.main-wrapper {
    padding-top: 100px;
    min-height: calc(100vh - 715px);
}
.wrapper-pt-0 {
    padding-top: 0;
}
.heading {
    h1 {
        // 
        font-weight: 700;
        font-size: 64px;
        line-height: 76.8px;
        span {
            
        }
    }
    p {
        
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }
}
.section-heading {
    h2 {
        font-weight: 700;
        // 
        font-size: 54px;
        line-height: 64px;
        span {
            display: inline-block;
            
        }
    }
    p {
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-color);
        font-weight: 400;
        padding-bottom: 12px;
    }
}

.card-link {
    width: 400px;
    height: 300px;
    border-radius: 16px;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-image: url(../assets/images/card-link-bg.svg);
    background-position: 93% 10%;
    background-repeat: no-repeat;
    background-size: auto;
    transition: background-position 0.3s ease-in;
    flex-flow: column;
    &:hover {
        background-position: 50% 50%;
        .arrow-down::before {
            left: 50%;
            transform: translateX(-50%);
            opacity: 1;
        }
        .arrow-down::after {
            left: 95%;
            opacity: 0;
        }
    }
    .card-link-text {
        color: var(--white-color);
        max-width: 200px;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: block;
        padding-bottom: 30px;
    }
    .arrow-down {
        position: relative;
        display: block;
        width: 100%;
        height: 16px;
        &::after {
            content: "";
            background-image: url("../assets/images/right-arrow.svg");
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.3s ease-in;
            width: 33px;
            height: 16px;
            background-size: contain;
        }
        &::before {
            content: "";
            background-image: url("../assets/images/right-arrow.svg");
            position: absolute;
            bottom: 0px;
            left: 0;
            transition: all 0.3s ease-in;
            opacity: 0;
            width: 33px;
            height: 16px;
            background-size: contain;
        }
    }
}

.swiper-arrow {
    .swiper-next,
    .swiper-prev {
        width: 22px;
        height: 16px;
        display: inline-block;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        vertical-align: middle;
    }
    .swiper-prev {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2307252F'/%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2322A5D9'/%3E%3C/svg%3E");
        }
    }
    .swiper-next {
        margin-left: 10px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2307252F %7D .s1 %7B fill: %2307252F %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2322a5d9 %7D .s1 %7B fill: %2307252f %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
        }
    }
}
.read-more-content {
    color: var(--link-color);
    cursor: pointer;
    &:hover {
        color: var(--link-hover-color);
    }
}
/*****home page***/

/****hero-banner-section****/
.hero-banner-section {
    padding: 60px 0 120px;
    background-color: var(--light-sky-background);
    .hero-banner-heading {
        display: flex;
        justify-content: space-between;
        padding-bottom: 32px;
    }
    .banner-heading-left {
        max-width: 995px;
        flex: 1;
        .main-heading {
            h1 {
                font-weight: 700;
                
                font-size: 64px;
                line-height: 76px;
                span {
                    color: var(--primary-color);
                }
            }
        }
        .banner-description {
            p {
                font-weight: 500;
                
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    .banner-heading-right {
        .banner-video {
            position: relative;
            padding: 20px;
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background-image: url(../assets/images/banner-video-bg.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                animation: rotation 15s infinite linear;
                transform-origin: center center;
            }
            a {
                display: block;
                position: relative;
                z-index: 2;
            }
            .banner-video-thumbnail {
                width: 85px;
                height: 85px;
                object-fit: cover;
                object-position: center center;
                border-radius: 100%;
            }
            .video-play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .hero-banner-img {
        position: relative;
        padding: 0 30px 30px 0;
        .banner-img {
            border-radius: 16px;
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
                border-radius: 16px;
            }
        }
        .banner-link {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}
/****end hero-banner-section****/

/***our-services-section***/
.our-services-section {
    padding: 120px 0;
    background-color: var(--body-background);
    overflow: hidden;
    .our-services-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .section-heading {
            flex: 1;
            padding-right: 20px;
            h2 {
                span {
                    display: block;
                }
            }
        }
        .services-title-right {
            padding-top: 60px;
            a {
                text-decoration: underline;
                display: inline-block;
                color: var(--link-color);
                &:hover {
                    color: var(--link-hover-color);
                }
            }
        }
    }
    .align-self-middle {
        .services-right-image {
            padding-top: 0px;
            text-align: center;
        }
    }
}
/***end our-services-section***/
/***services-accordion***/

.services-accordion {
    .accordion {
        background: transparent;
        border: 0;
        .accordion-item {
            background: transparent;
            border-radius: 16px;
            overflow: hidden;
            border: 0;
            box-shadow: none;
            width: 100%;
            .accordion-header {
                padding-bottom: 0;
                position: relative;
                .accordion-read-more {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 3;
                    transition: all 0.3s;
                    a {
                        width: 60px;
                        height: 50px;
                        background-color: var(--primary-color);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom-left-radius: 50%;
                        transition: all 0.5s;
                        &:hover {
                            background-color: var(--secondary-color);
                        }
                    }
                    img {
                        width: 25px;
                    }
                }
                .accordion-button::after {
                    display: none;
                }
                .accordion-button {
                    padding: 25px 70px 24px 25px;
                    background: var(--white-color);
                    border: 0;
                    box-shadow: none;
                    font-size: 24px;
                    
                    font-weight: 500;

                    &.collapsed {
                        color: var(--light-text-color);
                        background: transparent;
                        &:hover {
                            background-color: var(--accordion-hover);
                        }
                        & + .accordion-read-more {
                            opacity: 0;
                        }
                    }
                }
            }
            .accordion-collapse {
                background: var(--white-color);
                border-radius: 0 0 16px 16px;
                top: -15px;
                position: relative;
                z-index: 3;
            }
            .accordion-body {
                padding: 0 25px 24px;
                p {
                    font-size: 18px;
                    line-height: 27px;
                    
                    font-weight: 400;
                }
            }
            .services-accordion-icon {
                display: none;
            }
        }
    }
}
.mb-services-accordion {
    display: none;
}
.mobile-services-accordion {
    .services-accordion-slider {
        overflow: visible;
        -webkit-clip-path: inset(-100vw -100vw -100vw 0);
        clip-path: inset(-100vw -100vw -100vw 0);
    }
}
.services-accordion-card {
    background-color: var(--white-color);
    padding: 16px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 21.19205px 0px rgba(34, 165, 217, 0.05);
    position: relative;

    .services-accordion-details {
        h3 {
            
            font-size: 18px;
            line-height: 23.4px;
            font-weight: 500;
        }
        p {
            
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }
        .services-read-more {
            position: absolute;
            top: 0;
            right: 0;
            a {
                width: 48px;
                height: 40px;
                background-color: var(--primary-color);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-bottom-left-radius: 50%;
                transition: all 0.5s;

                img {
                    width: 20px;
                }
                &:hover {
                    background-color: var(--secondary-color);
                }
            }
        }
    }
    .services-accordion-img {
        max-width: 85px;
    }
}
/***end services-accordion***/
/***behind-services-section***/
.behind-services-section {
    padding: 120px 0;
    background-color: var(--light-sky-background);
    .behind-services-inner {
        position: relative;
        .section-heading {
            position: relative;
            z-index: 3;
            h2 {
                span {
                    display: block;
                }
            }
        }
        .technologies-logos {
            margin-top: -210px;
        }
    }
}
/***end behind-services-section***/
/***technologies-logos***/
.technologies-logos {
    position: relative;
    &::after {
        content: "";
        width: 52.75%;
        height: 30px;
        // background-color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
        opacity: 0.5;
        bottom: 0;
        left: 5%;
        position: absolute;
        border-left: 2px solid var(--primary-color);
        border-bottom-left-radius: 10px;
    }
    .technologies-left-logo {
        align-self: flex-end;
    }
    .technologies-left {
        padding-bottom: 40px;
        img {
            width: 120px;
            height: auto;
        }
    }
    .technologies-logo-card {
        position: relative;
        padding-top: 10px;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(../assets/images/technologies-lines.svg);
            top: 0px;
            right: 0;
            background-position: top right;
            background-repeat: no-repeat;
            background-size: auto;
        }
    }
    .technologies-logo-row {
        display: flex;
        position: relative;
        z-index: 2;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        li {
            list-style: none;
            padding-left: 18px;
            padding-top: 17.5px;
            .technologies-image {
                width: 85px;
                height: 85px;
                min-width: 85px;
                border-radius: 12px;
                background: var(--white-color);
                box-shadow: 0px 0px 21.192052841186523px 0px var(--tab-background);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}
/***end technologies-logos***/
/***work-process-section***/
.work-process-section {
    padding: 120px 0 0;
    overflow-x: hidden;
    .section-heading {
        padding-bottom: 20px;
        h2 {
            span {
                display: block;
            }
        }
    }
}
.work-process-slider {
    position: relative;
    &::after {
        content: "";
        width: 100vw;
        height: 100%;
        position: absolute;
        left: calc(50% + 12px);
        top: 0;
        background: linear-gradient(0deg, var(--light-sky-background) 0%, var(--light-sky-background) 100%);
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }

    .work-process-content {
        .work-process-row {
            align-items: center;
        }
        .work-process-left {
            .work-process-number {
                padding-bottom: 8px;
                font-size: 72px;
                color: transparent;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                -webkit-text-stroke: 1px var(--work-border-color);
            }
            .work-process-details {
                padding-bottom: 32px;
                h3 {
                    
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 41.6px;
                }
                p {
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }
        .work-process-right {
            padding: 120px 0 120px 50px;

            .work-process-description {
                p {
                    color: var(--secondary-color);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                }
                ul {
                    padding-top: 7px;
                    li {
                        color: var(--secondary-color);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23.4px;
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }
    .swiper-arrow {
        position: absolute;
        left: 57%;
        top: 50px;
        transform: translateX(-50%);
        z-index: 5;
        .swiper-next,
        .swiper-prev {
            width: 22px;
            height: 16px;
            display: inline-block;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            vertical-align: top;
        }
        .swiper-prev {
            background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2307252F'/%3E%3C/svg%3E");
            &:hover {
                background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2322A5D9'/%3E%3C/svg%3E");
            }
        }
        .swiper-next {
            margin-left: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2307252F %7D .s1 %7B fill: %2307252F %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2322a5d9 %7D .s1 %7B fill: %2307252f %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
            }
        }
    }
}

/***End work-process-section***/

/***end why-hire-section***/
.why-hire-section {
    padding: 120px 0;
    overflow-x: hidden;
    .section-heading {
        padding-bottom: 20px;
        h2 {
            color: var(--white-color);
            span {
                color: var(--white-color);
            }
        }
        p {
            color: var(--white-color);
        }
    }
}
/***end why-hire-section***/
/***project-types-slider***/
.project-types-slider {
    .swiper-project-types {
        overflow: visible;
        clip-path: inset(-100vw -100vw -100vw 0);
    }

    .project-type-card {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 52px;
            left: 115px;
            width: calc(100% - 72px);
            height: 1px;
            border-bottom: 2px dashed rgba(255, 255, 255, 0.3);
        }
        .project-type-number {
            padding: 13px;
            display: inline-block;
            border-radius: 100%;
            border: 2px solid rgba(255, 255, 255, 0.2);
            margin-bottom: 24px;
            span {
                width: 74px;
                height: 74px;
                background-color: rgba(255, 255, 255, 0.2);
                color: var(--white-color);
                font-size: 26px;
                font-style: normal;
                font-weight: 500;
                line-height: 39px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
        }
        .project-type-details {
            h3 {
                color: var(--white-color);
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 42px;
            }
            p {
                color: var(--white-color);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
            }
        }
    }
}
/***end project-types-slider***/

/***casestudies-card***/
.casestudies-card {
    background-color: var(--card-background);
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    height: 100%;
    min-height: 260px;
    position: relative;
    transition: all 0.4s;
    &:hover {
        background-color: var(--white-color);
        box-shadow: 0px 0px 21.192052841186523px 0px var(--tab-background);
        .casestudies-right {
            .casestudies-button {
                opacity: 1;
            }
        }
    }
    .casestudies-left {
        position: relative;
        .casestudies-img {
            aspect-ratio: 4/3;
            height: 285px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    .casestudies-right {
        padding: 24px 35px 24px 24px;
        .casestudies-description {
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                
                a {
                    
                    transition: all 0.4s;
                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
            p {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
                
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        .casestudies-button {
            position: absolute;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.4s;
            a {
                width: 60px;
                height: 50px;
                background-color: var(--primary-color);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 16px;
                transition: all 0.4s;
                &:hover {
                    background-color: var(--secondary-color);
                }
                img {
                    width: 22px;
                }
            }
        }
    }
}
/***End casestudies-card***/

/***case-studies-section***/
.case-studies-section {
    padding: 120px 0;
    .case-studies-title {
        display: flex;
        padding-bottom: 20px;
        .section-heading {
            padding-right: 20px;
            flex: 1;
            h2 {
                span {
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
        .section-heading-right {
            a {
                text-decoration: underline;
            }
        }
    }

    .div-grid-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        .div-grid-col {
            grid-area: 2 / 1 / 3 / 3;
        }
        .div-grid-col:first-child {
            grid-area: 1 / 1 / 2 / 3;
        }
    }
}
/***End case-studies-section***/

/***client-testimonials-section***/
.client-testimonials-section {
    padding: 120px 0;
    overflow: hidden;
    background-color: var(--light-sky-background);
    .section-heading {
        padding-bottom: 20px;
        h2 span {
            display: block;
        }
    }
}
/***end client-testimonials-section***/

/***testimonials-slider**/
.testimonials-slider {
    position: relative;
    overflow: visible;
    -webkit-clip-path: inset(-100vw -100vw -100vw 0);
    clip-path: inset(-100vw -100vw -100vw 0);
    .swiper-arrow {
        position: absolute;
        right: 0;
        top: -60px;
        z-index: 5;
    }
    .testimonials-card {
        background-color: var(--white-color);
        padding: 42px 24px 42px;
        border-radius: 16px;
        .testimonials-content {
            padding-left: 110px;
            padding-bottom: 24px;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                left: 30px;
                top: 0;
                width: 36px;
                height: 32px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='40' viewBox='0 0 46 40' fill='none'%3E%3Cpath d='M41.7164 9.39517H43.7164V7.39517V4.00098V2.00127L41.7167 2.00098C36.8221 2.00027 32.7503 4.3404 29.7364 7.65429C26.7378 10.9513 24.7046 15.2862 23.7875 19.5826C22.8762 23.8519 23.0172 28.3328 24.6817 31.8551C26.4163 35.5259 29.7555 38.001 34.6468 38.001C39.7076 38.001 43.7164 33.7959 43.7164 28.7277C43.7164 23.6624 39.708 19.4544 34.6468 19.4544C34.1005 19.4544 33.8348 19.3226 33.7105 19.2323C33.5874 19.1429 33.4531 18.9858 33.3562 18.6703C33.1396 17.9652 33.2051 16.7161 33.8413 15.1976C35.1141 12.1594 38.0639 9.39517 41.7164 9.39517ZM21.3488 9.39517H23.3488V7.39517V4.00098V2.00127L21.3491 2.00098C16.455 2.00027 12.3837 4.3405 9.37011 7.65438C6.37193 10.9514 4.33889 15.2863 3.42197 19.5826C2.51082 23.852 2.65198 28.3329 4.31646 31.8551C6.0511 35.5259 9.39031 38.001 14.2816 38.001C19.3404 38.001 23.3488 33.7954 23.3488 28.7277C23.3488 23.6628 19.3409 19.4544 14.2816 19.4544C13.7349 19.4544 13.4688 19.3226 13.3441 19.232C13.2208 19.1425 13.0865 18.9853 12.9895 18.6699C12.7727 17.9649 12.838 16.7159 13.474 15.1975C14.7466 12.1594 17.6963 9.39517 21.3488 9.39517Z' fill='%2322A5D9' stroke='white' stroke-width='4'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
            &::before {
                content: "";
                width: 1px;
                height: 72%;
                background-color: var(--primary-color);
                opacity: 0.5;
                position: absolute;
                left: 8%;
                top: 50px;
            }
            p {
                
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
            }
        }
        .testimonials-author {
            padding-left: 20px;
            .testimonials-author-img {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
            .testimonials-author-details {
                padding-left: 24px;
                h5 {
                    padding-bottom: 6px;
                    color: var(--secondary-color);
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 30px;
                }
                span {
                    color: var(--primary-color);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }
}
/***end testimonials-slider***/

/***faq-section**/
.faq-section {
    padding: 120px 0 100px;
    .section-heading {
        padding-bottom: 20px;
    }
    .faq-accordion {
        .accordion-item {
            background-color: transparent;
            border: none;
            border-radius: 16px;
            overflow: hidden;
        }
        .accordion-header {
            padding-bottom: 0;
            background-color: transparent;
            position: relative;
        }
        .accordion-button.collapsed {
            background-color: transparent;
            position: relative;
            color: var(--light-text-color);
        }
        .accordion-button {
            background-color: var(--white-color);
            border: none;
            box-shadow: none;
            color: var(--secondary-color);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 31.2px;
            padding: 24px 24px;
            position: relative;
            &::after {
                width: 24px;
                height: 24px;
                background-size: contain;
            }
        }
        .accordion-collapse {
            background-color: var(--white-color);
        }
        .accordion-body {
            padding: 0 75px 24px 24px;
            position: relative;
            z-index: 8 !important;
            p {
                
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
            }
        }
        .accordion-collapse {
            position: relative;
            z-index: 8 !important;
            top: -12px;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
            overflow: hidden;
        }
        .accordion-button::after {
            background-image: none;
        }
        .accordion-button:not(.collapsed)::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29' fill='none'%3E%3Cpath d='M21.2131 7.07104L7.071 21.2132' stroke='%2322A5D9' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M21.2131 21.2131L7.071 7.071' stroke='%2322A5D9' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
        }
    }
}

/***End faq-section**/

/***cta-section **/
.cta-section {
    padding: 0 0 120px;
    .cta-inner {
        position: relative;
        padding: 85px 50px;
        background-image: url(../assets/images/cta-section-bg.webp);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 16px;
        margin-right: 30px;
        margin-bottom: 30px;
        min-height: 380px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .cta-details {
            padding-right: 400px;
            h2 {
                color: var(--white-color);
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 55px;
            }
            p {
                color: var(--white-color);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
            }
        }

        .cta-request-btn {
            position: absolute;
            right: -30px;
            bottom: -30px;
        }
    }
}
/***End cta-section**/

/*****End-home page***/

/****industries****/
/***agile-banner-section***/
.agile-banner-section {
    padding: 60px 0 60px;
    position: relative;
    overflow: hidden;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100px;
        background-color: var(--secondary-background-ltr);
        top: 0px;
        left: 0;
        z-index: -1;
    }

    .agile-banner-heading {
        padding-bottom: 20px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 100vw;
            height: calc(100% + 60px);
            background-color: var(--secondary-background-ltr);
            top: -60px;
            left: 50%;
            z-index: -1;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100vw;
            height: calc(100% + 60px);
            background-color: var(--secondary-background-ltr);
            top: -60px;
            right: 50%;
            z-index: -1;
        }
        .agile-main-heading {
            h1 {
                font-size: 64px;
                line-height: 76px;
                
                span {
                    // color: var(--primary-color);
                    display: block;
                }
            }
        }
        .agile-banner-subheading {
            
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
        }
    }
    .agile-banner-description {
        padding-top: 32px;
        p {
            
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
        }
    }
    .content-center {
        text-align: center;
    }
    .agile-banner-right {
        position: relative;
        padding-right: 30px;
        padding-bottom: 30px;
        display: inline-block;
        max-width: 482px;
        .agile-banner-image {
            border-radius: 16px;
            overflow: hidden;
        }
        .agile-banner-link {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            .card-link {
                max-width: 321px;
                max-height: 241px;
            }
        }
    }
}
/*****End-agile-banner-section***/

/***our-offerings-section***/
.our-offerings-section {
    padding: 60px 0;
    overflow: hidden;
    .section-heading {
        padding-bottom: 20px;
        h2 {
            span {
                display: inline-block;
            }
        }
    }
    .offering-slider {
        .swiper-offering {
            position: relative;
            overflow: visible;
            -webkit-clip-path: inset(-100vw -100vw -100vw 0);
            clip-path: inset(-100vw -100vw -100vw 0);
        }
        .swiper-arrow {
            position: absolute;
            right: 0;
            top: -50px;
            z-index: 2;
        }
        .swiper-slide {
            height: auto;
            .offering-card {
                height: 100%;
            }
        }
    }
}
.offering-card {
    background-color: var(--white-color);
    padding: 16px;
    border-radius: 16px;
    min-height: 100%;

    .offering-card-icon {
        width: 80px;
        height: 80px;
        background-color: var(--offering-icon-bg);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        padding: 12px;
        margin-bottom: 24px;
        box-shadow: 0px 0px 21.19205px 0px var(--tab-background);
        img {
            max-width: 46px;
        }
    }
    .offering-card-content {
        .offering-card-title {
            font-weight: 500;
            
        }
        p {
            font-weight: 400;
            
            font-size: 18px;
            line-height: 27px;
        }
    }
}
/***End our-offerings-section***/

/***industries cta-section ***/
.cta-section.gradient-cta-section {
    padding: 60px 0px 120px;
    .cta-inner {
        min-height: 340px;
        background: var(--gradient-background);
        .cta-details {
            h2 {
                
            }
            p {
                
            }
        }
    }
}
/****End cta-section****/

/***our-specialities-section***/
.our-specialities-section {
    padding: 120px 0;
    background-color: var(--light-sky-background);
    .section-heading {
        padding-bottom: 20px;
        h2 {
            span {
                display: inline-block;
            }
        }
    }
}
.our-specialities-details {
    .our-specialities-content {
        padding-bottom: 42px;
        .specialities-content-left {
            .specialities-description {
                padding-bottom: 22px;
                h3 {
                    font-size: 32px;
                    line-height: 42px;
                }
                h5 {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .specialities-title {
                padding-bottom: 24px;
                font-weight: 500;
                
            }
            p {
                font-size: 18px;
                line-height: 24px;
                
                font-weight: 400;
            }
            ul {
                padding-left: 20px;
                margin-bottom: 10px;
                li {
                    padding-bottom: 16px;
                    font-size: 18px;
                    line-height: 24px;
                    
                    font-weight: 400;
                }
            }
            .specialities-bottom-text {
                font-weight: 500;
            }
        }
        .specialities-content-right {
            img {
                width: 530px;
                margin: 0 auto;
                display: block;
                border-radius: 16px;
                max-width: 100%;
            }
        }
    }
    .our-specialities-tab {
        background: var(--tab-background);
        border-radius: 8px 8px 0px 0px;
        .nav-item {
            flex: auto;
            .nav-link {
                background: transparent;
                color: var(--tab-nav-link);
                width: 100%;
                padding: 16px;
                text-align: center;
                position: relative;
                &:hover {
                    
                }
                &.active {
                    
                    &::after {
                        transform: scale(1);
                    }
                }
                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: var(--primary-color);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: scale(0);
                    transition: all 0.5s;
                }
            }
        }
    }
}
/***End our-specialities-section***/

/***edtech-game-section***/
.edtech-game-section {
    padding: 120px 0 100px;
    .section-heading {
        padding-bottom: 32px;
    }
    .section-full-image {
        padding-bottom: 32px;
        img {
            border-radius: 16px;
            width: 100%;
        }
    }
    .edtech-game-left {
        .h2 {
            
            font-weight: 500;
        }
    }
    .edtech-game-right {
        p {
            font-size: 18px;
            line-height: 28px;
        }
    }
    .edtech-game-card {
        padding-top: 42px;
        .offering-card {
            margin-bottom: 24px;
            min-height: calc(100% - 24px);
        }
    }
}
/***End edtech-game-section***/
.industries-solutions-section {
    padding: 120px 0;
    background-color: var(--light-sky-background);
    .section-heading {
        position: sticky;
        top: 135px;
    }
}
.icon-horizontal-card {
    background-color: var(--white-color);
    padding: 32px;
    border-radius: 16px;
    display: flex;
    margin-bottom: 20px;
    .horizontal-card-icon {
        width: 36px;
        height: 36px;
        min-width: 36px;
    }
    .horizontal-card-content {
        padding-left: 32px;
        .horizontal-card-title {
            font-weight: 500;
            
        }
        p {
            font-size: 18px;
            line-height: 27px;
        }
    }
}
/****End industries****/

/****keyframes****/
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
/*****************/

/****industries-page-middle****/
.industries-page-middle {
    .cta-section {
        padding: 60px 0 120px;
    }
}
/*****end industries-page-middle***/

/*****services*******/

.service-hero-section {
    padding: 100px 0 80px;
}
.service-tabs-section {
    padding: 80px 0;
}
.service-left-tabs {
    position: sticky;
    top: 150px;
    ul {
        padding: 0 0;
        li {
            list-style: none;
            margin-bottom: 14px;
        }
    }
    .service-tab-link {
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        
        opacity: 0.75;
        text-transform: capitalize;
        padding: 12px 40px 12px 24px;
        text-align: left;
        border-radius: 8px;
        position: relative;
        transition: all 0.3s ease;
        border: 0px;
        width: 100%;
        display: inline-block;
        position: relative;
        color: var(--text-secondary);
        &:hover,
        &.active {
            background-color: var(--white-color);
            opacity: 1;
            font-weight: 500;
        }
        &::before {
            content: "";
            position: absolute;
            right: 34px;
            width: 19px;
            height: 14px;
            top: 16px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../assets/images/blog-right-arrow.svg);
            transition: 0.4s;
            opacity: 0;
        }
        &:hover:before,
        &.active:before {
            margin-left: 0;
            opacity: 1;
            right: 16px;
        }
        p,span{
            font-size: 18px;
            font-weight: 500;
            color: var(--text-secondary) !important;
        }
    }
}
.service-feature-card {
    border-radius: 16px;
    background-color: var(--white-color);
    padding: 32px;
    margin-bottom: 32px;
    .service-heading {
        h2 {
            
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            span {
                
                display: block;
            }
        }
    }
    .service-short-description {
        padding-bottom: 24px;
        p {
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
        }
    }
    .service-feature-image {
        text-align: center;
        .service-img-box {
            display: inline-block;
            position: relative;
            padding-top: 0px;
            img.service-thumbnail-img {
                border-radius: 12px;
                // margin-top: -30px;
            }
            .service-img-label {
                position: absolute;
                top: -10px;
                left: -10%;
                background-color: var(--white-color);
                box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.05);
                padding: 14px;
                border-radius: 12px;
                display: inline-flex;
                align-items: center;
                max-width: 225px;
                text-align: left;
                img {
                    max-width: 31px;
                    border-radius: 0;
                }
                span {
                    
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20.8px;
                    flex: 1;
                    padding-left: 12px;
                }
            }
        }
    }
}
.services-page-middle {
    .cta-section {
        padding: 40px 0 120px;
    }
}

/*****end services*******/

/***default-hero-section***/
.default-hero-section {
    padding: 52px 0 42px 0;
}
.terms-policy-section {
    padding: 42px 0 120px 0;
    .terms-policy-content {
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        word-break: break-word;
        h2 {
            
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            margin-top: 30px;
        }
        h3 {
            
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            margin-top: 12px;
        }
        p {
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            padding-bottom: 12px;
        }
        ul {
            margin-bottom: 12px;
            li {
                
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
                padding-bottom: 12px;
            }
        }
    }
}
/***End default-hero-section***/

/***error-page-middle***/
.error-content-section {
    padding: 149px 0 137px 0;
    .error-page-title {
        &.title-error-500 {
            h1 {
                span {
                    display: block;
                }
            }
        }
        h2 {
            
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
        }
        h1 {
            
            font-size: 54px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            padding-bottom: 16px;
            span {
                
            }
        }
    }
    .error-page-img {
        text-align: center;
    }
    .error-page-msg {
        padding-bottom: 12px;
        max-width: 585px;
        p {
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            padding-bottom: 12px;
        }
    }

}
.thankyou-page-middle{
    .error-content-section .error-page-title h1 span{
        display: inline-block;
    }

    .error-content-row{
        align-items: center;
    }
}
/***end error-page-middle***/
/*****responsive******/

@media (min-width: 992px) {
    .case-studies-section {
        .div-grid-col:last-child {
            grid-area: 1 / 3 / 3 / 4;
            .casestudies-card {
                flex-direction: column;
                height: 100%;
                .casestudies-left {
                    flex: 100%;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: auto;
                    max-width: 100%;

                    .casestudies-img {
                        height: 285px;
                        position: relative;
                        aspect-ratio: inherit;
                    }
                }
                .casestudies-right {
                    flex: 100%;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: auto;
                    max-width: 100%;
                }
            }
        }
    }
}
@media (max-width: 1400px) {
    .card-link {
        width: 350px;
        height: 260px;
    }

    /***behind-services-section***/
    .technologies-logos::after {
        width: 46.2%;
    }
    /***end behind-services-section***/

    /***work-process-section***/
    .work-process-slider .swiper-arrow {
        left: 58%;
    }
    /***end work-process-section***/
    /***case-studies-section***/
    .casestudies-card .casestudies-left .casestudies-img {
        aspect-ratio: 3/3.1;
    }
    /***End case-studies-section***/
}
@media (max-width: 1366px) {
    /***hero-banner-section***/
    .hero-banner-section {
        padding: 40px 0 80px;
    }
    /***End hero-banner-section***/

    /***our-services-section***/
    .our-services-section {
        padding: 80px 0;
    }
    /***End our-services-section***/
    /***behind-services-section***/
    .behind-services-section {
        padding: 80px 0;
    }
    /***End behind-services-section***/

    /***work-process-section***/
    .work-process-section {
        padding: 80px 0 0;
    }
    .work-process-slider .work-process-content .work-process-right {
        padding: 100px 0 100px 50px;
    }
    /***End work-process-section***/

    /***why-hire-section***/
    .why-hire-section {
        padding: 80px 0;
    }
    /***End why-hire-section***/
    /***case-studies-section ***/
    .case-studies-section {
        padding: 80px 0;
    }
    /*** end case-studies-section ***/
    /***client-testimonials-section***/
    .client-testimonials-section {
        padding: 80px 0;
    }
    /*** end client-testimonials-section***/
    /***faq-section***/
    .faq-section {
        padding: 80px 0 60px;
    }
    /*** end faq-section***/
    /***cta-section***/
    .cta-section {
        padding: 0 0 80px;
    }

    /*** end cta-section***/

    .industries-page-middle .cta-section {
        padding: 80px 0;
    }

    /***industries-solutions-section***/
    .industries-solutions-section {
        padding: 80px 0;
    }
    /***end industries-solutions-section***/
    /***edtech-game-section ***/
    .edtech-game-section {
        padding: 80px 0 60px;
    }
    /***end edtech-game-section***/
    /***our-specialities-section ***/
    .our-specialities-section {
        padding: 80px 0;
    }
    /***end our-specialities-section***/

    /*****services*******/
    .service-feature-card {
        .service-feature-image {
            .service-img-box {
                .service-img-label {
                    right: auto;
                    float: left;
                }
            }
        }
    }

    /*****end services***/


}
@media (max-width: 1200px) {
    .main-wrapper {
        min-height: calc(100vh - 741px);
    }
    .wrapper-pt-0 {
        padding-top: 0;
    }
    .heading {
        h1 {
            font-size: 54px;
            line-height: 66px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
        }
    }
    .section-heading h2 {
        font-size: 44px;
        line-height: 54px;
    }
    .card-link {
        width: 280px;
        height: 200px;
        background-size: 42%;
        .card-link-text {
            font-size: 24px;
        }
    }

    /***hero-banner-section***/
    .hero-banner-section {
        .banner-heading-left {
            max-width: 850px;
            padding-right: 30px;
            .main-heading h1 {
                font-size: 54px;
                line-height: 66px;
            }
            .banner-description p {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    /***end hero-banner-section***/

    /****our-services-section****/
    .our-services-section {
        .our-services-title {
            .services-title-right {
                padding-top: 50px;
            }
        }
    }
    .services-accordion {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
            }
        }
    }
    /***end our-services-section***/
    /***behind-services-section***/
    .technologies-logos {
        .technologies-logo-card::after {
            width: 118%;
        }
    }
    .technologies-logos::after {
        width: 37.5%;
    }
    /***end behind-services-section***/
    /***work-process-section***/
    .work-process-slider {
        .work-process-content {
            .work-process-left {
                .work-process-number {
                    font-size: 80px;
                    line-height: 104px;
                }
                .work-process-details h3 {
                    font-size: 28px;
                    line-height: 38px;
                }
            }
            .work-process-right {
                padding: 100px 0 100px 30px;
                .work-process-description p {
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }

        .swiper-arrow {
            left: 58%;
        }
    }

    /***end work-process-section***/

    /***why-hire-section***/
    .project-types-slider {
        .project-type-card {
            .project-type-details {
                h3 {
                    font-size: 28px;
                    line-height: 38px;
                }
            }
        }
    }
    /***end why-hire-section***/
    /***client-testimonials-section***/
    .testimonials-slider {
        .testimonials-card {
            .testimonials-content {
                padding-left: 60px;
                &::after {
                    left: 8px;
                }
                &::before {
                    left: 7%;
                }
                p {
                    font-size: 18px;
                    line-height: 32px;
                }
            }
            .testimonials-author {
                padding-left: 0px;
                .testimonials-author-details {
                    h5 {
                        font-size: 22px;
                        line-height: 28px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    /***end client-testimonials-section***/

    /***cta-section***/
    .cta-section {
        &.gradient-cta-section {
            .cta-inner {
                min-height: 320px;
            }
        }
        .cta-inner {
            padding: 50px;
            min-height: inherit;
            .cta-details {
                padding-right: 250px;
                h2 {
                    font-size: 32px;
                    line-height: 42px;
                }
            }
        }
    }
    /***end cta-section***/
    /***our-specialities-section***/
    .our-specialities-details .our-specialities-content .specialities-content-left .specialities-description {
        h3 {
            font-size: 28px;
            line-height: 38px;
        }
        h5 {
            font-size: 18px;
            line-height: 28px;
        }
    }
    /***end-specialities-section***/
    /***agile-banner-section***/
    .agile-banner-section {
        .agile-banner-heading .agile-main-heading h1 {
            font-size: 54px;
            line-height: 66px;
        }
        .agile-banner-heading .agile-banner-subheading {
            font-size: 18px;
            line-height: 28px;
        }
    }
    /***end-agile-banner-section***/

    /*****services*******/
    .service-feature-card {
        .service-heading {
            h2 {
                font-size: 28px;
                line-height: 34px;
            }
        }
        .service-feature-image {
            padding-left: 30px;
            .service-img-box {
                .service-img-label {
                    right: auto;
                    float: left;
                    left: -30px;
                }
            }
        }
    }
    .service-left-tabs .service-tab-link {
        font-size: 16px;
        padding: 12px 40px 12px 14px;
    }
    /*****end services***/
    /***case-studies-section***/
    .casestudies-card .casestudies-left .casestudies-img {
        aspect-ratio: 2.5/3;
        height: 322px;
    }
    /***End case-studies-section***/
    /***terms-policy-middle***/
    .terms-policy-section {
        .terms-policy-content {
            h2 {
                font-size: 28px;
                line-height: 38px;
            }
        }
    }
    /***End terms-policy-middle***/

    /***error-page-middle***/
    .error-content-section {
        padding: 120px 0 107px 0;
    }
    /***end error-page-middle***/
}
@media (max-width: 991px) {
    .main-wrapper {
        padding-top: 79px;
        min-height: calc(100vh - 990px);
    }
    .wrapper-pt-0 {
        padding-top: 0;
    }
    .heading {
        h1 {
            font-size: 40px;
            line-height: 48px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
        }
    }
    .section-heading {
        h2 {
            font-size: 36px;
            line-height: 43px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .card-link {
        width: 215px;
        height: 160px;
        background-size: 35%;

        .card-link-text {
            font-size: 18px;
            padding-bottom: 16px;
            line-height: normal;
        }
        .arrow-down::after,
        .arrow-down::before {
            width: 21px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    /***hero-banner-section***/
    .hero-banner-section {
        .banner-heading-left {
            .main-heading h1 {
                font-size: 40px;
                line-height: 48px;
            }
            .banner-description {
                p {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
        .banner-heading-right {
            .banner-video {
                padding: 15px;
                .banner-video-thumbnail {
                    width: 70px;
                    height: 70px;
                }
            }
        }
        .hero-banner-img {
            .banner-img {
                height: 350px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
        .hero-banner-img .banner-link {
            .card-link {
                .card-link-text {
                    max-width: 144px;
                    margin: 0 auto;
                }
            }
        }
    }
    /***End hero-banner-section***/

    /***our-services-section***/
    .our-services-section {
        padding: 80px 0;
        .our-services-title .services-title-right {
            padding-top: 25px;
        }
        .services-accordion .accordion .accordion-item {
            .accordion-header .accordion-button {
                font-size: 20px;
                line-height: 26px;
            }
            .accordion-body p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .align-self-middle .services-right-image {
            max-width: 196px;
        }
    }
    /***End our-services-section***/
    /***behind-services-section***/
    .behind-services-section {
        padding: 80px 0;
        .behind-services-inner .technologies-logos {
            margin-top: 16px;
        }
    }
    .technologies-logos::after {
        width: 16.8%;
        left: 6%;
    }
    .technologies-logos .technologies-left img {
        width: 90px;
    }

    /***End behind-services-section***/

    /***work-process-section***/

    .work-process-section {
        padding: 80px 0 0;
        .section-heading {
            padding-bottom: 20px;
        }
    }
    .work-process-slider {
        .work-process-content {
            .work-process-right {
                padding: 60px 0 60px 30px;
                .work-process-description ul li {
                    font-size: 16px;
                    line-height: 23.4px;
                    padding-bottom: 10px;
                }
            }
            .work-process-left {
                .work-process-details {
                    h3 {
                        font-size: 24px;
                        line-height: 34px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .work-process-slider .swiper-arrow {
        top: 20px;
        left: 59%;
    }
    .work-process-slider .work-process-content .work-process-row {
        align-items: flex-start;
    }

    /***End work-process-section***/

    /***why-hire-section***/
    .why-hire-section {
        padding: 80px 0;
    }
    .project-types-slider .project-type-card::after {
        left: 106px;
        top: 43px;
        width: calc(100% - 63px);
    }
    .project-types-slider .project-type-card {
        .project-type-number {
            padding: 10px;
            span {
                width: 60px;
                height: 60px;
                font-size: 22px;
                line-height: 32px;
            }
        }
        .project-type-details {
            h3 {
                font-size: 24px;
                line-height: 34px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    /***End why-hire-section***/
    /***case-studies-section ***/
    .case-studies-section {
        padding: 60px 0;
        .div-grid-row {
            display: block;
            .div-grid-col {
                padding-bottom: 24px;
            }
        }
    }
    .casestudies-card {
        min-height: 230px;
        background-color: var(--white-color);

        .casestudies-right {
            .casestudies-description {
                h3 {
                    font-size: 20px;
                    line-height: 30px;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .casestudies-button {
                opacity: 1;
            }
        }
    }

    .casestudies-card .casestudies-left .casestudies-img {
        aspect-ratio: 4/3.5;
        height: 264px;
    }

    /*** end case-studies-section ***/

    /***client-testimonials-section***/
    .client-testimonials-section {
        .section-heading h2 span {
            display: inline-block;
        }
    }
    .testimonials-slider .testimonials-card {
        padding: 24px 24px 24px;
        .testimonials-content p {
            font-size: 22px;
            line-height: 33px;
        }
        .testimonials-content::before {
            left: 5%;
        }
        .testimonials-author .testimonials-author-img {
            width: 54px;
            height: 54px;
        }

        .testimonials-author .testimonials-author-details {
            padding-left: 16px;
            h5 {
                font-size: 22px;
                line-height: 28px;
                padding-bottom: 3px;
            }
            span {
                font-size: 16px;
                line-height: 21px;
            }
        }
    }

    /*** end client-testimonials-section***/
    /***faq-section***/
    .faq-section {
        .faq-accordion {
            .accordion-item {
                margin-bottom: 10px;
            }
            .accordion-collapse {
                top: 0px;
            }
            .accordion-button {
                font-size: 18px;
                line-height: 24px;
                padding: 16px 24px;
            }
            .accordion-body p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    /*** end faq-section***/
    /***cta-section***/
    .cta-section {
        padding: 0 0 60px;

        &.gradient-cta-section {
            .cta-inner {
                min-height: inherit;
            }
        }
        .cta-inner {
            min-height: inherit;
            margin-bottom: 60px;
            padding: 22px 16px;
            .cta-request-btn {
                bottom: -30px;
            }
            .cta-details {
                padding: 0px 210px 0px 0px;
                h2 {
                    font-size: 24px;
                    line-height: 32px;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    /*** end cta-section***/

    /***agile-banner-section***/
    .agile-banner-section {
        .agile-banner-heading .agile-main-heading h1 {
            font-size: 40px;
            line-height: 48px;
        }
        .agile-banner-heading .agile-banner-subheading {
            font-size: 18px;
            line-height: 28px;
        }

        .agile-banner-content {
            padding-bottom: 32px;
        }
        .agile-banner-heading {
            padding-bottom: 0px;
        }
        .agile-banner-description {
            padding-top: 0px;
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .content-center {
            text-align: center;
        }
    }

    /***End agile-banner-section***/
    /***our-offerings-section***/
    .our-offerings-section {
        .offering-slider {
            .swiper-arrow {
                position: relative;
                padding-bottom: 30px;
                top: 0;
                text-align: right;
            }
        }
    }
    .offering-card .offering-card-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .services-accordion {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        padding: 15px 75px 15px 30px;
                    }
                }
                .accordion-collapse {
                    top: 0px;
                }
            }
        }
    }

    // industry page css

    .agile-banner-section .agile-banner-heading .agile-main-heading span {
        display: inline-block;
    }
    .agile-banner-section .agile-banner-description {
        padding-top: 32px;
    }
    .our-specialities-details .our-specialities-content .specialities-content-left {
        margin-bottom: 20px;
    }
    .industries-solutions-section {
        padding: 80px 0;
    }
    .industries-solutions-section .section-heading {
        margin-bottom: 20px;
    }

    .industries-page-middle .cta-section {
        padding: 60px 0;
        &.gradient-cta-section {
            .cta-inner {
                padding: 32px 20px;
            }
        }
    }

    /***industries-solutions-section***/
    .industries-solutions-section {
        padding: 60px 0;
    }
    /***end industries-solutions-section***/
    /***edtech-game-section ***/
    .edtech-game-section {
        padding: 60px 0 60px;
        .edtech-game-section .edtech-game-right p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    /***end edtech-game-section***/
    /***our-specialities-section ***/
    .our-specialities-section {
        padding: 60px 0;
    }
    .our-specialities-details .our-specialities-content .specialities-content-left .specialities-description {
        h3 {
            font-size: 24px;
            line-height: 34px;
        }
        h5 {
            font-size: 18px;
            line-height: 28px;
        }
    }
    /***end our-specialities-section***/
    /***industries-solutions-section***/
    .icon-horizontal-card .horizontal-card-content p {
        font-size: 16px;
        line-height: 24px;
    }
    /***end industries-solutions-section***/

    /*****services*******/
    .service-hero-section {
        padding: 60px 0 50px;
    }
    .service-tabs-section {
        padding: 60px 0;
    }
    .service-feature-card {
        .service-card-left {
            padding-bottom: 30px;
        }
        .service-heading {
            h2 {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }
    .service-feature-card .service-feature-image {
        margin-top: 30px;
    }
    /*****end services***/
    /***terms-policy-middle***/
    .terms-policy-section {
        padding: 42px 0 80px 0;
        .terms-policy-content {
            font-size: 16px;
            line-height: 26px;
            h2 {
                font-size: 24px;
                line-height: 34px;
                margin-top: 16px;
            }
            h3 {
                font-size: 22px;
                line-height: 28px;
            }
            p {
                font-size: 16px;
                line-height: 26px;
            }
            ul li {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    /***End terms-policy-middle***/
    /***error-page-middle***/
    .error-content-section {
        padding: 80px 0 80px 0;
        .error-page-title {
            h2 {
                font-size: 24px;
                line-height: 34px;
            }
            h1 {
                font-size: 36px;
                line-height: 44px;
            }
        }
        .error-page-img {
            text-align: center;
        }
        .error-page-msg {
            padding-bottom: 12px;
            max-width: 585px;
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    /***end error-page-middle***/
}
@media (max-width: 767px) {

    .main-wrapper {
        padding-top: 72px;
        min-height: inherit;
    }
    .wrapper-pt-0 {
        padding-top: 0;
    }
    .heading {
        h1 {
            font-size: 36px;
            line-height: 43px;
        }
        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .section-heading h2 {
        font-size: 28px;
        line-height: 38px;
    }
    .card-link {
        width: 178px;
        height: 133px;
        padding: 15px;
        background-size: 35%;
        .card-link-text {
            font-size: 16px;
            padding-bottom: 15px;
        }
        .arrow-down {
            height: 16px;
            &::before,
            &::after {
                height: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    .why-hire-section {
        padding: 60px 0;
    }

    /***hero-banner-section***/
    .hero-banner-section {
        padding: 40px 0 60px;
        overflow: hidden;
        .hero-banner-content {
            position: relative;
        }
        .banner-heading-left {
            padding-right: 20px;
            .main-heading h1 {
                font-size: 36px;
                line-height: 43px;
            }
            .banner-description p {
                font-size: 16px;
                line-height: 26px;
            }
        }
        .hero-banner-img {
            padding: 0 20px 20px 0;
            .banner-img {
                height: 350px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
        .hero-banner-img .banner-link .card-link .card-link-text {
            max-width: 120px;
        }
    }
    /***end hero-banner-section***/
    /***our-services-section***/
    .our-services-section {
        padding: 60px 0;
        .our-services-title {
            align-items: center;
            .services-title-right {
                padding-top: 0;
            }
        }
        .align-self-middle {
            .services-right-image {
                max-width: 150px;
                margin: 0 auto;
                padding-top: 30px;
            }
        }
        .services-accordion .accordion .accordion-item .accordion-header .accordion-button {
            font-size: 18px;
            line-height: 23px;
        }
        .services-accordion .accordion .accordion-item .accordion-body p {
            font-size: 14px;
            line-height: 21px;
        }
    }
    .mb-services-accordion {
        display: block;
    }
    .lg-services-accordion {
        display: none;
    }
    /***end our-services-section***/
    /***behind-services-section ***/
    .behind-services-section {
        padding: 60px 0;
    }
    .behind-services-section .behind-services-inner .technologies-logos {
        margin-top: 0;
        padding-top: 20px;
        position: relative;
        &::after {
            width: 34.7%;
            bottom: 3px;
            border-bottom-left-radius: 8px;
        }
        .technologies-left {
            position: absolute;
            bottom: 0;
        }
    }
    .technologies-logos .technologies-logo-card::after {
        width: 100%;
        background-image: url(../assets/images/technologies-lines-mobile.svg);
    }
    .technologies-logos .technologies-left img {
        max-width: 60px;
    }
    .technologies-logos .technologies-logo-row li {
        padding-left: 9.57px;
        padding-top: 9.97px;
        .technologies-image {
            width: 44px;
            height: 44px;
            min-width: 44px;
        }
    }
    /***end behind-services-section***/
    /***work-process-section***/
    .work-process-section {
        padding: 60px 0 0;
    }
    .work-process-slider::after {
        display: none;
    }
    .work-process-slider {
        .swiper-arrow {
            top: 20px;
            left: auto;
            right: 0;
        }
        .work-process-content .work-process-right {
            padding: 60px 0 60px 00px;
        }
        .work-process-content .work-process-left .work-process-number {
            font-size: 64px;
            line-height: 83px;
        }
        .work-process-content .work-process-left .work-process-details h3 {
            font-size: 20px;
            line-height: 26px;
        }
        .work-process-content .work-process-right {
            padding: 30px 0 60px 0px;
            background: linear-gradient(0deg, var(--light-sky-background) 0%, var(--light-sky-background) 100%);
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            margin-top: 32px;
            padding: 56px 0px 23px 24px;
            position: relative;
            // &:before{
            //     content: '';
            //     position: absolute;
            //     top: 0px;
            //     bottom: 0px;
            //     right: 0px;
            //     width: 999px;
            //     height: 100%;
            //     background: linear-gradient(0deg, var(--light-sky-background) 0%, var(--light-sky-background) 100%);
            // }
        }
        .work-process-content .work-process-left .work-process-details {
            padding-bottom: 20px;
        }
        .work-process-content .work-process-right .work-process-description ul {
            padding-left: 22px;
        }
    }

    /***end work-process-section***/
    /***why-hire-section***/
    .project-types-slider .project-type-card::after {
        left: 92px;
        top: 36px;
        width: calc(100% - 50px);
    }
    .project-types-slider .project-type-card .project-type-details h3 {
        font-size: 20px;
        line-height: 26px;
    }
    .project-types-slider .project-type-card {
        .project-type-number {
            padding: 10px;
            span {
                width: 50px;
                height: 50px;
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    /***end why-hire-section***/
    /***case-studies-section***/
    .case-studies-section .case-studies-title {
        align-items: center;
    }
    .casestudies-card {
        .casestudies-right {
            padding: 24px 16px 60px 16px;
            .casestudies-description {
                h3 {
                    font-size: 18px;
                    line-height: 26px;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .casestudies-card .casestudies-left .casestudies-img {
        aspect-ratio: 3/4.5;
        height: 305px;
    }
    /***end case-studies-section***/

    /***testimonials-slider***/
    .client-testimonials-section {
        padding: 60px 0;
    }
    .client-testimonials-section .section-heading h2 span {
        display: block;
    }
    .testimonials-slider .testimonials-card {
        padding: 24px 22px;
        .testimonials-content p {
            font-size: 18px;
            line-height: 27px;
        }
        .testimonials-author .testimonials-author-details h5 {
            font-size: 18px;
            line-height: 24px;
        }
        .testimonials-author .testimonials-author-details span {
            font-size: 14px;
            line-height: 18px;
        }
        .testimonials-content::after {
            width: 27.64px;
            height: 24px;
        }
        .testimonials-content {
            padding-left: 52px;
        }
        .testimonials-content::before {
            top: 36px;
        }
        .testimonials-author {
            margin-left: 0px;
        }
        .testimonials-author .testimonials-author-img {
            width: 40px;
            height: 40px;
        }
    }

    /*** end testimonials-slider***/
    /***cta-section ***/
    .cta-section .cta-inner {
        padding: 35px 16px 110px;
        margin-bottom: 31px;
        .cta-details {
            padding: 0px 0px 10px;
            h2 {
                font-size: 20px;
                line-height: 28px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    /***end cta-section***/

    .services-accordion {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        padding: 15px 30px 15px 30px;
                    }
                }
                .accordion-collapse {
                    top: 0px;
                }
                .services-accordion-icon {
                    display: block;
                }
            }
        }
    }
    .services-accordion-card .services-accordion-details h3 {
        padding-right: 50px;
    }
    .faq-section {
        padding: 60px 0 60px;
        .faq-accordion {
            .accordion-button {
                font-size: 18px;
                line-height: 26px;
                padding: 10px 15px;
            }
            .accordion-body {
                padding: 0 15px 10px;
            }
            .accordion-collapse {
                top: 0;
            }
        }
    }

    .our-specialities-details .our-specialities-content .specialities-content-right img {
        max-width: 100%;
    }
    .our-specialities-details .our-specialities-content .specialities-content-left .specialities-description {
        h3 {
            font-size: 22px;
            line-height: 32px;
        }
        h5 {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .agile-banner-section {
        padding: 40px 0;
    }
    .agile-banner-section .agile-banner-heading {
        padding-bottom: 20px;
        .agile-main-heading h1 {
            font-size: 36px;
            line-height: 43px;
        }
        .agile-banner-subheading {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .our-offerings-section {
        padding: 40px 0;
    }
    .cta-section.gradient-cta-section {
        padding: 40px 0 60px 0;
    }
    .our-specialities-section {
        padding: 60px 0;
    }
    .edtech-game-section {
        padding: 60px 0;
    }
    .edtech-game-section .section-heading {
        padding-bottom: 10px;
    }
    .offering-card .offering-card-icon {
        width: 60px;
        height: 60px;
        margin-bottom: 16px;
        border-radius: 10px;
    }
    .offering-card .offering-card-icon img {
        max-width: 34px;
    }
    .offering-card .offering-card-content p {
        font-size: 14px;
        line-height: 20px;
    }
    .our-specialities-details .our-specialities-content .specialities-content-left ul li {
        font-size: 16px;
        line-height: 24px;
    }
    .icon-horizontal-card .horizontal-card-content p {
        font-size: 14px;
        line-height: 20px;
    }
    .industries-solutions-section {
        padding: 60px 0;
    }
    .icon-horizontal-card .horizontal-card-content {
        padding-left: 16px;
    }
    .icon-horizontal-card {
        padding: 16px;
    }
    .industries-page-middle .cta-section.gradient-cta-section .cta-inner {
        padding: 32px 20px 110px;
    }

    /*****services*******/
    .service-left-tabs {
        border-bottom: 1px solid var(--tab-border-color);
        margin-bottom: 30px;
        padding-bottom: 10px;
        position: relative;
        top: auto;
        max-height: fit-content !important;
        overflow: hidden !important;
    }

    .service-feature-card {
        padding: 24px;
        .service-short-description p {
            font-size: 16px;
            line-height: 22px;
        }
        .service-feature-image {
            .service-img-box {
                .service-img-label {
                    padding: 8px;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    /*****end services***/
    /***terms-policy-middle***/
    .terms-policy-section {
        padding: 42px 0 60px 0;
        .terms-policy-content {
            font-size: 24px;
            line-height: 34px;
            h2 {
                font-size: 22px;
                line-height: 32px;
                margin-top: 8px;
            }
            h3 {
                font-size: 20px;
                line-height: 32px;
                margin-top: 0;
            }
        }
    }
    /***End terms-policy-middle***/

    /***error-page-middle***/
    .error-content-section {
        padding: 40px 0 60px 0;
        .error-content-row {
            flex-wrap: wrap-reverse;
        }
        .error-page-title {
            text-align: center;
            h2 {
                font-size: 22px;
                line-height: 32px;
            }
            h1 {
                font-size: 28px;
                line-height: 38px;
            }
        }
        .error-page-img {
            text-align: center;
            padding-bottom: 30px;
            img {
                max-width: 350px;
                &.error-img-500 {
                    max-width: 250px;
                }
            }
        }
        .error-page-msg {
            max-width: 350px;
            margin: 0 auto;
            text-align: center;
        }
        .back-home-btn{
            text-align: center;
        }
        .error-page-title.title-error-500 h1 span {
            display: inline;
        }
    }
    .thankyou-page-middle .error-content-section .error-page-img img.error-img-500{
        max-width: 140px;
    }
    /***end error-page-middle***/
    /***terms-policy-middle***/
    .default-hero-section {
        padding: 30px 0 30px 0;
    }
    /*** endterms-policy-middle***/
}
@media (max-width: 575px) {
    .main-wrapper {
        padding-top: 72px;
    }
    .wrapper-pt-0 {
        padding-top: 0;
    }
    .section-heading h2 {
        font-size: 32px;
        line-height: 40px;
    }
    .heading {
        h1 {
            font-size: 36px;
            line-height: 43px;
        }
        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
    /***hero-banner-section***/
    .hero-banner-section {
        .hero-banner-heading {
            display: block;
            position: relative;
        }
        .banner-heading-left {
            padding-right: 0;
            .main-heading {
                padding-right: 100px;
                max-width: 450px;
            }
            .main-heading h1 {
                font-size: 36px;
                line-height: 43px;
            }
            .banner-description p {
                font-size: 16px;
                line-height: 26px;
            }
        }
        .banner-heading-right {
            position: absolute;
            top: -10px;
            right: 0;
            .banner-video {
                display: inline-block;
                padding: 12px;
                .banner-video-thumbnail {
                    width: 60px;
                    height: 60px;
                }
            }
            .video-play-icon {
                width: 13px;
            }
        }
    }

    .behind-services-section .behind-services-inner .technologies-logos .technologies-left {
        max-width: 62px;
    }
    /***case-studies-section****/
    .casestudies-card {
        display: block;
        margin: 0 auto;
        .casestudies-left {
            max-width: 100%;
            .casestudies-img {
                position: relative;
                height: 260px;
                aspect-ratio: auto;
            }
        }
        .casestudies-right {
            max-width: 100%;
        }
    }
    /***end case-studies-section**/
    /**behind-services-section**/
    .behind-services-section .behind-services-inner .technologies-logos::after {
        width: calc(100vw - 352px);
        bottom: 2.5px;
    }
    /**endbehind-services-section**/
    /**error-content-section**/
    .error-content-section .error-page-img {
        padding-bottom: 30px;
        img {
            max-width: 300px;
            &.error-img-500 {
                max-width: 180px;
            }
        }
    }
    
    /**end error-content-section**/
}
@media (max-width: 416px) {
    /***case-studies-section***/
    .casestudies-card .casestudies-left .casestudies-img {
        position: relative;
        height: 200px;
    }
    /***end case-studies-section**/
}
@media (max-width: 360px) {
    .behind-services-section .behind-services-inner .technologies-logos::after {
        display: none;
    }
    .technologies-logos .technologies-left {
        padding-bottom: 120px;
    }
}

@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
    .heading {
        h1 {
            font-size: 40px;
            line-height: 48px;
        }
    }

    /***home page***/
    .hero-banner-section {
        .banner-heading-left {
            padding-right: 30px;
            .main-heading h1 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }

    .card-link {
        width: 320px;
        height: 240px;
    }
    .section-heading h2 {
        font-size: 36px;
        line-height: 43px;
    }
    .testimonials-slider {
        .testimonials-card {
            .testimonials-content p {
                font-size: 22px;
                line-height: 33px;
            }
        }
    }
    .work-process-slider {
        .work-process-content {
            .work-process-left {
                .work-process-details h3 {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }
    .project-types-slider {
        .project-type-card {
            .project-type-details h3 {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    .casestudies-card {
        .casestudies-right {
            .casestudies-description h3 {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
    .testimonials-slider {
        .testimonials-card {
            .testimonials-content p {
                font-size: 20px;
                line-height: 33px;
            }
        }
    }
    .cta-section .cta-inner {
        padding: 50px 50px;
        min-height: 290px;
        .cta-details h2 {
            font-size: 36px;
            line-height: 44px;
        }
    }
    /***end home page***/
    /***industries page***/
    .agile-banner-section {
        .agile-banner-heading {
            .agile-main-heading h1 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }
    .offering-card {
        .offering-card-content p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .our-specialities-details {
        .our-specialities-content {
            .specialities-content-left {
                .specialities-description h3 {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }
    .our-specialities-section {
        .section-heading {
            padding-bottom: 20px;
        }
    }
    /** end industries page***/
}

/********/

.tech-col-1 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 10s ease-in-out infinite;
            background-color: #fff;
            transform-origin: center center;
            &:first-child {
                animation-delay: -2.5s;
            }
            &:nth-child(2) {
                animation-delay: -5s;
            }
            &:nth-child(3) {
                animation-delay: -7.5s;
            }
            &:nth-child(4) {
                animation-delay: -10s;
            }
            //  &:nth-child(3) {
            //     animation-delay: -6s;
            //  }
        }
    }
}

.tech-col-2 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 15s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -3.75s;
            }
            &:nth-child(2) {
                animation-delay: -7.5s;
            }
            &:nth-child(3) {
                animation-delay: -11.25s;
            }
            &:nth-child(4) {
                animation-delay: -15s;
            }
        }
    }
}

.tech-col-3 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 20s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -5s;
            }
            &:nth-child(2) {
                animation-delay: -10s;
            }
            &:nth-child(3) {
                animation-delay: -15s;
            }
            &:nth-child(4) {
                animation-delay: -20s;
            }
        }
    }
}
.tech-col-4 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 25s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -6.25s;
            }
            &:nth-child(2) {
                animation-delay: 12.5s;
            }
            &:nth-child(3) {
                animation-delay: -18.75s;
            }
            &:nth-child(4) {
                animation-delay: -25s;
            }
        }
    }
}
.tech-col-5 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 30s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -7.5s;
            }
            &:nth-child(2) {
                animation-delay: -15s;
            }
            &:nth-child(3) {
                animation-delay: -22.5s;
            }
            &:nth-child(4) {
                animation-delay: -30s;
            }
        }
    }
}
.tech-col-6 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 35s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -7s;
            }
            &:nth-child(2) {
                animation-delay: -14s;
            }
            &:nth-child(3) {
                animation-delay: -21s;
            }
            &:nth-child(4) {
                animation-delay: -35s;
            }
        }
    }
}
.tech-col-7 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 30s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -7.5s;
            }
            &:nth-child(2) {
                animation-delay: -15s;
            }
            &:nth-child(3) {
                animation-delay: -22.5s;
            }
            &:nth-child(4) {
                animation-delay: -30s;
            }
        }
    }
}
.tech-col-8 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 25s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -6.25s;
            }
            &:nth-child(2) {
                animation-delay: 12.5s;
            }
            &:nth-child(3) {
                animation-delay: -18.75s;
            }
            &:nth-child(4) {
                animation-delay: -25s;
            }
        }
    }
}
.tech-col-9 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 20s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -5s;
            }
            &:nth-child(2) {
                animation-delay: -10s;
            }
            &:nth-child(3) {
                animation-delay: -15s;
            }
            &:nth-child(4) {
                animation-delay: -20s;
            }
        }
    }
}
.tech-col-10 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 15s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -3.75s;
            }
            &:nth-child(2) {
                animation-delay: -7.5s;
            }
            &:nth-child(3) {
                animation-delay: -11.25s;
            }
            &:nth-child(4) {
                animation-delay: -15s;
            }
        }
    }
}
.tech-col-11 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 20s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -5s;
            }
            &:nth-child(2) {
                animation-delay: -10s;
            }
            &:nth-child(3) {
                animation-delay: -15s;
            }
            &:nth-child(4) {
                animation-delay: -20s;
            }
        }
    }
}
.tech-col-12 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 25s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -6.25s;
            }
            &:nth-child(2) {
                animation-delay: 12.5s;
            }
            &:nth-child(3) {
                animation-delay: -18.75s;
            }
            &:nth-child(4) {
                animation-delay: -25s;
            }
        }
    }
}
.tech-col-13 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 25s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -6.25s;
            }
            &:nth-child(2) {
                animation-delay: 12.5s;
            }
            &:nth-child(3) {
                animation-delay: -18.75s;
            }
            &:nth-child(4) {
                animation-delay: -25s;
            }
        }
    }
}
.tech-col-15 {
    .technologies-image {
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            animation: imgRotator 30s ease-in-out infinite;
            background-color: #fff;
            &:first-child {
                animation-delay: -7.5s;
            }
            &:nth-child(2) {
                animation-delay: -15s;
            }
            &:nth-child(3) {
                animation-delay: -22.5s;
            }
            &:nth-child(4) {
                animation-delay: -30s;
            }
        }
    }
}

@keyframes imgRotator {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    17% {
        opacity: 1;
        transform: scale(1);
    }
    25% {
        opacity: 0;
        transform: scale(0);
    }
    92% {
        opacity: 0;
        transform: scale(0);
    }
    98% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes tag-animation {
    0% {
        background-color: #22a5d9;
    }
    30% {
        background-color: #07252f;
    }
}


.service-left-tabs{
    max-height: 758px;
    overflow: auto !important;
}
.service-left-tabs::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

/* Track */
.service-left-tabs::-webkit-scrollbar-track {
    background: #e0f2f9;
}

/* Handle */
.service-left-tabs::-webkit-scrollbar-thumb {
    background: #e0f2f9;
}


.error-content-section.thank-you-section{
    padding: 80px 0;
    .thankyou-description{
        padding-bottom: 24px;
    }
    .error-page-img {
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
         img{
            width: 100%;
            min-height: 338px;
            max-height: 500px;
            height: 100%;
            display: block;
            margin: 0 auto;
            
         }
    }
  }

  @media (max-width:767px){
    .error-content-section.thank-you-section{
        padding: 40px 0;
        .thankyou-description{
           text-align: center !important;
        }
        .error-page-img {
            max-width: 350px;
            width: 100%;
            margin: 0 auto;
             img{
                max-width:100% !important;
                min-height: 350px;
                
             }
        }
      }
  }
 

  .bog-detail-page .cta-details h4{
        color: #fff;
  }