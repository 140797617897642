/***casestudies-hero-section***/
.casestudies-hero-section {
  padding: 32px 0;
}
/***end casestudies-hero-section***/
/***casestudies-stories-section***/
.casestudies-stories-section {
  position: relative;
  padding-bottom: 60px;
  &::after {
    content: "";
    background-color: var(--light-sky-background);
    width: 100%;
    height: 270px;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    min-height: 270px;
  }
  .casestudies-stories-inner {
    position: relative;
    z-index: 2;
  }
  .casestudies-category-tab {
    padding-bottom: 26px;
    .casestudies-category-nav {
      .nav-item {
        margin-right: 16px;
        margin-bottom: 16px;
        .nav-link {
          padding: 16px 30px;
          background-color: var(--casestudies-tab-background);
          border-radius: 8px;
          color: var(--text-secondary);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          transition: all 0.5s;
          &.active,
          &:hover {
            background-color: var(--casestudies-tabhover-background);
            color: var(--text-white);
          }
        }
      }
    }
  }
  .casestudies-stories-list {
    padding-bottom: 50px;
    .casestudies-stories-card {
      display: flex;
      padding: 24px;
      background-color: var(--casestudies-card-background);
      border-radius: 16px;
      box-shadow: 0px 0px 21.19205px 0px rgba(34, 165, 217, 0.05);
      margin-bottom: 30px;
      &:hover {
        box-shadow: 0px 0px 21.19205px 0px rgba(34, 165, 217, 0.15);

        .casestudies-thumbnail img {
          transform: scale(1.1);
        }
        .casestudies-content .casestudies-details h3 a {
          color: var(--primary-color);
        }
        .casestudies-content
          .casestudies-readmore-btn
          .casestudies-readmore.btn {
          color: var(--primary-color);
        }
      }
      .casestudies-thumbnail {
        width: 254px;
        flex-shrink: 0;
        border-radius: 12px;
        overflow: hidden;
        a {
          display: block;
          height: 100%;
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          transition: all 0.4s;
          transform: scale(1);
          transform-origin: center center;
        }
      }
      .casestudies-content {
        flex: 1;
        flex-grow: 1;
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        .casestudies-details {
          padding-bottom: 42px;
          flex: 1;
          h3 {
            font-size: 24px;
            font-weight: 500;
            line-height: 31px;
            a {
              
              font-size: 24px;
              font-weight: 500;
              line-height: 31px;
              &:hover {
                
              }
            }
          }
          p {
            
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
        .casestudies-readmore-btn {
          .casestudies-readmore {
            &.btn {
              padding: 5px 32px 5px 0px;
              color: var(--casestudies-readmore-color);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 27px;
              transition: all 0.4s;
              position: relative;
              box-shadow: none;
              outline: none;
              border: 0;
              &:hover {
                
                &::after {
                  right: 0;
                  opacity: 1;
                }
              }
              &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM21.7071 8.70711C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928932C14.9526 0.538408 14.3195 0.538408 13.9289 0.928932C13.5384 1.31946 13.5384 1.95262 13.9289 2.34315L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.70711ZM1 9H21V7H1V9Z' fill='%2322A5D9'/%3E%3C/svg%3E");
                position: absolute;
                width: 20px;
                height: 20px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                opacity: 0;
                transition: all 0.2s linear;
              }
            }
          }
        }
      }
    }
  }
  .article-pagination {
    .pagination {
      justify-content: center;
      margin-bottom: 0;
      .page-item {
        position: relative;
        .page-link {
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          
          border: 0px;
          padding: 6px;
          border-radius: 8px;
          background-color: transparent;
          box-shadow: 0px 0px 0px 0px;
          min-width: 44px;
          height: 44px;
          margin: 4px;

          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: var(--primary-color);
            color: var(--white-color);
          }
        }
        &.active {
          .page-link {
            background-color: var(--primary-color);
            color: var(--white-color);
          }
        }
        &:first-child {
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -24px;
            transform: translate(0%, -50%);
            transition: all 0.3s ease;
            width: 20px;
            height: 14px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.5 9C22.0523 9 22.5 8.55228 22.5 8C22.5 7.44772 22.0523 7 21.5 7L21.5 9ZM0.792893 7.29289C0.402369 7.68342 0.402369 8.31658 0.792892 8.7071L7.15685 15.0711C7.54738 15.4616 8.18054 15.4616 8.57107 15.0711C8.96159 14.6805 8.96159 14.0474 8.57107 13.6569L2.91421 8L8.57107 2.34314C8.96159 1.95262 8.96159 1.31946 8.57107 0.928931C8.18054 0.538407 7.54738 0.538407 7.15685 0.928931L0.792893 7.29289ZM21.5 7L1.5 7L1.5 9L21.5 9L21.5 7Z' fill='%2322A5D9'/%3E%3C/svg%3E%0A");
          }
          .page-link {
            color: var(--primary-color);
            &:hover {
              background-color: transparent;
              color: var(--primary-color);
            }
          }
        }
        &:last-child {
          &::after {
            content: "";
            position: absolute;
            right: -24px;
            top: 53%;
            transform: translate(0%, -53%);
            transition: all 0.3s ease;
            width: 20px;
            height: 14px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7C0.947715 7 0.5 7.44772 0.5 8C0.5 8.55228 0.947715 9 1.5 9V7ZM22.2071 8.70711C22.5976 8.31658 22.5976 7.68342 22.2071 7.29289L15.8431 0.928932C15.4526 0.538408 14.8195 0.538408 14.4289 0.928932C14.0384 1.31946 14.0384 1.95262 14.4289 2.34315L20.0858 8L14.4289 13.6569C14.0384 14.0474 14.0384 14.6805 14.4289 15.0711C14.8195 15.4616 15.4526 15.4616 15.8431 15.0711L22.2071 8.70711ZM1.5 9H21.5V7H1.5V9Z' fill='%2322A5D9'/%3E%3C/svg%3E%0A");
          }
          .page-link {
            color: var(--primary-color);
            &:hover {
              background-color: transparent;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}
/***end casestudies-stories-section***/
/***cta-sectio***/
.case-studies-middle .cta-section {
  padding: 60px 0 120px 0;
}
/***end cta-sectio***/

/******Case Studies Details********/

/***caseStudiesdet-hero-section***/
.caseStudiesdet-hero-section {
  padding: 32px 0 42px 0;
  position: relative;
  &::after {
    content: "";
    background-color: var(--body-background);
    width: 100%;
    height: 107px;
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
  }
  .caseStudiesdet-hero-inner {
    position: relative;
    z-index: 2;
  }
  .col-hero-end {
    align-self: flex-end;
  }
  .caseStudiesdet-heading {
    padding-bottom: 12px;
    max-width: 650px;
    h1 {
      font-size: 54px;
      line-height: 65px;
    }
  }
  .caseStudies-sub-heading {
    padding-bottom: 32px;
    h2 {
      
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
    p {
      
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  .caseStudiesdet-tag {
    .caseStudiesdet-tag-card {
      background-color: var(--white-color);
      padding: 16px;
      border-radius: 16px;
      p {
        padding-bottom: 6px;
        &.caseStudiesdet-tag-title {
          
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
        }
        &.caseStudiesdet-tag-info {
          padding-bottom: 0;
          
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }
      }
    }
  }
  .caseStudiesdet-hero-img {
    text-align: right;
    img {
      width: 380px;
      border-radius: 16px;
    }
  }
}

/***end caseStudiesdet-hero-section***/
/***caseStudies-details-section***/
.caseStudies-details-section {
  .caseStudies-social-media {
    position: sticky;
    top: 150px;
    .caseStudies-social-list {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      li {
        margin-bottom: 12px;
        list-style: none;

        a {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          background-color: var(--primary-color);
          transition: all 0.3s;
          &:hover {
            
          }
          img {
            filter: brightness(100);
            width: 18px;
          }
        }
        &:last-child {
          margin-right: 0;
          a {
            background: unset;
            img {
              filter: unset;
            }
          }
        }
      }
    }
  }
}
/***end caseStudies-details-section***/
.caseStudies-details-section {
  padding: 0 0 60px 0;
  .caseStudies-content-wrapper {
    padding-left: 20px;
    h2 {
      
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
    p,
    li {
      
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
    ul li {
      margin-bottom: 16px;
    }
    .caseStudies-project-overview {
      padding-bottom: 42px;
    }
    .caseStudies-major-challenges {
      padding-bottom: 42px;
      ul.major-challenges-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          position: relative;
          display: flex;
          list-style: none;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            background-image: url(../assets/images/list-icon-1.svg);
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            min-width: 20px;
            flex-shrink: 0;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-top: 2px;
          }
        }
      }
    }
    .caseStudies-our-solutions {
      padding-bottom: 42px;
      ul.our-solutions-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          position: relative;
          display: flex;
          list-style: none;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            background-image: url(../assets/images/list-icon.svg);
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            min-width: 20px;
            flex-shrink: 0;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-top: 2px;
          }
        }
      }
    }
    .caseStudies-main-features {
      padding-bottom: 42px;
      ul.main-features-list {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          padding: 12px 12px 12px 28px;
          border-radius: 8px;
          border: 1px solid var(--border-color-primary-ltr);
          display: inline-block;
          margin: 0 16px 16px 0;
          position: relative;
          
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 27px;
          background-color: var(--white-background-color);
          &::after {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: var(--primary-background-color);
            position: absolute;
            display: inline-block;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
          }
        }
      }
    }
    .caseStudies-technology-stack {
      padding-bottom: 20px;
      .caseStudies-technology-list {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin-bottom: 8px;
          .caseStudies-tecsta-title {
            h3 {
              
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px;
            }
          }
          .caseStudies-tecsta-div {
            display: flex;
            flex-wrap: wrap;
            .caseStudies-tecsta-box {
              text-align: center;
              margin-right: 24px;
              margin-bottom: 16px;
              .caseStudies-tecsta-icon {
                width: 60px;
                height: 60px;
                background-color: var(--white-background-color);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 6px;
                img {
                  width: 30px;
                }
              }
              .caseStudies-tecsta-caption {
                p {
                  
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
    .caseStudies-stake-holder {
      p {
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        padding-bottom: 6px;
      }
      h4 {
        
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }
  .caseStudies-details-right {
    position: sticky;
    top: 150px;
    .caseStudies-details-tab {
      margin: 0;
      padding: 0;
      li {
        list-style: none;

        a.tab-link {
          padding: 19px 32px 19px 28px;
          display: block;
          border-radius: 8px;
          color: var(--caseStudies-tab-link-color);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          transition: all 0.5s;
          position: relative;
          &::after {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: var(--primary-background-color);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            opacity: 0;
            transition: all 0.5s;
          }
          &.active,
          &:hover {
            background-color: var(--white-background-color);
            
            font-weight: 600;
          }
          &.active {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .social-lg-none {
    display: none;
  }
}
/******end Case Studies Details********/

@media (max-width: 1400px) {
  .casestudies-stories-section {
    .casestudies-category-tab {
      .casestudies-category-nav {
        .nav-item {
          .nav-link {
            padding: 12px 20px;
          }
        }
      }
    }
    .casestudies-stories-list {
      .casestudies-stories-card {
        .casestudies-content {
          .casestudies-details {
            padding-bottom: 20px;
            h3 {
              font-size: 22px;
              line-height: 30px;
              a {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .casestudies-stories-section {
    .casestudies-stories-list {
      .casestudies-stories-card .casestudies-thumbnail {
        width: 215px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .casestudies-stories-section {
    .casestudies-stories-list {
      .casestudies-stories-card {
        padding: 12px;
        .casestudies-content {
          .casestudies-details {
            h3 {
              font-size: 18px;
              line-height: 26px;
              a {
                font-size: 18px;
                line-height: 26px;
              }
            }
            p {
              font-size: 16px;
              line-height: 22px;
            }
          }
          .casestudies-readmore-btn {
            .casestudies-readmore.btn {
              font-size: 16px;
              line-height: 24px;
              &::after {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
  .caseStudiesdet-hero-section {
    .caseStudiesdet-heading h1 {
      font-size: 44px;
      line-height: 54px;
    }
    .caseStudies-sub-heading p {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
@media (max-width: 991px) {
  .caseStudiesdet-hero-section {
    &::after {
      height: 85px;
    }
    .caseStudiesdet-hero-row {
      align-items: flex-start;
    }
    .caseStudiesdet-heading h1 {
      font-size: 36px;
      line-height: 44px;
    }
    .caseStudies-sub-heading p {
      font-size: 16px;
      line-height: 24px;
    }
    .caseStudiesdet-tag .caseStudiesdet-tag-card {
      padding: 12px;

      p.caseStudiesdet-tag-info {
        font-size: 16px;
        line-height: 24px;
      }
      p.caseStudiesdet-tag-title {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .caseStudies-sub-heading {
      padding-bottom: 22px;
      h2 {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
  .casestudies-stories-section {
    padding-bottom: 40px;
    .casestudies-stories-list .casestudies-stories-card {
      flex-direction: column;
      height: calc(100% - 30px);
      .casestudies-content {
        padding-left: 0;
      }
      .casestudies-thumbnail {
        width: 100%;
        height: 260px;
        margin-bottom: 20px;
      }
    }
    .article-pagination {
      .pagination {
        .page-item {
          .page-link {
            font-size: 14px;
            line-height: 20px;
            min-width: 34px;
            height: 34px;
            padding: 4px;
            margin: 2px;
          }
          &:first-child {
            &:after {
              width: 14px;
              height: 10px;
              left: -15px;
              top: 52%;
              transform: translate(0%, -52%);
            }
          }
          &:last-child {
            &:after {
              width: 14px;
              height: 10px;
              right: -15px;
              top: 55%;
              transform: translate(0%, -55%);
            }
          }
        }
      }
    }
    .casestudies-category-tab .casestudies-category-nav .nav-item {
      margin-right: 10px;
      margin-bottom: 10px;
      .nav-link {
        padding: 8px 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .case-studies-middle .cta-section {
    padding: 40px 0 80px 0;
  }
  .social-md-none {
    display: none;
  }

  .caseStudies-details-section {
    padding-bottom: 40px;
    .caseStudies-content-wrapper {
      padding-left: 0px;
    }
    .caseStudies-details-right {
      top: 120px;
      .caseStudies-right-tab {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--tab-border-color);
      }

      .caseStudies-details-tab li a.tab-link {
        font-size: 16px;
        padding: 12px 40px 12px 14px;
      }
    }
    .social-lg-none {
      display: block;
    }
    .caseStudies-social-media .caseStudies-social-list {
      flex-direction: row;
      li {
        margin-right: 12px;
      }
    }
    .caseStudies-content-wrapper p,
    .caseStudies-content-wrapper li {
      font-size: 16px;
      line-height: 24px;
    }
    .caseStudies-content-wrapper h2 {
      font-size: 22px;
      line-height: 28px;
    }
    .caseStudies-content-wrapper
      .caseStudies-main-features
      ul.main-features-list
      li {
      font-size: 16px;
      line-height: 22px;
      padding: 8px 8px 8px 32px;
      margin: 0 10px 10px 0;
    }
    .caseStudies-content-wrapper
      .caseStudies-technology-stack
      .caseStudies-technology-list
      li {
      margin-bottom: 12px;
      .caseStudies-tecsta-div .caseStudies-tecsta-box {
        margin-right: 15px;
      }
    }
  }
}
@media (max-width: 767px) {
  .casestudies-stories-section {
    .casestudies-stories-list .casestudies-stories-card .casestudies-thumbnail {
      height: 190px;
    }
    .casestudies-category-tab .casestudies-category-nav .nav-item .nav-link {
      padding: 6px 10px;
    }
    .casestudies-stories-list {
      padding-bottom: 30px;
    }
  }
  .caseStudies-details-row {
    flex-direction: column-reverse;
  }
  .caseStudiesdet-hero-section {
    .caseStudiesdet-heading h1 {
      font-size: 28px;
      line-height: 38px;
    }
    .caseStudiesdet-hero-content {
      padding-bottom: 40px;
    }
    .caseStudiesdet-hero-img {
      text-align: center;
      img {
        width: 280px;
      }
    }
  }

  .caseStudies-details-section {
    padding-bottom: 30px;
    .caseStudies-details-right {
      position: relative;
      top: 0;
      padding-bottom: 10px;
    }
    .caseStudies-social-media .caseStudies-social-list {
      justify-content: flex-end;
    }
    .caseStudies-content-wrapper {
      .caseStudies-project-overview,
      .caseStudies-major-challenges,
      .caseStudies-our-solutions,
      .caseStudies-main-features {
        padding-bottom: 30px;
      }
      .caseStudies-major-challenges ul.major-challenges-list li::before {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }
      .caseStudies-our-solutions ul.our-solutions-list li::before {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }
      .caseStudies-technology-stack
        .caseStudies-technology-list
        li
        .caseStudies-tecsta-div
        .caseStudies-tecsta-box
        .caseStudies-tecsta-icon {
        width: 50px;
        height: 50px;
      }
    }
    .caseStudies-social-media .caseStudies-social-list li a {
      width: 35px;
      height: 35px;
    }
  }
  .case-studies-middle .cta-section {
    padding: 30px 0 60px 0;
  }
}
@media (max-width: 575px) {
  .casestudies-stories-section {
    .casestudies-stories-list .casestudies-stories-card .casestudies-thumbnail {
      height: 240px;
    }
    .article-pagination {
      .pagination {
        .page-item {
          .page-link {
            font-size: 12px;
            line-height: 16px;
            min-width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
  .caseStudiesdet-hero-section {
    .caseStudiesdet-tag .caseStudiesdet-tag-card {
      margin-bottom: 12px;
      p.caseStudiesdet-tag-title {
        padding-bottom: 3px;
      }
      p.caseStudiesdet-tag-info {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .caseStudiesdet-hero-img img {
      width: 200px;
    }
  }
}
