:root {
  /**font variables**/
  --dm-sans: "DM Sans", sans-serif;
  /**end font variables**/

  /****************/
  --primary-color: #22a5d9;
  --secondary-color: #07252f;
  --white-color: #fff;
  /****************/

  /**color variables**/
  --light-sky-background: #22a5d91a;
  --primary-background-color: #22a5d9;
  --secondary-background-ltr: #e1f2f9;
  --secondary-background-lt: #f6fbfd;
  --white-background-color: #ffffff;
  --body-background: #f6fbfd;
  --text-secondary: #07252f;
  --text-secondary-lt: rgb(7 37 47 / 50%);
  --text-secondary-ltr: rgb(7 37 47 / 80%);
  --text-primary: #22a5d9;
  --text-primary-ltr: #e1f2f9;
  --text-black: #000;
  --text-white: #fff;
  --light-text-color: rgba(1, 4, 5, 0.75);
  --link-color: #22a5d9;
  --link-hover-color: #07252f;
  --header-background: rgba(224 242 249);
  --accordion-hover: rgba(34, 165, 217, 0.05);
  --card-background: #e1f2f9;
  --footer-border: rgba(34, 165, 217, 0.5);
  --footer-link: rgba(1, 4, 5, 0.65);
  --offering-icon-bg: rgba(34, 165, 217, 0.05);
  --gradient-background: linear-gradient(0deg, rgba(34, 165, 217, 0.1) 0%, rgba(34, 165, 217, 0.1) 100%);
  --tab-background: rgba(34, 165, 217, 0.05);
  --tab-nav-link: rgba(7, 37, 47, 0.75);
  --bg-background: #22a5d9;
  --shadow-color: rgba(0, 134, 230, 0.4);
  --article-card-bg: rgba(7, 37, 47, 0.65);
  --article-chip-bg: rgba(34 165 217 / 65%);
  --tab-border-color: rgba(34, 165, 217, 0.3);
  --work-border-color: #8dd0ff;
  --btn-primary-light-hover: rgba(34 165 217 / 65%);
  --article-bg: rgba(225, 242, 249, 1);
  --computing-link: rgba(7, 37, 47, 65%);
  --nav-link-color: #07252f;
  --nav-link-hover: #22a5d9;
  --submenu-border: rgba(0, 1, 26, 0.1);
  --submenu-background: #f6fbfd;
  --btn-secondary-background: #e1f2f9;
  --btn-secondary-color: #07252f;
  --btn-hover-shadow: #07252f;
  --btn-box-shadow:rgba(39, 48, 75, 0.2);
  --offcanvas-body-background: #f6fbfd;
  --border-color-primary: #22a5d9;
  --border-color-primary-ltr: rgba(34, 165, 217, 0.5);
  --border-color-secondary: #07252f;
  --border-color-secondary-lt: rgba(7, 37, 47, 0.12);
  --border-color-secondary-ltr: rgba(7, 37, 47, 0.1);
  --border-color-white:#fff;
  --step-icon-background: #fff;
  --about-hero-background: #e1f2f9;
  --careers-hero-overlay: #07252f;
  --careers-promises-title: #040405;
  --footerlink-hover-border:linear-gradient(#22a5d9, #22a5d9);
  --file-input-background:rgba(225, 242, 249, 0.16);
  --range-input-line:rgba(7, 37, 47, 0.1);
  --casestudies-tab-background:#ffffff;
  --casestudies-tabhover-background:#22a5d9;
  --casestudies-card-background:#ffffff;
  --casestudies-card-shadow:rgba(34, 165, 217, 0.05);
  --casestudies-readmore-color:rgb(7 37 47 / 75%);
  --caseStudies-tab-link-color: rgb(7 37 47 / 75%);
  /**end variables**/
}
