/****contact-hero-section******/
.contact-hero-section {
    padding: 32px 0 120px;
    overflow: hidden;
    .request-proposal-heading {
        margin-bottom: 41px;
    }
    .contact-form-wrapper {
        .contact-form-title {
            padding-bottom: 12px;
            h2 {
                
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
            }
        }
        .input-wrapper {
            margin-bottom: 24px;
        }
        .btn-primary {
            margin-top: 8px;
        }
    }
    .contact-banner-img {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 60px;
            left: 60px;
            width: 100vw;
            height: 100%;
            background: var(--gradient-background);
            border-radius: 0 0 0 16px;
            z-index: -1;
        }
    }
}

/****end-contact-hero-section******/
.contact-page-middle .case-studies-section {
    padding-top: 0;
}

/****our-locations-section*****/
.our-locations-section {
    padding: 120px 0;
    .section-heading {
        padding-bottom: 20px;
    }
}
.our-locations-details {
    .locations-tabs {
        .nav {
            flex-direction: column;
        }
        .nav-item {
            padding-bottom: 32px;
            .nav-link {
                text-align: left;
                padding: 0;
                color: var(--secondary-color);
                &.active {
                    .locations-name {
                        
                        &::after {
                            right: 0;
                            opacity: 1;
                        }
                        color: var(--primary-color);
                    }
                }
                &:hover {
                    .locations-name {
                        
                    }
                }
                .locations-name {
                    
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    display: inline-block;
                    margin-bottom: 12px;
                    padding-right: 40px;
                    position: relative;
                    transition: all 0.4s;
                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        background-image: url(../assets/images/loc-right.svg);
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat;
                        right: 15px;
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: all 0.4s;
                        opacity: 0;
                    }
                }
                .locations-address {
                    
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0;
                    max-width: 380px;
                }
            }
        }
    }
    .locations-tab-content {
        .locations-image {
            display: inline-block;
            padding-right: 26px;
            padding-bottom: 26px;
            position: relative;
        }
        .locations-lg-image {
            width: 441px;
            height: 330px;
            border-radius: 16px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .locations-sm-image {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            border-radius: 16px;
            overflow: hidden;
            width: 240px;
            height: 177px;
            border: 5px solid #e1f2f9;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            .locations-name-box {
                position: absolute;
                width: 100%;
                height: 100%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                left: 0;
                top: 0;
                p {
                    color: #fff;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    img {
                        width: auto;
                        height: auto;
                        object-fit: none;
                    }
                }
            }
        }
    }
    .locations-info {
        display: flex;
        .location-business-info {
            padding-left: 30px;
            .location-for-business {
                padding-bottom: 32px;
            }
            .location-label {
                
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
            p {
                padding-bottom: 12px;
                a {
                    
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 23px;
                    transition: all 0.4s;
                    &:hover {
                        
                    }
                }
            }
        }
    }
}
/***our-clients-section***/
.our-clients-section {
    padding: 120px 0;
    overflow: hidden;
    .section-heading {
        padding-bottom: 20px;
    }
    .contact-map-image {
        text-align: center;
    }
    .cotries-selection {
        position: relative;
        .swiper-arrow {
            text-align: right;
            top: -40px;
            position: absolute;
            padding-bottom: 10px;
            width: 100%;
        }
        .cotries-selection-slider {
            overflow: visible;
            clip-path: inset(-100vw -100vw -100vw 0);
            .swiper-slide {
                width: auto;
            }
            .swiper-slide-active {
                .cotries-card {
                    background-color: var(--primary-background-color);
                }
                .cotries-dis {
                    .cotries-name,
                    .clients-count {
                        color: var(--text-white);
                    }
                }
            }
        }
        .cotries-card {
            width: 180px;
            background-color: var(--white-background-color);
            padding: 12px;
            border-radius: 14px;
            transition: all 0.4s;
            .cotries-flag-img {
                height: 144px;
                background-color: var(--secondary-background-lt);
                padding: 14px;
                display: flex;
                align-items: center;
                border-radius: 14px;
                justify-content: center;
            }
            .cotries-dis {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 0 0 4px;
                .cotries-name {
                    padding-right: 10px;
                    
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    transition: all 0.4s;
                }
                .clients-count {
                    
                    text-align: right;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    transition: all 0.4s;
                }
            }
        }
    }
}
/***end our-clients-section***/
/***social-media-section***/
.social-media-section {
    padding: 0 0 120px 0;
    .section-heading {
        padding-bottom: 20px;
        h2 span {
            display: block;
        }
    }
    .social-tabs {
        background: var(--tab-background);
        border-radius: 8px 8px 0px 0px;
        margin-bottom: 42px;
        .nav-item {
            flex: auto;
            .nav-link {
                background: transparent;
                color: var(--tab-nav-link);
                width: 100%;
                padding: 16px;
                text-align: center;
                position: relative;
                &:hover {
                    
                }
                &.active {
                    
                    font-weight: 500;
                    &::after {
                        transform: scale(1);
                    }
                }
                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: var(--primary-color);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: scale(0);
                    transition: all 0.5s;
                }
            }
        }
    }
    .media-tab-card {
        .media-post-card {
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 30px;
            img {
                width: 100%;
            }
        }
    }
}
/***end social-media-section***/
/****end our-locations-section*****/
@media (max-width: 1366px) {
    .contact-hero-section {
        padding: 32px 0 80px;
    }
    /***our-locations-section***/
    .our-locations-section {
        padding: 80px 0;
    }
    /***end our-locations-section***/
    /***our-clients-section***/
    .our-clients-section {
        padding: 80px 0;
    }
    /***end our-clients-section***/
    /***social-media-section***/
    .social-media-section {
        padding: 0 0 80px 0;
    }
    /***end social-media-section***/
}
@media (max-width: 1200px) {
    /***our-locations-details ***/
    .our-locations-details {
        .locations-tab-content {
            .locations-lg-image {
                width: 338px;
                height: 272px;
            }
            .locations-sm-image {
                width: 210px;
                height: 148px;
            }
        }
    }

    /***end our-locations-details ***/
    /***our-clients-section***/
    .our-clients-section {
        .cotries-selection {
            .swiper-arrow {
                text-align: right;
                top: 0px;
                position: relative;
                padding-bottom: 20px;
                width: 100%;
            }
            .cotries-card {
                width: 150px;
                .cotries-flag-img {
                    height: 125px;
                }
            }
        }
    }
    /***end our-clients-section***/
    /***social-media-section***/
    .social-media-section {
        padding: 0 0 60px 0;
    }
    /***end social-media-section***/
}
@media (max-width: 991px) {
    .contact-hero-section {
        overflow: inherit;
        .contact-banner-img {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 60px;
                left: 60px;
                width: 100vw;
                height: 100%;
                border-radius: 0 0 0 16px;
                z-index: -1;
            }
        }
        .custom-row {
          flex-direction: column-reverse;
          align-items: center;
        }
    }

    /***our-locations-section***/
    .our-locations-section {
        padding: 60px 0;
    }
    .locations-tab-content {
        padding-left: 30px;
    }
    .our-locations-details {
        .locations-tabs .nav-item .nav-link {
            .locations-name {
                font-size: 22px;
                line-height: 30px;
            }
            .locations-address {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .locations-tab-content .locations-lg-image {
            width: 100%;
            max-width: 490px;
        }
        .locations-info {
            flex-flow: column;
            .location-business-info {
                padding-left: 0;
                display: flex;
                padding-top: 30px;
                justify-content: center;
                .location-for-business {
                    padding-right: 30px;
                    padding-bottom: 0;
                    border-right: 1px solid var(--border-color-primary-ltr);
                }
                .location-for-careers {
                    padding-left: 30px;
                }
            }
        }
    }
    /***end our-locations-section***/
    /***our-clients-section***/
    .our-clients-section {
        padding: 60px 0;
        .cotries-selection .cotries-card {
            padding: 8px;
            width: 140px;
            .cotries-flag-img {
                height: 110px;
            }
            .cotries-dis {
                .clients-count {
                    font-size: 22px;
                }
                .cotries-name {
                    font-size: 16px;
                }
            }
        }
    }
    /***end our-clients-section***/
}

@media (max-width: 767px) {
    .contact-hero-section {
        padding: 32px 0 60px;
        .contact-banner-img {
            margin-bottom: 70px;
            &::before {
                top: 30px;
                left: 30px;
                width: 100%;
                border-radius: 16px;
            }
        }
    }
    .request-proposal-wrapper {
        .case-studies-section {
            padding: 60px 0;
        }
    }
    .locations-tab-content {
        padding-left: 0px;
    }
    /***our-clients-section***/
    .our-clients-section {
        .cotries-selection .cotries-card {
            padding: 6px;
            width: 80px;
            .cotries-flag-img {
                height:65px;
                padding: 5px;
            }
            .cotries-dis {
                padding: 4px 0 0 0px;
                flex-flow: column;
                .clients-count {
                    font-size: 16px;
                    line-height: normal;
                }
                .cotries-name {
                    font-size: 14px;
                    padding-right:2px;
                    line-height: normal;
                }
            }
        }
    }
    /***end our-clients-section***/
    /***our-locations-details***/
    .our-locations-details .locations-tabs .nav-item .nav-link .locations-address {
        max-width: 100%;
    }
    /***end our-locations-details***/
}

@media (max-width: 575px) {
    .our-locations-details {
        .locations-tab-content {
            .locations-lg-image {
                height: 240px;
            }
            .locations-sm-image {
                width: 180px;
                height: 120px;
            }
        }
        .locations-info {
            .location-business-info {
                flex-flow: column;
                p {
                    padding-bottom: 6px;
                    a {
                        font-size: 16px;
                    }
                }
                .location-for-business {
                    border: none;
                    padding-right: 0;
                    padding-bottom: 20px;
                }
                .location-for-careers {
                    padding-left: 0px;
                }
            }
        }
    }
    
}
