.blog-detail-section{
    .blog-detail-inner{
    background-color: var(--card-background);
    padding: 32px 0 86px;
    }
    .blog-detail-list{
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;
        li{
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            
            opacity: 0.75;
            list-style: none;
            padding: 0 15px;
            position: relative;
            &:first-child{
                padding-left: 0;
                &::before{
                    display: none;
                }
            }
            &::before{
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 30px;
                
                opacity: 0.75;
                position: absolute;
                left: -3px;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--text-secondary);
            }
        }
    }
    .blog-detail-heading{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 32px;
        h2{
        padding-right: 70px;
        padding-bottom: 0;
        font-size: 54px;
            span{
                color: var(--primary-color);
            }
        }
        .social-icon-list{
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            padding-left: 50px;
            padding-bottom: 15px;
            li{
                margin-right: 8px;
                list-style: none;
                
                a{
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    background-color: var(--primary-color);
                    transition: all 0.3s;
                    &:hover{
                        
                    }
                    img{
                        filter: brightness(100);
                        width: 18px;
                    }

                }
                &:last-child{
                    margin-right: 0;
                    a{
                        background: unset;
                        img{
                            filter: unset;
                        }
                    }
                }
            }
        }
    }
    .blog-detail-banner{
        height: auto;
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
        margin-top: -85px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.blog-list-section{
    padding-top: 32px;
    .blog-left-tab{
        position: sticky;
        top: 150px;
        ul{
            margin: 0;
            padding: 0;
            li{
                margin-bottom: 4px;
                list-style: none;
                a{
                    font-size: 18px;
                    line-height: 21px;
                    font-weight: 500;
                    
                    opacity: 0.75;
                    text-transform: capitalize;
                    padding: 12px 40px 12px 24px;
                    text-align: left;
                    border-radius: 8px;
                    position: relative;
                    transition: all .3s ease;
                    border: 0px;
                    width: 100%;
                    display: inline-block;
                    position: relative;
                    color: var(--text-secondary);
                    &::before{
                        content: '';
                            position: absolute;
                            right: 34px;
                            width: 19px;
                            height: 14px;
                            top: 16px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-image: url(../assets/images/blog-right-arrow.svg);
                            transition: 0.4s;
                            opacity: 0;
                    }
                    &:hover, &.active{
                        background-color: var(--white-color);
                        opacity: 1;
                        font-weight: 500;
                        &:before{
                            margin-left: 0;
                            opacity: 1;
                            right: 16px;
                        }
                    }
                }
            }
        }
    }
    .blog-right-tab{
        .blog-right-content{
            margin-bottom: 20px;
            p{
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
                
                margin-bottom: 12px;
                padding-bottom: 0;
            }
            h2{
                font-size: 32px;
                line-height: 40px;
                font-weight: 500;
                
                margin-bottom: 16px;
                padding-bottom: 0;
            }
            h3{
                font-size: 24px;
                line-height: 36px;
                font-weight: 500;
            }
            h5{
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;
                
                margin-bottom: 12px;
                padding-bottom: 0;
            }
            ul{
                li{
                    padding: 6px 0;
                }
            }
            figure{
                &.table{
                    padding: 24px;
                    background-color: var(--white-color);
                    border-radius: 16px;
                    width: 100%;
                    table{
                        width: 100%;
                        tr{
                            border: none;
                            td, th{
                                font-size: 16px;
                                padding: 16px;
                                color: var(--text-secondary);
                                border: none;
                                &:first-child{
                                    border-radius: 30px 0px 0px 30px;
                                }
                                &:last-child{
                                    border-radius: 0px 30px 30px 0px;
                                }
                            }
                        }
                        tbody{
                            tr{
                                &:nth-child(odd){
                                    background-color: var(--light-sky-background);
                                }
                            }
                        }
                        thead{
                            th{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .blog-text-box{
                margin-bottom: 24px;
            }
            .blog-image-box{
                width: 100%;
                height: 446px;
                border-radius: 16px;
                overflow: hidden;
                margin-bottom: 24px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .blog-right-listing{
                margin: 0;
                padding: 0;
                margin-bottom: 32px;
                li{
                    list-style: none;
                    margin-bottom: 12px;
                    font-size: 18px;
                    line-height: 26px;
                    
                    font-weight: 400;
                    position: relative;
                    padding-left: 20px;
                    &::before{
                        content: "";
                        width: 4px;
                        height: 4px;
                        
                        border-radius: 30px;
                        position: absolute;
                        left: 0;
                        top: 10px;
                    }
                }
            }
            .blog-detail-card{
                padding: 32px 32px 20px 32px;
                background-color: var(--card-background);
                border-radius: 16px;
                h6{
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                    
                    margin-bottom: 16px;
                    padding-bottom: 0;
                }
                p{
                    font-weight: 500;
                }
            }
            .blog-profile-card{
                padding: 32px 32px 20px 32px;
                background-color: var(--card-background);
                border-radius: 16px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 32px;
                position: relative;
                .profile-left-img{
                    width: 120px;
                    min-width: 120px;
                    height: 120px;
                    border-radius: 16px;
                    overflow: hidden;
                    margin-right: 24px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .profile-right-content{
                    h4{
                        font-size: 20px;
                        line-height: 26px;
                        font-weight: 500;
                        
                        margin-bottom: 4px;
                        padding-bottom: 0;
                    }
                    span{
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        
                        display: block;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}
.article-section{
    padding-top: 120px;
    padding-bottom: 120px;
    .section-heading{
        margin-bottom: 32px;
        h2{
            span{
                display: inline-block;
            }
        }
    }
    .container{
        overflow: hidden;
    }
        .article-slider{
            .article-card{
                transition: 0.4s;
                .article-header{
                    width: 100%;
                    // height: 275px;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 16px;
                    transition: 0.4s;
                    border-radius: 16px;
                    img{
                        width: 100%;
                        height: 100%;
                        transition: all .3s ease;
                    }
                    .article-card-chip{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transition: 0.4s;
                        z-index: 9;
                        p{
                            border-radius: 0px 8px 0px 16px;
                            background-color: var(--article-card-bg);
                            backdrop-filter: blur(10px);
                            display: inline-block;
                            padding: 11px 25px;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            color: #fff;
                            transition: all .3s ease;
                        }
                    }
                }
                .article-middle{
                    .blog-detail-list{
                        margin: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 12px;
                        li{
                            font-size: 16px;
                            line-height: 22px;
                            font-weight: 400;
                            
                            opacity: 0.75;
                            list-style: none;
                            padding: 0 15px;
                            position: relative;
                            
                            &:first-child{
                                padding-left: 0;
                                &::before{
                                    display: none;
                                }
                            }
                            &::before{
                                content: "";
                                width: 6px;
                                height: 6px;
                                border-radius: 30px;
                                
                                opacity: 0.75;
                                position: absolute;
                                left: -3px;
                                top: 10px;
                            }
                        }
                    }
                    h3{
                        font-size: 20px;
                        line-height: 26px;
                        font-weight: 500;
                        
                        margin-bottom: 12px;
                        padding: 0;
                        transition:all 0.3s;
                    }
                    p{
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 400;
                        
                        margin: 0;
                        padding: 0;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; 
                        white-space: pre-wrap;
                    }
                }
                
                &:hover{
                    .article-header{
                        img{
                            transform: scale(1.1);
                        }
                        .article-card-chip{
                            position: absolute;
                            left: 0;
                            p{
                                background-color: var(--article-chip-bg);
                            }
                        }
                    }
                    .article-middle{
                        h3{
                            color: var(--primary-color);
                        }
                    }
                }
            }
            .article-swiper-slider{
                overflow: unset;
            
            .swiper-arrow {
                position: absolute;
                right: 0;
                top: -70px;
                z-index: 5;
                .swiper-next,
                .swiper-prev {
                    width: 22px;
                    height: 16px;
                    display: inline-block;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                    vertical-align: top;
                }
                .swiper-prev {
                    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2307252F'/%3E%3C/svg%3E");
                    &:hover {
                        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2322A5D9'/%3E%3C/svg%3E");
                    }
                }
                .swiper-next {
                    margin-left: 10px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2307252F %7D .s1 %7B fill: %2307252F %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2322a5d9 %7D .s1 %7B fill: %2307252f %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
                    }
                }
            }
            
        }
    }
}

@media(max-width: 1200px){
    .blog-detail-section .blog-detail-heading h2{
        padding-right: 20px;
        font-size: 40px;
    }
    
}
@media(max-width: 1024px){
    .blog-detail-section .blog-detail-banner{
        height: auto;
    }
}
@media(max-width: 991px){
    
    .blog-detail-section .blog-detail-banner{
        margin-top: -85px;
    }
    .blog-detail-section .blog-detail-inner{
        height: 100%;
        padding: 32px 0 85px;
    }
    .blog-detail-section .blog-detail-heading .social-icon-list li a{
        width: 34px;
        height: 34px;
    }
    .blog-list-section .blog-right-tab .blog-right-content h2{
        font-size: 26px;
        line-height: 34px;
    }
    .blog-list-section .blog-right-tab .blog-right-content h5{
        font-size: 20px;
        line-height: 28px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-image-box{
        height: 100%;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-detail-card{
        padding: 24px 24px 16px 24px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-detail-card h6{
        font-size: 20px;
        line-height: 26px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-profile-card .profile-left-img{
        margin-right: 10px;
        width: 80px;
        height: 80px;
        min-width: 80px;
    }
    .blog-list-section{
        .blog-right-tab{
            .blog-right-content{
                figure{
                    &.table{
                        padding: 12px 14px;
                        table{
                            tr{
                                border: none;
                                td, th{
                                    font-size: 14px;
                                    padding: 12px;
                                }
                            }
                            thead{
                                th{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .article-section .article-slider .article-card .article-middle h3{
        font-size: 18px;
        line-height: 26px;
    }
    .blog-detail-section .blog-detail-banner{
        height:auto;
    }
}

@media(max-width: 767px){
    .blog-detail-section .blog-detail-inner{
        padding: 30px 0 100px;
    }
    .blog-detail-section .blog-detail-heading h2{
        font-size: 36px;
        line-height: 44px;
    }
    .blog-detail-section .blog-detail-heading{
        display: block;
    }
    .blog-detail-section .blog-detail-heading{
        margin-bottom: 0;
    }
    .blog-detail-section .blog-detail-heading .social-icon-list{
        margin-top: 16px;
        justify-content: flex-start;
        padding-left: 0px;
        padding-bottom: 15px;
    }

    .blog-list-section .blog-left-tab{
        border-bottom: 1px solid var(--tab-border-color);
        margin-bottom: 30px;
        padding-bottom: 10px;
    }
    .blog-list-section .blog-left-tab ul li a{
        padding: 8px 40px 8px 16px;
    }
    .blog-list-section .blog-right-tab .blog-right-content p{
        font-size: 16px;
        line-height: 22px;
    }
    .blog-list-section .blog-right-tab .blog-right-content h2{
        font-size: 20px;
        line-height: 26px;
    }
    .blog-list-section .blog-right-tab .blog-right-content h3{
        font-size: 16px;
        line-height: 20px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-right-listing li{
        font-size: 16px;
        line-height: 22px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-profile-card{
        display: block;
        padding: 16px 16px 10px 16px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-profile-card .profile-right-content h4{
        font-size: 18px;
        margin-top: 16px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-profile-card .profile-right-content span{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    .article-section{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .blog-detail-section .blog-detail-list li{
        font-size: 16px;
        line-height: 20px;
    }
    .blog-detail-section .blog-detail-heading h2{
        padding-right: 0;
    }
    .blog-list-section .blog-profile-card .profile-user-name{
        position: absolute;
        top: 11px;
        left: 113px;
    }
    .blog-list-section .blog-right-tab .blog-right-content .blog-profile-card .profile-left-img{
        margin-bottom: 15px;
    }
}
@media(max-width: 575px){
    .blog-detail-section .blog-detail-inner{
        padding: 30px 0 85px;
    }
    .blog-detail-section .blog-detail-banner {
        margin-top: -65px;
    }
}
/**********/
@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
    .blog-detail-section .blog-detail-banner{
        height:auto;
        img{
            object-fit: cover;
            object-position: center center;
        }
    }
}