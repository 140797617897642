.casestudies-stories-section
  .casestudies-stories-list
  .casestudies-stories-card
  .casestudies-content
  .casestudies-details
  p {
  margin-bottom: 10px;
  padding-bottom: 0;
}
.footer-section .footer-social-link ul li {
  cursor: pointer;
  &:hover img {
    transform: scale(1.2);
  }
  img {
    transition: all 0.5s;
  }
}
.testimonials-slider .testimonials-card .testimonials-content {
  padding-left: 104px;
}
.our-services-section .our-services-title .services-title-right a,
.case-studies-section .case-studies-title .section-heading-right a {
  font-weight: 500;
}
.footer-section .footer-bottom .footer-bottom-right li:last-child {
  a {
    margin-right: 0;
  }
}
.swiper-arrow {
  cursor: pointer;
}

.services-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button.collapsed
  + .accordion-read-more,
.services-accordion
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button.collapsed
  .accordion-read-more {
  opacity: 0;
}
.contact-page-middle .case-studies-section {
  padding: 120px 0;
}

.terms-policy-section .terms-policy-content h2 {
  margin-top: 0;
}
.terms-policy-section .terms-policy-content p + h2,
.terms-policy-section .terms-policy-content ul + h2 {
  margin-top: 30px;
}
.footer-section .footer-col-link ul li a {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0px;
    height: 2px;
    background-color: var(--primary-color);
    transition: all 0.5s;
  }
  &:hover {
    &::after {
      width: 20px;
    }
  }
}
.open-job-positions .job-apply .job-apply-btn {
  box-shadow: none;
  border: none;
  outline: none;
}
.testimonials-slider {
  overflow: visible !important;
}

.blog-page-middle
  .article-section
  .article-inner
  .latest-article-card
  .latest-article-content
  p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blog-page-middle
  .article-section
  .article-inner
  .latest-article-all-card
  .latest-article-card {
  align-items: flex-start;
}
.blog-page-middle
  .article-section
  .article-inner
  .latest-article-all-card
  .latest-article-card
  .latest-article-img {
  width: 275px;
  flex-shrink: 0;
  height: 220px;
  img {
    object-fit: cover;
    object-position: center center;
  }
}
.blog-page-middle .latest-article-card .latest-article-img {
  height: 280px;
  img {
    object-fit: cover;
    object-position: center center;
  }
}
.all-article-section
  .all-article-inner
  .all-article-tab-content
  .articles-box
  .latest-article-card
  .latest-article-content
  p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.jvectormap-container {
  background-color: transparent !important;
}
p.help.is-danger {
  color: red;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 3px;
}
.section-heading h2 p {
  font-weight: 700;
  
  font-size: 54px;
  line-height: 64px;
}
.header
  .navbar-nav
  .submenu
  .submenu-inner
  .submenu-right-inner
  .submenu-btn-grup
  .submenu-btn
  .btn-secondary:hover
  p {
  color: #fff;
}
.services-banner-right .services-banner-img img,
.services-banner-thumbnail .banner-thumbnail-img img {
  border-radius: 16px;
}

// .testimonials-slider .testimonials-card .testimonials-content::before {
// 	height: calc(100vh - 215px);
// }
@media (max-width: 1366px) {
  .contact-page-middle .case-studies-section {
    padding: 80px 0;
  }
}
@media (max-width: 1200px) {
  .testimonials-slider .testimonials-card .testimonials-content {
    padding-left: 60px;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-img {
    width: 220px;
    flex-shrink: 0;
    height: 187px;
  }
  .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-content
    h3 {
    font-size: 18px;
    line-height: 28px;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-card
    .latest-article-content
    p {
    font-size: 16px;
    line-height: 24px;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card {
    margin-bottom: 20px;
  }
  .section-heading h2 p {
    font-size: 44px;
    line-height: 54px;
  }
}
@media (max-width: 991px) {
  .footer-section .footer-top .footer-logo {
    max-width: 136px;
  }
  .contact-page-middle .case-studies-section {
    padding: 60px 0;
  }

  .blog-page-middle .latest-article-all-card {
    grid-template-columns: 1fr 1fr;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-img {
    width: 100%;
  }
  .section-heading h2 p {
    font-size: 36px;
    line-height: 43px;
  }
}
@media (max-width: 767px) {
  .footer-section .footer-col-link ul li {
    padding-bottom: 12px;
  }
  .testimonials-slider .testimonials-card .testimonials-content {
    padding-left: 52px;
  }
  .terms-policy-section .terms-policy-content p + h2,
  .terms-policy-section .terms-policy-content ul + h2 {
    margin-top: 10px;
  }
  .casestudies-card .casestudies-right .casestudies-button a {
    width: 50px;
    height: 45px;
  }
  .casestudies-card .casestudies-right .casestudies-button a img {
    width: 20px;
  }
  .hero-banner-section {
    padding: 20px 0 60px;
    overflow: hidden;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-img {
    width: 220px;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-content {
    padding-top: 0;
  }
  .section-heading h2 p {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 575px) {
  .footer-section .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    p {
      padding-bottom: 0;
    }
    .footer-bottom-right {
      margin-bottom: 10px;
    }
  }
  .hero-banner-section .banner-heading-right {
    top: 0;
  }
  .blog-page-middle .latest-article-card .latest-article-img {
    height: 220px;
  }
}
@media (max-width: 480px) {
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-img {
    width: 100%;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card
    .latest-article-content {
    padding-top: 16px;
  }
  .blog-page-middle
    .article-section
    .article-inner
    .latest-article-all-card
    .latest-article-card {
    margin-bottom: 30px;
  }
  .blog-page-middle .latest-article-card .latest-article-img {
    height: 187px;
  }
}
@media (max-width: 369px) {
  .hero-banner-section
    .banner-heading-right
    .banner-video
    .banner-video-thumbnail {
    width: 50px;
    height: 50px;
  }
  .hero-banner-section .banner-heading-left .main-heading h1 {
    font-size: 32px;
    line-height: 38px;
  }
}

/*************/
@media (min-width: 992px) {
  .service-tabs-content .service-heading {
    max-width: 390px;
  }
}
