// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");
/***loader****/
.loader-div {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.loader-circle div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: loader-circle 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader-circle div:nth-child(1) {
  border-color: rgb(7 37 47 / 50%);
  animation-delay: 0s;
}
.loader-circle div:nth-child(2) {
  border-color: #22a5d9;
  animation-delay: -0.5s;
}
.loader-wrapper {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loader-circle {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
@keyframes loader-circle {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
/* loader Css end */

html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--body-background);
  font-size: 16px;
  line-height: 24px;
  
  margin: 0 auto;
  padding: 0;
  height: 100%;
  width: 100%;
  font-style: normal;
  font-family: var(--dm-sans);
  font-weight: 400;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
p {
  font-size: 18px;
  line-height: 28px;
  color: var(--secondary-color);
  font-family: var(--dm-sans);
  font-style: normal;
  font-weight: 400;
  padding-bottom: 16px;
  margin-bottom: 0;
  &:last-child {
    padding-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--secondary-color);
  font-family: var(--dm-sans);
  font-weight: 700;
  font-style: normal;
  padding-bottom: 12px;
  margin-bottom: 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  &:hover {
    color: var(--secondary-color);
  }
}
img {
  max-width: 100%;
  height: auto;
}

.main-wrapper {
  min-height: calc(100vh - 715px);
  padding-top: 100px;
}
.modal-backdrop.show {
  opacity: 0.8;
  background: #000;
}
.wrapper-top-bg {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: var(--light-sky-background);
  }
}
.input-error-msg {
  color: red;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 3px;
}
/***heading***/

.heading-h1 {
  font-size: 64px;
  line-height: 76px;
}
.heading-h2 {
  font-size: 54px;
  line-height: 64px;
}
.heading-h3 {
  font-size: 32px;
  line-height: 42px;
}
.heading-h4 {
  font-size: 24px;
  line-height: 32px;
}
.heading-h5 {
  font-size: 20px;
  line-height: 30px;
}

/***end heading***/

.pre-font-lg {
  font-size: 20px;
  line-height: 30px;
}

.pre-font-md {
  font-size: 18px;
  line-height: 27px;
}

.link-font-lg {
  font-size: 18px;
  line-height: 27px;
}

.btn {
  padding: 14px 30px;
  font-size: 16px;
  font-family: var(--dm-sans);
  font-weight: 500;
  line-height: normal;
}

.btn.btn-primary {
  color: var(--text-white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all 0.8s !important;
  border-radius: 8px;
  // &:active {
  //     color: #fff;
  //     background-color:  var(--primary-color);
  //     border-color:  var(--primary-color);
  // }
  // &:hover,
  // &:focus {
  //     box-shadow: 0 3px 15px 0 var(--shadow-color);
  //     transform: translateY(-3px);
  //     box-shadow: -5px 5px var(--btn-hover-shadow);
  // }
}
.btn.btn-secondary {
  color: var(--btn-secondary-color);
  background-color: var(--btn-secondary-background);
  border-color: var(--btn-secondary-background);
  transition: all 0.8s !important;
  border-radius: 8px;
  // &:active {
  //     color: var(--btn-secondary-color);
  //     background-color: var(--btn-secondary-background);
  //     border-color: var(--btn-secondary-background);
  // }
  // &:hover,
  // &:focus {
  //     box-shadow: -5px 5px var(--btn-hover-shadow);
  //     transform: translateY(-3px);
  // }
}
.btn.btn-outline-primary {
  
  border-color: var(--border-color-primary);
  transition: all 0.8s !important;
}
/***btn-hover****/
.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-outline-primary {
  outline: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-outline-primary {
  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    border-top: 1.5px solid var(--border-color-white);
    border-left: 1.5px solid var(--border-color-white);
    width: 0px;
    height: 0px;
    opacity: 0;
    transition: 0.3s;
    border-top-left-radius: 5px;
    // transform: translate(-50px, -50px);
  }
  &::after {
    content: "";
    position: absolute;
    right: 5px;
    bottom: 5px;
    border-bottom: 1.5px solid var(--border-color-white);
    border-right: 2px solid var(--border-color-white);
    width: 0px;
    height: 0px;
    opacity: 0;
    transition: 0.3s;
    border-bottom-right-radius: 5px;
    // transform: translate(50px, 50px);
  }
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 4px 13px var(--btn-box-shadow);
    transform: translateY(-0px);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--text-white);
    &::after,
    &::before {
      transform: translate(0px);
      opacity: 1;
      height: 30px;
      width: 30px;
    }
  }
}

/***/
/**btn-hover-9**/
.btn-hover-9.btn {
  position: relative;
  overflow: hidden;
  transform: translateY(0);
  border: 0;
  box-shadow: none;
  &:hover {
    box-shadow: 0 4px 13px var(--btn-box-shadow);
    color: var(--text-white);
  }
  &:hover::after {
    transform: translateY(-45%) skew(25deg) scaleX(1);
  }
  &::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 150px;
    background-color: var(--secondary-color);
    transform: translateY(-45%) skew(25deg) scaleX(0);
    top: 50%;
    left: -5%;
    -webkit-transition: 280ms ease-in-out;
    -khtml-transition: 280ms ease-in-out;
    -moz-transition: 280ms ease-in-out;
    -ms-transition: 280ms ease-in-out;
    -o-transition: 280ms ease-in-out;
    transition: 280ms ease-in-out;
    z-index: -1;
  }
}

/***/

/*******/

.text-sky {
  color: var(--primary-color);
}

.primary-background {
  background-color: var(--primary-color);
}
.light-primary-background {
  background-color: var(--light-sky-background);
}

.heading {
  h1 {
    
    font-weight: 700;
    font-size: 64px;
    line-height: 76.8px;
    span {
      
    }
  }
  p {
    
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
}

.section-heading {
  h2 {
    font-weight: 700;
    
    font-size: 54px;
    line-height: 64px;
    span {
      display: inline-block;
      
    }
  }
  p {
    font-size: 18px;
    line-height: 27px;
    color: var(--secondary-color);
    font-weight: 400;
    padding-bottom: 12px;
  }
}

.swiper-arrow {
  .swiper-next,
  .swiper-prev {
    width: 22px;
    height: 16px;
    display: inline-block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: top;
  }
  .swiper-prev {
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2307252F'/%3E%3C/svg%3E");
    &:hover {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'%3E%3Cpath d='M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7L21 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z' fill='%2322A5D9'/%3E%3C/svg%3E");
    }
  }
  .swiper-next {
    margin-left: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2307252F %7D .s1 %7B fill: %2307252F %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.2' viewBox='0 0 22 16' width='22' height='16'%3E%3Ctitle%3EGroup 1116603004-svg%3C/title%3E%3Cstyle%3E .s0 %7B fill: %2322a5d9 %7D .s1 %7B fill: %2307252f %7D %3C/style%3E%3Cpath id='Layer' class='s0' d='m1 7c-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1zm20.7 1.7c0.4-0.4 0.4-1 0-1.4l-6.4-6.4c-0.3-0.4-1-0.4-1.4 0-0.4 0.4-0.4 1.1 0 1.4l5.7 5.7-5.7 5.7c-0.4 0.3-0.4 1 0 1.4 0.4 0.4 1.1 0.4 1.4 0zm-20.7 0.3h20v-2h-20z'/%3E%3Cpath id='Layer' class='s1' d='m-19 9c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1zm-20.7-1.7c-0.4 0.4-0.4 1 0 1.4l6.4 6.4c0.3 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1.1 0-1.4l-5.7-5.7 5.7-5.7c0.4-0.3 0.4-1 0-1.4-0.4-0.4-1.1-0.4-1.4 0zm20.7-0.3h-20v2h20z'/%3E%3C/svg%3E");
    }
  }
}

// on hover border aniamtion
.border-expand {
  display: inline;
  background-image: var(--footerlink-hover-border);
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.5s;
  background-position: 0 100%;
  &:hover {
    background-size: 100% 1px;
  }
}

// Custom input css start
.input-wrapper {
  .form-floating {
    .form-control {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      
      border: 1px solid var(--border-color-secondary-lt);
      border-radius: 8px;
      padding: 15px 16px;
      background-color: var(--secondary-background-lt);
      box-shadow: none;
      height: auto;
      min-height: inherit;

      &:not(:placeholder-shown) ~ label {
        transform: none;
        top: -12px;
        left: 16px;
        font-size: 12px;
        line-height: 18px;
        background-color: var(--secondary-background-lt) !important;
        padding: 2px;
        height: auto;
      }
      &:hover {
        border-color: var(--border-color-secondary);
      }
      &:focus {
        border-color: var(--border-color-secondary);
        & + .form-label {
          transform: none;
          top: -12px;
          left: 16px;
          font-size: 12px;
          line-height: 18px;
          padding: 2px;
          background-color: var(--secondary-background-lt) !important;
          height: auto;
        }
      }
    }

    .form-label {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: var(--text-secondary-lt);
      margin-bottom: 0;
      padding: 17px 16px;
      border: 0;
      transition: all 0.1s linear;
      background-color: transparent !important;
      top: 2px;
    }
    .textarea-floating {
      min-height: 112px;
    }
  }
}

.file-input {
  display: flex;

  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;

    & + label {
      width: 100%;
      background: var(--file-input-background);
      border: 1px dashed var(--border-color-secondary-lt);
      border-radius: 8px;
      cursor: pointer;
      display: block;
      position: relative;
      transition: all 0.3s;
      padding: 14px 16px;

      .input-title {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        margin-bottom: 11px;
        
        display: block;
      }

      .upload-container {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 12px;

          img {
            width: 48px;
          }
        }

        .text {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: var(--text-secondary-lt);
        }
      }
    }
  }
}
.custom-range-wrapper {
  padding: 14px 16px;
  border: 1px solid var(--border-color-secondary-lt);
  border-radius: 8px;
  .range-title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    
    display: inline-block;
    padding-bottom: 15px;
  }
  .custom-range-input {
    margin-bottom: 15px;
    .irs--big {
      height: 46px;
      margin-top: -20px;
    }
    .irs {
      .irs-min {
        display: none;
      }
      .irs-max {
        display: none;
      }
      .irs-single {
        display: none;
      }
      .irs-from {
        display: none;
      }
      .irs-to {
        display: none;
      }
      .irs-handle {
        width: 20px;
        height: 20px;
        top: 24px;
        background: var(--white-background-color);
        border: 5px solid var(--border-color-primary);
      }
      .irs-line {
        height: 3px;
        border: 0;
        background: var(--range-input-line);
      }
      .irs-bar {
        height: 3px;
        border: 0;
        background: var(--primary-background-color);
      }
    }
    .range-slider {
      height: 6px;
      width: 100%;
      background-color: var(--range-input-line);
    }
    .range-slider .range-slider__range {
      background: #22a5d9;
    }
    .range-slider .range-slider__thumb {
      background: #fff;
      width: 20px;
      height: 20px;
      border: 5px solid #22a5d9;
    }
    .custom-range-input {
      margin-bottom: 10px;
    }
  }
  .range-input-value {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: var(--text-secondary-lt);
      .value {
        
      }
    }
  }
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  opacity: 0;
}

.video-modal .modal-dialog {
  max-width: 780px;
  height: 440px;
}

.specialities-title .heading-h3 ul h3 {
  font-size: 20px !important;
}
// custom input css end

/******responsive*******/
@media (max-width: 1200px) {
  .main-wrapper {
    min-height: calc(100vh - 741px);
  }
  .heading-h1 {
    font-size: 54px;
    line-height: 66px;
  }
  .heading-h2 {
    font-size: 44px;
    line-height: 54px;
  }
  .heading-h3 {
    font-size: 28px;
    line-height: 38px;
  }
  .pre-20 {
    font-size: 18px;
    line-height: 28px;
  }
  .heading-h5 {
    font-size: 18px;
    line-height: 28px;
  }
  .heading {
    h1 {
      font-size: 54px;
      line-height: 66px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .section-heading h2 {
    font-size: 44px;
    line-height: 54px;
  }
}

@media (max-width: 991px) {
  .main-wrapper {
    min-height: calc(100vh - 990px);
    padding-top: 79px;
  }
  .wrapper-top-bg::after {
    height: 79px;
  }
  .wrapper-pt-0 {
    padding-top: 0;
  }
  .heading-h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .heading-h2 {
    font-size: 36px;
    line-height: 44px;
  }
  .heading-h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .heading-h4 {
    font-size: 22px;
    line-height: 28px;
  }
  .heading-h5 {
    font-size: 18px;
    line-height: 28px;
  }
  .pre-font-lg {
    font-size: 18px;
    line-height: 28px;
  }
  .pre-font-md {
    font-size: 16px;
    line-height: 24px;
  }
  .link-font-lg {
    font-size: 16px;
    line-height: 24px;
  }
  .heading {
    h1 {
      font-size: 40px;
      line-height: 48px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .section-heading {
    h2 {
      font-size: 36px;
      line-height: 43px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .input-wrapper .form-floating {
    .form-control,
    .form-label {
      padding: 12px 14px;
    }
    .textarea-floating {
      min-height: 80px;
    }
  }
  .custom-range-wrapper {
    padding: 12px 16px;
  }
  .file-input [type="file"] + label {
    padding: 12px 16px;
  }
}
@media (max-width: 767px) {
  .main-wrapper {
    padding-top: 72px;
    min-height: inherit;
  }
  .wrapper-top-bg::after {
    height: 72px;
  }
  .wrapper-pt-0 {
    padding-top: 0;
  }
  .heading-h1 {
    font-size: 36px;
    line-height: 43px;
  }
  .heading-h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .heading-h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .heading-h5 {
    font-size: 16px;
    line-height: 26px;
  }
  .pre-20 {
    font-size: 16px;
    line-height: 26px;
  }
  .heading-h3 {
    font-size: 22px;
    line-height: 32px;
  }
  .btn {
    padding: 14px 20px;
    font-size: 16px;
    line-height: normal;
  }
  .heading {
    h1 {
      font-size: 36px;
      line-height: 43px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .section-heading h2 {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 575px) {
  .heading-h1 {
    font-size: 36px;
    line-height: 43px;
  }
  .heading-h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .heading-h5 {
    font-size: 16px;
    line-height: 26px;
  }
  .pre-20 {
    font-size: 16px;
    line-height: 26px;
  }
  .heading-h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .btn {
    padding: 14px 20px;
    font-size: 14px;
    line-height: normal;
  }
  .heading {
    h1 {
      font-size: 36px;
      line-height: 43px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .section-heading h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .input-wrapper {
    .form-floating {
      .form-control {
        height: 48px;
        min-height: 48px;
        padding: 12px 16px;
      }
      .form-label {
        padding: 12px 16px;
      }
    }
  }
}
/****/
@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
  .heading-h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .heading-h2 {
    font-size: 36px;
    line-height: 44px;
  }
  .heading-h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .heading-h4 {
    font-size: 22px;
    line-height: 28px;
  }
  .heading-h5 {
    font-size: 18px;
    line-height: 28px;
  }
  .heading {
    h1 {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .section-heading h2 {
    font-size: 36px;
    line-height: 43px;
  }
}
