/*****about-hero-section*****/
.about-hero-section {
    padding: 40px 0;
    position: relative;
    .about-heading {
        padding: 0px 0 20px 58px;
        position: relative;
        z-index: 1;
        h1,h2{
            span{
                color: var(--primary-color);
            }
        }
    }
    .about-hero-content {
        position: relative;
        padding-top: 60px;
        &::after {
            content: "";
            width: 278px;
            height: 60%;
            background-color: var(--about-hero-background);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 16px;
        }
    }
    .about-heading-content {
        position: relative;
        z-index: 2;
        padding-left: 300px;
        padding-bottom: 32px;
        h2 {
            
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
        }
    }
    .about-hero-img {
        position: relative;
        z-index: 2;
        .about-hero-row{
            justify-content: flex-end;
            .work-image-1 {
                max-width: 761px;
                height: 442px;
                border-radius: 16px;
                overflow: hidden;
                margin-left: auto;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    transform: scale(1);
                    transition: all .4s linear;
                }
                &:hover{
                   img{
                    transform: scale(1.03);
                    transition: all .4s linear;
                   }
                }
            }
            .work-image-2 {
                height: 442px;
                border-radius: 16px;
                overflow: hidden;
                max-width: 306px;
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                    object-position: center center;
                    transform: scale(1);
                    transition: all .4s linear;
                }
                &:hover{
                  img{
                    transform: scale(1.03);
                    transition: all .4s linear;
                  }
                }
            }
        }
    }
}
/****end about-hero-section*****/

/****about-developer-section***/
.about-developer-section {
    padding: 40px 0 120px;
    .about-developer-title {
        position: sticky;
        top: 150px;
        h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            color: var(--primary-color);
        }
    }
    .about-developer-content {
        padding-bottom: 80px;
        .about-short-description {
            padding-bottom: 12px;
            p {
                
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .about-full-description {
            p {
                
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
            }
        }
    }
    .about-vision-mission {
        .about-icon-card {
            max-width: 370px;
        }
        .about-icon-image {
            padding-bottom: 24px;
            img {
                max-width: 53px;
            }
        }
        .about-card-content {
            h2 {
                
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
            p {
                color: rgb(7 37 47 / 80%);
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
            }
        }
    }
}
/****end about-developer-section***/
/****about-quality-section***/

.about-quality-section {
    padding: 120px 0;
    .about-quality-heading {
        padding-bottom: 20px;
        h2{
            span{
                color: var(--primary-color);
            }
        }
    }
    .about-quality-faq {
        .accordion-item {
            background-color: transparent;
            padding: 13px 0;
            border-bottom: 1px solid var(--border-color-secondary-ltr);
            &:first-child {
                border-top: 1px solid var(--border-color-secondary-ltr);
            }
            .accordion-header {
                padding: 0;
                .accordion-button {
                    background: transparent;
                    padding: 11px 0;
                    
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border: none;
                    box-shadow: none;
                    &:focus,
                    &:active {
                        box-shadow: none;
                        border: none;
                    }
                    &::after {
                        display: none;
                    }

                    p{
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
            .accordion-body {
                padding: 0 0 11px;
                max-width: 520px;
            }
        }
    }
    .about-quality-img {
        text-align: right;
        position: sticky;
        top: 150px;
        .quality-img-box {
            position: relative;
            display: inline-block;
            padding: 0 30px 60px 0;
            .quality-img-1 {
                max-width: 453px;
                img {
                    border-radius: 16px;
                }
            }
            .quality-img-2 {
                width: 250px;
                height: 180px;
                border-radius: 16px;
                overflow: hidden;
                border: 5px solid #e1f2f9;
                position: absolute;
                bottom: 0;
                right: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
    }
}
/****end about-quality-section***/
.about-page-middle .cta-section {
    padding: 120px 0;
}

/******responsive*******/
@media (max-width: 1400px) {
    /*****about-hero-section*****/
    .about-hero-section .about-hero-img .about-hero-row {
        .work-image-1 {
           max-width: 648px;
            height: 376px;
        }
        .work-image-2 {
            max-width: 261px;
            height: 376px;
           
        }
    }
    /*****end about-hero-section*****/
}
@media (max-width: 1366px) {
    /***about-developer-section***/
    .about-developer-section {
        padding: 40px 0 80px;
    }
    /*****end about-developer-section*****/
    /*****about-quality-section*****/
    .about-quality-section {
        padding: 80px 0;
    }
    /*****end about-quality-section*****/
    .about-page-middle .cta-section {
        padding: 80px 0;
    }
}
@media (max-width: 1200px) {
    /****about-hero-section****/
    .about-hero-section {
        .about-heading {
            padding: 0px 0 20px 95px;
        }
        .about-heading-content {
            h2 {
                font-size: 28px;
                line-height: 38px;
            }
        }
        .about-hero-img .about-hero-row {
            .work-image-1 {
                max-width: 525px;
                height: 312px;
            }
            .work-image-2 {
                max-width: 261px;
                height: 312px;
                
            }
        }
    }
    /*****end about-hero-section*****/
    /****about-developer-section****/
    .about-developer-section {
        .about-developer-title {
            h2 {
                font-size: 28px;
                line-height: 38px;
            }
        }
        .about-vision-mission {
            .about-card-content {
                h2 {
                    font-size: 28px;
                    line-height: 38px;
                }
            }
        }
    }

    /****end about-developer-section****/
}

@media (max-width: 991px) {
    /***about-hero-section***/
    .about-hero-section {
        padding: 40px 0 30px;
        .about-heading {
            padding: 0px 0 10px 30px;
        }
        .about-hero-content {
            padding-top: 30px;
        }
        .about-hero-img .about-hero-row{
            .work-image-1 {
                height: 225px;
               max-width: 390px;
            }
            .work-image-2 {
                height: 225px;
                max-width: 156px;
            }
        }
        .about-hero-content::after {
            width: 165px;
            height: 80%;
        }
        .about-heading-content {
            padding-left: 180px;
            padding-bottom: 26px;
            h2 {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }

    /***end about-hero-section***/
    /***about-developer-section***/
    .about-developer-section {
        padding: 30px 0 60px;
        .about-developer-title h2 {
            font-size: 24px;
            line-height: 34px;
        }
        .about-developer-content {
            padding-bottom: 60px;
            .about-short-description p {
                font-size: 18px;
                line-height: 24px;
            }
            .about-full-description p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .about-vision-mission .about-card-content h2 {
            font-size: 24px;
            line-height: 34px;
        }
    }

    /***end about-developer-section***/

    /***about-quality-section***/
    .about-quality-section {
        padding: 60px 0;
        .about-quality-faq {
            padding-bottom: 0px;
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
            }
        }
        .about-quality-img {
            padding-left: 20px;
            .quality-img-box .quality-img-2 {
                width: 200px;
                height: 140px;
            }
        }
    }
    /***end about-quality-section***/
    .about-page-middle .cta-section {
        padding: 60px 0;
    }
}

@media (max-width: 767px) {
    .about-hero-section .about-hero-img .about-hero-row {
        .work-image-1 {
            height: 156px;
            max-width: 270px;
        }
        .work-image-2 {
            height: 156px;
            max-width: 110px;
        }
    }
    /***about-quality-section***/
    .about-quality-section {
        .about-quality-faq {
            padding-bottom: 40px;
        }
        .about-quality-img {
            padding-left: 0px;
            text-align: center;
            .quality-img-box .quality-img-1 {
                max-width: 320px;
            }
        }
    }
    /***end about-quality-section ***/
    /***about-developer-section***/
    .about-developer-section {
        .about-developer-content {
            padding-bottom: 30px;
        }
        .about-vision-mission .about-card-content h2 {
            font-size: 22px;
            line-height: 30px;
        }
    }
    /***end about-developer-section***/
}

@media (max-width: 575px) {
    /***about-hero-section***/
    .about-hero-section {
        background-color: var(--light-sky-background);
        padding: 60px 0;
        .about-hero-content {
            padding-top: 0px;
        }
        .about-hero-content::after {
            display: none;
        }
        .about-heading {
            padding: 0px 0 10px 0px;
        }
        .about-heading-content {
            padding-left: 0px;
        }
        .about-hero-img {
             .about-hero-row {
                margin: 0 -5px;
                & > * {
                    padding: 0 5px;
                }
                .work-image-1 {
                    padding-left: 0;
                    height: 180px;
                    border-radius: 10px;
                    max-width: 100%;
                }
                .work-image-2 {
                    padding-left: 0;
                    height: 180px;
                    border-radius: 10px;
                    max-width: 100%;

                }
            }
        }
    }
    /***end about-hero-section***/
    /***about-developer-section***/
    .about-developer-section {
        padding: 60px 0 30px;
        .about-developer-content {
            padding-bottom: 30px;
        }
        .about-vision-mission .about-icon-card {
            padding-bottom: 30px;
            max-width:inherit;
        }
    }
 
    /***end about-developer-section***/
    /***about-quality-section***/
    .about-quality-section {
        .about-quality-faq {
            .accordion-item {
                padding: 5px 0;
                .accordion-header .accordion-button {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
    /***end about-quality-section***/
}

@media (max-width: 416px) {
    /***about-hero-section***/
    .about-hero-section .about-hero-img .about-hero-row {
        .work-image-1 ,
        .work-image-2 {
            height: 150px;
        }
    }
    /***end about-hero-section***/
}

@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
    /***about-hero-section***/
    .about-hero-section {
       .about-heading {
            padding: 0px 0 20px 95px;
        }
        .about-hero-content {
            padding-top: 40px;
            &::after {
                width: 230px;
            }
        }
        .about-hero-img .about-hero-row {
            .work-image-2 ,
            .work-image-1 {
                height: 325px;
            }
        }
    }
    /***end about-hero-section***/
    /***about-quality-section***/
    .about-quality-section {
        .about-quality-img {
            .quality-img-box {
                .quality-img-1 {
                    max-width: 400px;
                }
            }
        }
    }
    /***end about-quality-section***/
    /***about-developer-section***/
    .about-developer-section .about-developer-content {
        padding-bottom: 40px;
    }
    /***end about-developer-section***/
}
