.proposal-section {
  padding: 32px 0 120px;
  overflow: hidden;
  .request-proposal-heading {
    margin-bottom: 41px;
  }
  .proposal-form-wrapper {
    .input-wrapper {
      margin-bottom: 24px;
    }
    .btn-primary {
      margin-top: 8px;
    }
  }
  .proposal-banner-img {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 60px;
      left: 60px;
      width: 100vw;
      height: 100%;
      background-color: rgba($color: #22a5d9, $alpha: 0.1);
      border-radius: 0 0 0 16px;
      z-index: -1;
    }
  }
}
@media (max-width: 1366px) {
  .proposal-section {
    padding: 32px 0 80px;
  }
}
@media (max-width: 991px) {
  .proposal-section {
    .proposal-form-wrapper {
      .custom-row {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
    .proposal-banner-img {
      margin-bottom: 70px;
      &::before {
        top: 30px;
        left: 30px;
        width: 100%;
        border-radius: 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  .proposal-section {
    padding: 32px 0 60px;
  }
  .request-proposal-wrapper {
    .case-studies-section {
      padding: 60px 0;
    }
  }
}
.captcha-error{
    color: #ff0000;
    position: relative;
    top: -10px;
}

.modal-captcha-error{
    padding:0 20px;
    color: #ff0000;
    position: relative;
    top: 10px;
}
