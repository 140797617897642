/***agile-banner-section***/
.agile-banner-section {
  padding: 60px 0 60px;
  position: relative;
  overflow: hidden;

  .agile-banner-heading {
    padding-bottom: 20px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100vw;
      height: calc(100% + 60px);
      background-color: var(--card-background);
      top: -60px;
      left: 50%;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100vw;
      height: calc(100% + 60px);
      background-color: var(--card-background);
      top: -60px;
      right: 50%;
      z-index: -1;
    }
    .agile-main-heading {
      h1 {
        font-size: 64px;
        line-height: 76px;
        
        span {
          color: var(--primary-color);
          display: block;
        }
      }
    }
    .agile-banner-subheading {
      
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }
  .agile-banner-description {
    padding-top: 32px;
    p {
      
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }
  .content-center {
    text-align: center;
  }
  .agile-banner-right {
    position: relative;
    padding-right: 30px;
    padding-bottom: 30px;
    display: inline-block;
    max-width: 482px;
    .agile-banner-image {
      border-radius: 16px;
      overflow: hidden;
    }
    .agile-banner-link {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      .card-link {
        max-width: 321px;
        max-height: 241px;
      }
    }
  }
}
/*****End-agile-banner-section***/
/***our-offerings-section***/
.our-offerings-section {
  padding: 60px 0;
  overflow: hidden;
  .section-heading {
    padding-bottom: 24px;
    h2 {
      span {
        display: inline-block;
      }
    }
  }
  .offering-slider {
    .swiper-offering {
      position: relative;
      overflow: visible;
      -webkit-clip-path: inset(-100vw -100vw -100vw 0);
      clip-path: inset(-100vw -100vw -100vw 0);
    }
    .swiper-arrow {
      position: absolute;
      right: 0;
      top: -50px;
      z-index: 2;
    }
    .swiper-slide {
      height: auto;
      .offering-card {
        height: 100%;
      }
    }
  }
}
.offering-card {
  background-color: var(--white-color);
  padding: 16px;
  border-radius: 16px;
  min-height: 100%;

  .offering-card-icon {
    width: 80px;
    height: 80px;
    background-color: var(--offering-icon-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 24px;
    box-shadow: 0px 0px 21.19205px 0px var(--tab-background);
    img {
      max-width: 46px;
    }
  }
  .offering-card-content {
    .offering-card-title {
      font-weight: 500;
      
    }
    p {
      font-weight: 400;
      
      font-size: 18px;
      line-height: 27px;
    }
  }
}
/***End our-offerings-section***/

/***our-specialities-section***/
.our-specialities-section {
  padding: 120px 0;
  background-color: var(--secondary-background-lt);
  .section-heading {
    padding-bottom: 32px;
    h2 {
      span {
        display: inline-block;
      }
    }
  }
}
.our-specialities-details {
  position: relative;
  .our-specialities-content {
    padding-bottom: 42px;
    padding-top: 32px;
    .specialities-content-left {
      .specialities-description {
        padding-bottom: 22px;
        h3 {
          font-size: 32px;
          line-height: 42px;
        }
        h5 {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .specialities-title {
        padding-bottom: 24px;
        font-weight: 500;
        
      }
      p {
        font-size: 18px;
        line-height: 24px;
        
        font-weight: 400;
      }
      ul {
        padding-left: 20px;
        margin-bottom: 10px;
        li {
          padding-bottom: 16px;
          font-size: 18px;
          line-height: 24px;
          
          font-weight: 400;
        }
        h3{
          font-size: 20px;
        }
      }
      .specialities-bottom-text {
        font-weight: 500;
      }
    }
    .specialities-content-right {
      img {
        width: auto;
        margin: 0 auto;
        display: block;
        border-radius: 16px;
        max-width: 100%;
      }
    }
  }
  .our-specialities-tab {
    background: var(--tab-background);
    border-radius: 8px 8px 0px 0px;
    ul.nav-pills{
      flex-wrap: nowrap;
      overflow-x: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .nav-item {
      flex: auto;
      .nav-link {
        background: transparent;
        color: var(--tab-nav-link);
        width: 100%;
        padding: 16px;
        text-align: center;
        position: relative;
        font-size: 18px;
        font-weight: 400;
        color: var(--text-secondary);
        white-space: nowrap;
        &:hover {
          
        }
        &.active {
          background-color: var(--white-color);
          font-weight: 500;
          border-radius: 16px 16px 0 0;
          &::after {
            transform: scale(1);
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-color: var(--primary-color);
          position: absolute;
          bottom: 0;
          left: 0;
          transform: scale(0);
          transition: all 0.5s;
        }
      }
    }
  }
  .swiper-arrow {
    position: absolute;
    right: 0px;
    top: -50px;
    z-index: 2;
    .swiper-prev,.swiper-next{
      &.disabled{
        opacity: 0.2;
        cursor: pointer;
      }
    }
  }

  .our-specialities-tab-dp{
    width: 100%;
    margin: 5px 0;
    position: relative;
    display: none;
    .tabs-dp-header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px solid var(--border-color-primary-ltr);
      border-radius: 5px;
      padding: 10px 10px;
      cursor: pointer;
      p{
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        padding: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tabs-dp-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        padding: 5px;
        background-color: var(--tab-border-color);
        border-radius: 5px;
        &.active{
          img{
            transition: 0.2s;
            transform: rotate(180deg);
          }
        }
      }
    }
    .tabs-dp-options{
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      display: none;
      padding: 0px 0px;
      justify-content: flex-start;
      text-align: left;
      flex-direction: column;
      border: 2px solid var(--border-color-secondary-ltr);
      border-radius: 5px;
      margin-top: 4px;
      background-color: var(--white-color);
      max-height: 400px;
      overflow-y: scroll;
      &.active{
        display: flex;
      }

      .nav-link{
        background: transparent;
        color: var(--tab-nav-link);
        width: 100%;
        text-align: left;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary);
        white-space: nowrap;
        padding: 10px 10px;
        border-bottom: 1px solid var(--submenu-border);
      }
    }
  }
}
/***End our-specialities-section***/

/***edtech-game-section***/
.edtech-game-section {
  padding: 120px 0 100px;
  .section-heading {
    padding-bottom: 32px;
  }
  .section-full-image {
    padding-bottom: 32px;
    img {
      border-radius: 16px;
      width: 100%;
    }
  }
  .edtech-game-left {
    .h2 {
      
      font-weight: 500;
    }
  }
  .edtech-game-right {
    p {
      font-size: 18px;
      line-height: 28px;
    }
    .read-more-btn{
      color: var(--primary-color);
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      cursor: pointer;
    }
  }
  .edtech-game-card {
    padding-top: 42px;
    .offering-card {
      margin-bottom: 24px;
      min-height: calc(100% - 24px);
    }
  }
}

/***industry cta section***/
.industry-cta-section{
  .cta-inner{
    .cta-details{
      h2,p{
        color: var(--text-secondary);
      }
    }
  }
}

.tabs-dp-options::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

/* Track */
.tabs-dp-options::-webkit-scrollbar-track {
  background: #e0f2f9;
}

/* Handle */
.tabs-dp-options::-webkit-scrollbar-thumb {
  background: #e0f2f9;
}

/***End edtech-game-section***/


@media (max-width: 767px) {

  .our-specialities-details{
    .our-specialities-tab {
      display: none;
      .nav-item {
        .nav-link {
          font-size: 14px !important;
          line-height: 20px;
        }
      }
    }
  
    .swiper-arrow{
      display: none;
    }
  
    .our-specialities-tab-dp{
      display: block;
      .tabs-dp-options{
        .nav-link{
          white-space: initial;
        }
      }
    }
  }
  
}