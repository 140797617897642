
.article-section {
  background-color: var(--article-bg);
  padding: 60px 0px 80px;
  .article-inner {
    .article-heading {
      margin-bottom: 32px;
      h1, h2 {
        font-size: 64px;
        line-height: 76px;
        font-weight: 700;
        
        text-transform: capitalize;
        padding: 0px;
        span {
          color: var(--primary-color);
        }
      }
    }
    .latest-article-card {
      .latest-article-img {
        position: relative;
        overflow: hidden;
        border-radius: 16px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          transition: all 0.3s ease;
        }
        .computing-link {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          color: var(--white-color);
          display: inline-block;
          text-transform: capitalize;
          padding: 15px 15px;
          min-width: 200px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          background-color: var(--computing-link);
          border-radius: 0px 8px 0px 16px;
          text-align: center;
          z-index: 1;
          transition: all 0.3s ease;
        }
      }
      .latest-article-content {
        padding-top: 16px;
        span {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          
          text-transform: capitalize;
          display: inline-block;
          margin-bottom: 12px;
        }
        h3 {
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          
          text-transform: capitalize;
          margin-bottom: 12px;
          padding: 0px;
          transition: all 0.3s ease;
        }
        p {
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          
          margin-bottom: 0px;
        }
        .article-date-time{
          color:#565656 !important;
          .article-time{
            position: relative;
            margin-left: 30px;
            &::before{
              content: "";
              width: 6px;
              height: 6px;
              border-radius: 30px;
              opacity: 0.75;
              position: absolute;
              left: -30%;
              top: 50%;
              transform: translate(-50%, -50%);
              background-color: var(--text-secondary);
            }
          }
        }
        .article-title{
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          color: var(--text-secondary);
        }
      }
      &:hover {
        .latest-article-img {
          img {
            transform: scale(1.1);
          }
          .computing-link {
            background-color: var(--btn-primary-light-hover);
            color: var(--white-color);
          }
        }
        .latest-article-content {
          h3 {
            color: var(--primary-color) !important;
          }
        }
      }
    }
    .latest-article-all-card {
      .latest-article-card {
        margin-bottom: 30px;
        display: flex;
        .latest-article-img {
          margin: 0px;
          margin-right: 24px;
          img {
            width: 100%;
            border-radius: 16px;
            aspect-ratio: 2 / 1.3;
          }
          .computing-link {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: var(--white-color);
            padding: 9px 12px;
            min-width: 144px;
          }
        }
        .latest-article-content {
          padding: 13px 0px;
          h3 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            
          }
          .title-text{
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            color: var(--text-secondary);
          }
          .description-text{
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.all-article-section {
  padding: 80px 0px;
  .all-article-inner {
    .all-article-tag {
      position: sticky;
      top: 120px;
      .nav-pills {
        .nav-link {
          font-size: 18px;
          line-height: 21px;
          font-weight: 500;
          color: var(--nav-link-color);
          text-transform: capitalize;
          padding: 12px 40px 12px 24px;
          text-align: left;
          border-radius: 8px;
          max-width: 277px;
          min-height: 60px;
          position: relative;
          transition: all 0.3s ease;
          margin-bottom: 4px;
          border: 0px;
          &:after {
            content: "";
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translate(0%, -50%);
            transition: all 0.3s ease;
            width: 19px;
            height: 14px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../assets/images/tabarrow.svg");
            opacity: 0;
          }
          &.active {
            background-color: var(--white-color);
            color: var(--nav-link-hover);
            font-weight: 500;
            &:after {
              opacity: 1;
              right: 16px;
            }
          }
          &:hover {
            background-color: var(--white-color);
            color: var(--nav-link-hover);
            font-weight: 500;
            &:after {
              opacity: 1;
              right: 16px;
            }
          }
        }
      }
    }
    .all-article-tab-content {
      .articles-box {
        margin-bottom: 30px;
        .latest-article-card {
          margin-bottom: 42px;
          .latest-article-img {
            position: relative;
            border-radius: 16px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              transition: all 0.3s ease;
            }
            .computing-link {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              color: var(--white-color);
              display: inline-block;
              text-transform: capitalize;
              padding: 15px 15px;
              min-width: 160px;
              position: absolute;
              bottom: 0px;
              left: 0px;
              background-color: var(--computing-link);
              border-radius: 0px 8px 0px 16px;
              text-align: center;
              z-index: 1;
              transition: all 0.3s ease;
            }
          }
          .latest-article-content {
            padding-top: 16px;
            
            span {
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              
              text-transform: capitalize;
              display: inline-block;
              margin-bottom: 12px;
            }
            h3 {
              font-size: 20px;
              line-height: 30px;
              font-weight: 500;
              
              text-transform: capitalize;
              margin-bottom: 12px;
              padding: 0px;
              transition: all 0.3s ease;
            }
            p {
              font-size: 18px;
              line-height: 27px;
              font-weight: 400;
              
              margin-bottom: 0px;
            }
            .article-date-time{
              color:#565656 !important;
              .article-time{
                position: relative;
                margin-left: 30px;
                &::before{
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 30px;
                  opacity: 0.75;
                  position: absolute;
                  left: -30%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  background-color: var(--text-secondary);
                }
              }
            }
            .title-text{
              font-size: 20px;
              line-height: 30px;
              font-weight: 500;
              color: var(--text-secondary);
            }
          }
          &:hover {
            .latest-article-img {
              img {
                transform: scale(1.1);
              }
              .computing-link {
                background-color: var(--btn-primary-light-hover);
                color: var(--white-color);
              }
            }
            .latest-article-content {
              h3 {
                color: var(--primary-color) !important;
              }
            }
          }
        }
      }
    }
  }
}
.article-pagination {
  .pagination {
    justify-content: center;
    .page-item {
      position: relative;
      .page-link {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        
        border: 0px;
        padding: 6px;
        border-radius: 8px;
        background-color: transparent;
        box-shadow: 0px 0px 0px 0px;
        min-width: 44px;
        height: 44px;
        margin: 4px;

        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-secondary);

        &:hover {
          background-color: var(--primary-color);
          color: var(--white-color);
        }
      }
      &.active {
        .page-link {
          background-color: var(--primary-color);
          color: var(--white-color);
        }
      }
      &:first-child {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: -24px;
          transform: translate(0%, -50%);
          transition: all 0.3s ease;
          width: 20px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.5 9C22.0523 9 22.5 8.55228 22.5 8C22.5 7.44772 22.0523 7 21.5 7L21.5 9ZM0.792893 7.29289C0.402369 7.68342 0.402369 8.31658 0.792892 8.7071L7.15685 15.0711C7.54738 15.4616 8.18054 15.4616 8.57107 15.0711C8.96159 14.6805 8.96159 14.0474 8.57107 13.6569L2.91421 8L8.57107 2.34314C8.96159 1.95262 8.96159 1.31946 8.57107 0.928931C8.18054 0.538407 7.54738 0.538407 7.15685 0.928931L0.792893 7.29289ZM21.5 7L1.5 7L1.5 9L21.5 9L21.5 7Z' fill='%2322A5D9'/%3E%3C/svg%3E%0A");
        }
        .page-link {
          color: var(--primary-color);
          &:hover {
            background-color: transparent;
            color: var(--primary-color);
          }
        }
      }
      &:last-child {
        &::after {
          content: "";
          position: absolute;
          right: -24px;
          top: 53%;
          transform: translate(0%, -53%);
          transition: all 0.3s ease;
          width: 20px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7C0.947715 7 0.5 7.44772 0.5 8C0.5 8.55228 0.947715 9 1.5 9V7ZM22.2071 8.70711C22.5976 8.31658 22.5976 7.68342 22.2071 7.29289L15.8431 0.928932C15.4526 0.538408 14.8195 0.538408 14.4289 0.928932C14.0384 1.31946 14.0384 1.95262 14.4289 2.34315L20.0858 8L14.4289 13.6569C14.0384 14.0474 14.0384 14.6805 14.4289 15.0711C14.8195 15.4616 15.4526 15.4616 15.8431 15.0711L22.2071 8.70711ZM1.5 9H21.5V7H1.5V9Z' fill='%2322A5D9'/%3E%3C/svg%3E%0A");
        }
        .page-link {
          color: var(--primary-color);
          &:hover {
            background-color: transparent;
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.blog-cta{
  .cta-inner{
    padding: 40px 50px;
    min-height: 260px;
    .cta-details{
      padding-right: 250px;
    }
  }
  
  .cta-card-link{
    width: 280px;
    height: 200px;

    .card-link-text{
      font-size: 22px;
    }
  }
}

@media (max-width: 1200px) {
  .blog-cta{
    .cta-inner{
      .cta-details{
        padding-right: 200px;
      }
    }
    .cta-card-link{
      width: 280px;
      height: 180px;

      .card-link-text{
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .latest-article-all-card {
    width: 100%;
    --auto-grid-min-size: 12rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--auto-grid-min-size), 1fr)
    );
  }
  .blog-cta{
    .cta-inner{
      .cta-details{
        padding-right: 150px;
      }
    }
    .cta-card-link{
      width: 215px;
      height: 160px;
    }
  }
}
@media (max-width: 991px) {
  .article-section {
    padding: 50px 0px 50px;
    .article-inner {
      .article-heading {
        h2 {
          font-size: 40px;
          line-height: 48px;
        }
      }
      .latest-article-card {
        margin-bottom: 30px;
        .latest-article-img {
          .computing-link {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: var(--white-color);
            padding: 9px 12px;
            min-width: 144px;
          }
        }
        .latest-article-content {
          span {
            font-size: 14px;
            line-height: 18px;
          }
          h3 {
            font-size: 18px;
            line-height: 28px;
          }
          P {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .latest-article-all-card {
        .latest-article-card {
          display: block;
          .latest-article-img {
            margin-right: 0px;
          }
          .latest-article-content {
            padding: 0px;
            padding-top: 16px;
            h3 {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  .all-article-section {
    padding: 50px 0px;
    .all-article-inner {
      .all-article-tag {
        .nav-pills {
          .nav-link {
            font-size: 16px;
            line-height: 20px;
            padding: 12px 41px 12px 15px;
            min-height: 45px;
          }
        }
      }
      .all-article-tab-content {
        .articles-box {
          margin-bottom: 15px;
          .latest-article-card {
            .latest-article-content {
              span {
                font-size: 14px;
                line-height: 18px;
              }
              h3 {
                font-size: 18px;
                line-height: 28px;
              }
              p {
                font-size: 16px;
                line-height: 24px;
              }
            }
            .latest-article-img {
              .computing-link {
                min-width: 144px;
                padding: 9px 12px;
              }
            }
          }
        }
      }
    }
  }
  .article-pagination {
    .pagination {
      .page-item {
        .page-link {
          font-size: 14px;
          line-height: 20px;
          min-width: 34px;
          height: 34px;
          padding: 4px;
          margin: 2px;
        }
        &:first-child {
          &:after {
            width: 14px;
            height: 10px;
            left: -15px;
            top: 52%;
            transform: translate(0%, -52%);
          }
        }
        &:last-child {
          &:after {
            width: 14px;
            height: 10px;
            right: -15px;
            top: 55%;
            transform: translate(0%, -55%);
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .article-section {
    padding: 40px 0px 40px;
    .article-inner {
      .article-heading {
        h2 {
          font-size: 40px;
          line-height: 50px;
        }
      }
      .latest-article-card {
        margin-bottom: 30px;
        .latest-article-content {
          span {
            font-size: 14px;
            line-height: 20px;
          }
          h3 {
            font-size: 18px;
            line-height: 22px;
          }
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .latest-article-all-card {
        .latest-article-card {
          display: flex;
          .latest-article-img {
            margin-right: 20px;
          }
          .latest-article-content {
            padding: 0px;
            padding-top: 16px;
          }
        }
      }
    }
  }
  .all-article-section {
    padding: 40px 0px;
    .all-article-inner {
      .all-article-tag {
        margin-bottom: 20px;
      }
    }
  }
  .blog-cta{
    .cta-inner{
      padding: 35px 15px 100px;
      min-height: 300px;
      .cta-details{
        padding-right: 10px;
      }
    }
    .cta-card-link{
      width: 180px;
      height: 130px;
    }
  }
}
@media (max-width: 575px) {
  .article-pagination {
    .pagination {
      .page-item {
        .page-link {
          font-size: 12px;
          line-height: 16px;
          min-width: 25px;
          height: 25px;
        }
      }
    }
  }
  .article-section .article-inner .article-heading h2 {
    font-size: 36px;
    line-height: 44px;
  }
}
@media (max-width: 480px) {
  .article-section {
    .article-inner {
      .latest-article-all-card {
        .latest-article-card {
          display: block;
          .latest-article-img {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
