
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    &.fixed-top {
        position: fixed;
        box-shadow: 0 7px 12px rgba(0, 0, 0, 0.02);
        background-color: var(--header-background);
        // .navbar {
        //     padding: 10px 0;
        // }
    }
    .offcanvas .offcanvas-header .btn-close {
        box-shadow: none;
    }
    .navbar {
        padding: 22px 0;
        .main-navbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .logo {
                max-width: 174px;
                .navbar-brand {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    vertical-align: middle;
                }
                .quixom-logo-white {
                    display: none;
                }
            }
            .navbar-right {
                display: flex;
                align-items: center;
                flex: 1;
                padding-left: 20px;
                justify-content: flex-end;
                .navbar-toggler,
                .navbar-toggler:focus {
                    border: 0px;
                    box-shadow: none;
                    outline: none;
                }
                .navbar-nav {
                    .nav-item {
                        > .nav-link {
                            color: var(--nav-link-color);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 8px 15px;
                            position: relative;
                            &::after {
                                content: "";
                                width: calc(100% - 24px);
                                height: 2px;
                                border-radius: 5px;
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%) scaleX(0);
                                background-color: var(--nav-link-hover);
                                transition: all 0.2s;
                                display: none;
                            }
                            &:hover,
                            &.active {
                                color: var(--nav-link-hover);
                                &::after {
                                    transform: translateX(-50%) scaleX(1);
                                }
                            }
                        }
                    }
                }
                .offcanvas {
                    background-color: var(--header-background);
                    .offcanvas-header {
                        .offcanvas-title {
                            max-width: 136px;
                        }
                    }
                }
            }
            .navbar-btn {
                padding-left: 15px;
            }
        }
    }
    .navbar-nav {
        .nav-item {
            padding: 10px 0;

            &:hover {
                .submenu {
                    display: block;
                }
            }
        }
        .submenu {
            position: absolute;
            left: 0;
            right: 0;
            top: 76px;
            width: 100%;
            display: none;
            padding-top: 23px;
            z-index: 100;
            .submenu-inner {
                background-color: var(--submenu-background);
                position: relative;
                padding: 0px 0;
                box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
                z-index: 10;
                &::after {
                    content: "";
                    position: absolute;
                    width: 50%;
                    height: 100%;
                    right: 0;
                    background-color: #fff;
                    top: 0;
                }
                .submenu-left-inner {
                    position: relative;
                    z-index: 2;
                    padding: 60px 0;
                    .submenu-heading {
                        padding-bottom: 14px;
                        .sub-menu-title {
                            
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                    .sub-menu-content {
                        ul {
                            padding: 0;
                            li {
                                list-style: none;
                                margin-bottom: 12px;
                                .sub-menu-link {
                                    color: var(--nav-link-color);
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    padding: 10px 0;
                                    display: inline-block;
                                    transition: all 0.4s;
                                    &:hover,
                                    &.active {
                                        color: var(--nav-link-hover);
                                    }
                                }
                            }
                        }
                    }
                    .submenu-bottom-link {
                        .btn-link {
                            color: var(--nav-link-hover);
                            padding: 0;
                            position: relative;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-decoration: none;
                            transition: all 0.4s;
                            &:hover {
                                .btn-link-arrow {
                                    transform: translateX(0px);
                                }
                            }

                            .btn-link-arrow {
                                margin-left: 12px;
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                background-size: contain;
                                vertical-align: middle;
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' viewBox='0 0 23 16' fill='none'%3E%3Cpath d='M1.60352 7C1.05123 7 0.603516 7.44772 0.603516 8C0.603516 8.55228 1.05123 9 1.60352 9V7ZM22.3106 8.70711C22.7011 8.31658 22.7011 7.68342 22.3106 7.29289L15.9467 0.928932C15.5561 0.538408 14.923 0.538408 14.5324 0.928932C14.1419 1.31946 14.1419 1.95262 14.5324 2.34315L20.1893 8L14.5324 13.6569C14.1419 14.0474 14.1419 14.6805 14.5324 15.0711C14.923 15.4616 15.5561 15.4616 15.9467 15.0711L22.3106 8.70711ZM1.60352 9H21.6035V7H1.60352V9Z' fill='%2322A5D9'/%3E%3C/svg%3E");
                                transition: all 0.4s;
                                transform: translateX(-8px);
                            }
                        }
                    }
                }
                .submenu-right-col {
                    background-color: #fff;
                }
                .submenu-right-inner {
                    position: relative;
                    z-index: 2;
                    padding-left: 12px;
                    padding-top: 60px;
                    padding-bottom: 60px;
                    .submenu-card {
                        .submenu-card-thumbnail {
                            margin-bottom: 17px;
                            img {
                                border-radius: 16px;
                            }
                        }
                        .submenu-card-content {
                            padding-bottom: 31px;
                            p {
                                
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 27px;
                            }
                        }
                    }
                    .submenu-btn-grup {
                        display: flex;
                        .submenu-btn {
                            margin-right: 30px;
                            .btn-secondary {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                padding: 14px 30px 13px;
                                min-width: 222px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.menu-overlay {
    content: "";
    position: fixed;
    left: 0;
    top: 100px;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    transition: all 0.3s ease-in-out;
    display: none;
    &.show {
        display: block;
    }
}
.header .navbar-nav .submenu .submenu-inner .submenu-left-inner .sub-menu-content ul li .sub-menu-link.all-service-link
{
  color:#22a5d9 !important;
}
/*****/
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.mobile-menu .no-child::after{
    display: none !important;
}
.mobile-menu .no-child a{
    width: 100%;
    font-family: "DM Sans", sans-serif !important;
}

.mobile-menu .accordion-item .accordion-button{
    font-family: "DM Sans", sans-serif !important;
}
.mobile-menu .accordion-item .accordion-button a{
    font-family: "DM Sans", sans-serif !important;
}
  
@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

.mobile-menu {
    display: none;
}
.header.transparent-sticky-header {
    .navbar .main-navbar .navbar-right .navbar-nav .nav-item > .nav-link {
        color: var(--white-color);
        &:hover,
        &.active {
            color: var(--nav-link-hover);
        }
    }
    .navbar .main-navbar .logo {
        .quixom-logo-white {
            display: block;
        }
        .quixom-logo-gray {
            display: none;
        }
    }
    &.fixed-top {
        .navbar .main-navbar .logo {
            .quixom-logo-white {
                display: none;
            }
            .quixom-logo-gray {
                display: block;
            }
        }
        .navbar .main-navbar .navbar-right .navbar-nav .nav-item > .nav-link {
            color: var(--nav-link-color);
            &:hover,
            &.active {
                color: var(--nav-link-hover);
            }
        }
    }
}

.submenu-blog-div {
    padding-bottom: 32px;
    .submenu-blog-card {
        padding: 16px;
        border-radius: 16px;
        background-color: var(--white-background-color);
        transition: all 0.5s;
        &:hover{
            background-color: var(--card-background);
            .submenu-blog-content{
                h3{
                    
                }
            }
        }
        .submenu-blog-thumbnail {
            width: 100%;
            height: 200px;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 12px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .submenu-blog-content {
            h3 {
                
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 27px;
                padding-bottom: 8px;
                transition: all 0.5s;
            }
            p {
                color: var(--light-text-color);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}
/*****responsive*****/
@media (max-width: 1200px) {
    .header .navbar-nav .submenu .submenu-inner .submenu-right-inner .submenu-btn-grup .submenu-btn .btn-secondary {
        min-width: 180px;
    }
    .submenu-blog-div .submenu-blog-card .submenu-blog-thumbnail{
        height: 160px;
    }
}

@media (max-width: 991px) {
    .header {
        .navbar {
            padding: 12px 0;
            .navbar-toggler {
                padding-right: 0px;
            }
            .main-navbar {
                .navbar-right {
                    flex-direction: row-reverse;
                    margin-left: auto;
                    justify-content: flex-start;
                    .navbar-nav {
                        .nav-item {
                            > .nav-link {
                                margin-bottom: 10px;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    .offcanvas {
                        min-height: 100vh;
                    }
                    .navbar-toggler {
                        background-color: var(--nav-link-hover);
                        padding: 11px;
                        border-radius: 8px;
                        transition: all 0.5s;
                        margin-right: 0 !important;
                        .navbar-toggler-icon {
                            background-image: url("data:image/svg+xml,%3csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.25 7.5835H22.75' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3e%3cpath d='M3.25 13H22.75' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3e%3cpath d='M3.25 18.4167H22.75' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3e%3c/svg%3e");
                            width: 26px;
                            height: 26px;
                        }
                        &:hover {
                            background-color: var(--secondary-color);
                        }
                    }
                    .navbar-btn {
                        display: none;
                    }
                }
            }
        }
    }
    .mobile-Offcanvas.offcanvas-lg.offcanvas-end {
        height: 100vh;
        border-left: none;
        width: 100%;
        .offcanvas-body {
            background-color: var(--offcanvas-body-background);
        }
        .offcanvas-header {
            background-color: var(--header-background);
        }
    }

    .web-navbar {
        display: none;
    }
    .mobile-menu {
        display: block;
    }

    .mobile-menu .accordion-body {
        padding-top: 0 !important;
        border: 0 !important;
        background-color: transparent;
        box-shadow: none !important;
    }

    .mobile-menu .accordion-item {
        border: 0 !important;
        border-bottom: 1px solid var(--tab-border-color) !important;
    }
    .mobile-menu .accordion-header,
    .mobile-menu .accordion-item {
        border-radius: 0 !important;
        box-shadow: none !important;
        background-color: transparent;
        padding-bottom: 0;
    }
    .mobile-menu .accordion-header,
    .mobile-menu .accordion-item {
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    .mobile-menu .accordion-header {
        margin-bottom: 1px !important;
        border: 0 !important;
    }

    .header .navbar .main-navbar .navbar-right .offcanvas {
        background-color: var(--white-color);
    }
    .mobile-menu .menu-item-2 {
        background: unset !important;
    }
    .mobile-menu .menu-item {
        padding: 0;
        margin: 0;
    }
    .mobile-menu .menu-item-2 li {
        background: var(--card-background) !important;
        margin-bottom: 12px !important;
        border-radius: 8px !important;
        padding: 15px;
        list-style: none;
    }
    .mobile-menu .menu-item li a:last-child {
        margin-bottom: 0 !important;
    }
    .mobile-menu .menu-item-2 li a {
        padding: 0 !important;
        display: block;
    }
    .mobile-menu .menu-item li p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: var(--secondary-color);
    }
    .mobile-menu .menu-item li a .submenu-heading {
        margin-bottom: 0;
        display: block;
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        color: var(--secondary-color) !important;
        padding-bottom: 6px;
    }
    .mobile-menu .accordion-button[aria-expanded="false"] {
        &::after {
            background-image: url(../assets/images/accordion-down-arrow.svg) !important;
        }
    }
    .mobile-menu .accordion-button:not(.collapsed):after {
        background-image: url(../assets/images/acordion-right-arrow.svg);
    }
    .mobile-menu .accordion-button:after {
        content: "";
        background-image: url(../assets/images/accordion-down-arrow.svg);
        width: 24px;
        height: 24px;
        background-size: cover;
        background-position: 50%;
        transform: unset;
    }

    .mobile-menu a.accordion-button:after {
        display: none;
    }
    .mobile-menu {
        .accordion,
        .accordion-item,
        .accordion-header,
        .accordion-button {
            background: transparent !important;
        }
        .accordion {
            padding-bottom: 42px;
        }
        .accordion-item {
            .accordion-button {
                padding: 25px 0;
                
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                box-shadow: none !important;
                border-radius: 0 !important;
                transition: all 0.4s;
                &:hover {
                    
                }
            }
            .accordion-body {
                padding: 0;
                .mobile-sub-menu {
                    padding: 0;
                    li {
                        list-style: none;
                        margin-bottom: 20px;
                        .mobile-menu-link {
                            padding: 4px 0;
                            display: inline-block;
                            
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            &:hover {
                                
                            }
                        }
                    }
                }
            }
        }
        .mobile-menu-btn {
            text-align: center;
            padding-bottom: 30px;
            display: flex;
            justify-content: center;
            .btn-primary {
                min-width: 180px;
                margin: 8px 10px;
            }
        }
    }
    .mobile-Offcanvas.offcanvas-lg .offcanvas-header .btn-close {
        box-shadow: none;
        padding: 0;
        width: 48px;
        height: 48px;
        opacity: 1;
        background: none;
    }
    .mobile-Offcanvas.offcanvas-lg .offcanvas-header .offcanvas-title{
        max-width: 136px;
    }
}
@media (max-width: 767px) {
    .header {
        .navbar {
            .main-navbar {
                .logo {
                    max-width: 140px;
                }
                .navbar-right {
                    .navbar-btn {
                        .btn-primary {
                            padding: 10px 20px;
                        }
                    }
                }
            }
        }
    }
    .mobile-menu {
        .accordion {
            padding-bottom: 32px;
        }
        .mobile-menu-btn {
            text-align: center;
            padding-bottom: 30px;
            display: flex;
            justify-content: center;
            flex-flow: column;
            align-items: center;
            .btn-primary {
                min-width: 180px;
                margin: 8px 10px;
            }
        }
        .accordion-item .accordion-button {
            padding: 20px 0;
        }
        .accordion-item .accordion-body .mobile-sub-menu li {
            margin-bottom: 16px;
        }
    }
    .mobile-Offcanvas.offcanvas-lg .offcanvas-header .btn-close {
        width: 38px;
        height: 38px;
    }
}
@media (max-width: 575px) {
    .header {
        .navbar {
            .main-navbar {
                .navbar-right {
                    .navbar-btn {
                        .btn-primary {
                            padding: 10px 10px;
                            white-space: nowrap;
                        }
                    }
                    .offcanvas {
                        min-height: 100vh;
                        width: 100%;
                        border: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
    .header .navbar-nav .submenu .submenu-inner {
        padding: 40px 0;
        .submenu-left-inner {
            .sub-menu-content {
                ul li .sub-menu-link {
                    padding: 5px 0;
                }
            }
        }
    }
    .header .navbar-nav .submenu .submenu-inner .submenu-right-inner, .header .navbar-nav .submenu .submenu-inner .submenu-left-inner{
        padding: 40px 0;
    }
    .submenu-blog-div .submenu-blog-card .submenu-blog-thumbnail{
        height: 160px;
    }
}
// 28-03-2024
.submenu.submenu-services{
    .submenu-inner{
        &:after{
            width: auto !important;
            min-width: calc(71vw - 0%) !important;
        }
        .submenu-left-inner{
            display: flex;
            .submenu-heading{
                width: 20%;
            }
            .sub-menu-content{
                width: 80%;
                padding-left: 20px;
                ul{
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    display: flex;
                   flex-wrap: wrap;
                    li{
                        display: inline-block;
                        width: 20%;
                        margin-bottom: 48px !important;
                
                        .sub-menu-link{
                            padding: 0px 15px !important;
                            display: inline-flex !important;
                            align-items: center;
                            transition: all 0.4s !important;
                            text-decoration: none;
                            border-right: 1px solid ;
                            word-break: break-word;
                            width: 100%;
                            position: relative;
                            border: 0px;
                            &:after{
                                content: '';
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                bottom: 0px;
                                height: 46px;
                                width: 1px;
                                background-color:rgba(83, 210, 253, 1);
                            }
                            &:hover{
                                color: #22A5D9;
                            }
                            .active{
                                color: #22A5D9;
                            }

                        }
                        &:nth-child(5n+0){
                            .sub-menu-link{
                                &:after{
                                    background-color:transparent;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
    .submenu-bottom-link{
        display: none;
    }
}
@media (min-width:1921px){
    .submenu.submenu-services{
        .submenu-inner{
            &:after{
                min-width: calc(56vw - 0%) !important;
            }
        }
    }
}
@media (max-width:1750px) and (min-width:1570px){
    .submenu.submenu-services{
        .submenu-inner{
            &:after{
                min-width: calc(75vw - 0%) !important;
            }
        }
    }
}
@media (max-width:1570px) and (min-width:1360px){
    .submenu.submenu-services{
        .submenu-inner{
            &:after{
                min-width: calc(78vw - 0%) !important;
            }
        }
    }
}
@media (max-width:1360px) and (min-width:1200px){
    .submenu.submenu-services{
        .submenu-inner{
            &:after{
                min-width: calc(77vw - 0%) !important;
            }
        }
    }
}
@media (max-width:1200px) and (min-width:992px){
    .submenu.submenu-services{
        .submenu-inner{
            &:after{
                min-width: calc(76vw - 0%) !important;
            }
        }
    }
}
 @media (max-width:991px){
     .submenu.submenu-services{
         .submenu-inner{
            .submenu-left-inner{
                 .submenu-heading{
                    width: 100%;
                }
                .sub-menu-content{
                    width: 100%;
                    ul{
                        li{
                            width: 100%;
                            margin-bottom: 20px !important;
                            .sub-menu-link{
                                padding: 0px 0px !important;
                                &:after{
                                    background-color:transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// 28-03-2024
