.careers-hero-section {
    background-image: url(../assets/images/careers-hero-bg.webp);
    padding: 160px 0 0;
    position: relative;
    background-size: cover;
    background-position: center center;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--careers-hero-overlay);
        opacity: 0.9;
    }
    .careers-hero-inner {
        position: relative;
        z-index: 2;
    }
    .careers-hero-heading {
        padding-bottom: 60px;
        h1 {
            color: var(--text-white);
            span {
                display: block;
            }
        }
        p {
            color: var(--text-white);
            max-width: 585px;
        }
    }
    .careers-hero-img {
        text-align: center;
        margin-bottom: -63px;
        img {
            width: 210px;
        }
    }
    .careers-hero-content .careers-hero-row{
        .align-end {
            align-self: flex-end;
        }
    }
}

/***careers-overview-section***/
.careers-overview-section {
    padding: 120px 0 120px;
    .careers-overview-title {
        position: sticky;
        top: 150px;
        h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            
        }
    }
    .overview-short-description {
        padding-bottom: 12px;
        p {
            
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
        }
    }
    .overview-full-description {
        p {
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
        }
    }
}
/***end overview-section***/
/***careers-Insights-section***/
.careers-insights-section {
    padding: 120px 0;
    .section-heading {
        padding-bottom: 20px;
        padding-right: 65px;
        h2 {
            span {
                display: block;
            }
        }
    }
    .careers-insights-card {
        position: relative;
        background-color: var(--white-background-color);
        padding: 0px 24px 24px;
        border-radius: 16px;
        margin-top: 40px;
        display: inline-block;
        width: 100%;
        .careers-insights-icon {
            margin-top: -40px;
            padding-bottom: 16px;
            img {
                width: 80px;
            }
        }
        .careers-insights-content {
            padding-bottom: 12px;
            p {
                
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
            }
        }
        .careers-insights-author {
            text-align: right;
            .insights-author-info {
                display: inline-block;
                p {
                    
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    padding-bottom: 6px;
                    &::before {
                        content: "-";
                        margin-right: 8px;
                        
                    }
                }
                span {
                    text-align: left;
                    display: block;
                    
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    padding-left: 19px;
                }
            }
        }
    }
    .careers-insights-content {
        position: relative;
        .swiper-arrow {
            text-align: right;
            position: absolute;
            top: -75px;
            right: 0;
        }
    }
}
/***end careers-insights-section***/

/***job-positions-section***/
.job-positions-section {
    padding: 120px 0;
    .job-positions-heading {
        padding-bottom: 20px;
    }
}
.job-positions-filter {
    padding-top: 50px;
}
.job-filter-form {
    text-align: right;
}
.positions-filter-form {
    display: inline-flex;
    background-color: rgba(34, 165, 217, 0.05);
    padding: 6px;
    border-radius: 8px;
    align-items: center;
    width: 585px;
    max-width: 100%;
    justify-content: space-between;
    &.positions-filter-focused {
        outline: 1px solid #22a5d9;
    }
    .form-control,
    .form-select {
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        
    }
    .job-positions-name {
        border-right: 1px solid #22a5d9;
        flex: 1;
    }
    .job-positions-Location {
        padding: 0 20px 0 40px;
    }
    .job-positions-search {
        .btn.btn-primary {
            padding: 10px 15px;
            &::after,
            &::before {
                display: none;
            }
        }
    }
}
.open-job-positions {
    background-color: var(--white-background-color);
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
    &:hover {
        box-shadow: 0 0 15px rgba(211, 211, 211, 0.3);
    }
    .open-job-name {
        padding-bottom: 16px;
        // display: flex;
        // align-items: flex-start;
        .job-name {
            flex: 1;
            padding-right: 150px;
            span{
                font-size: 24px;
                font-weight: 700;
                line-height: 36px;
                color: var(--text-secondary);
            }
        }
        h4 {
            
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            padding-bottom: 2px;
        }
        p {
            
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            color: var(--primary-color);
        }
    }
    .open-job-description {
        padding-bottom: 16px;
        p {
            
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            opacity: 0.75;
        }
    }
    .open-job-tag {
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            li {
                list-style: none;
                margin-right: 16px;
                .job-tag {
                    padding: 10px 24px 11px 24px;
                    
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    display: inline-block;
                    border: 1px solid var(--border-color-primary-ltr);
                    border-radius: 8px;
                }
            }
        }
    }
    .job-apply {
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        transition: all 0.4s ease-in-out;
        .job-apply-btn {
            padding: 12px 53px 12px 21px;
            color: var(--text-white);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            display: inline-block;
            background-color: var(--primary-background-color);
            border-radius: 0px 16px;
            transition: all 0.4s;
            position: relative;
            &:hover {
                background-color: var(--secondary-color);
                &::after {
                    right: 20px;
                }
            }
            &::after {
                content: "";
                background-image: url(../assets/images/apply-right-icon.svg);
                width: 20px;
                height: 20px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translateY(-44%);
                transition: all 0.3s;
            }
        }
    }

    &:hover {
        .job-apply {
            right: 0;
            opacity: 1;
        }
    }
}
.job-positions-list {
    padding-bottom: 16px;
}
.load-more-job {
    text-align: center;
}
/***end job-positions-section***/

/****cta-section*****/
.careers-gradient-cta {
    &.cta-section.gradient-cta-section {
        padding-top: 0;
        .cta-inner {
            min-height: 250px;
            .cta-details{
                h2{
                    color: var(--secondary-color);
                }
            }
        }
        .card-link {
            width: 334px;
            height: 250px;
        }
    }
}

/****end cta-section*****/

/***careers-promises-section***/
.careers-promises-section {
    padding: 120px 0;
    overflow: hidden;
    .section-heading {
        padding-right: 65px;
        padding-bottom: 20px;
    }
}
.careers-promises-list {
    position: relative;
    .swiper-arrow {
        position: absolute;
        top: -75px;
        right: 0;
    }
    .careers-promises-card {
        .careers-promises-thumbnail {
            width: 100%;
            height: 440px;
            border-radius: 16px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .careers-promises-details {
            background-color: var(--white-background-color);
            padding: 24px;
            border-radius: 16px;
            width: calc(100% - 32px);
            margin: -100px auto 0;
            position: relative;
            z-index: 2;
            .careers-promises-title {
                color: var(--careers-promises-title);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }
            p {
                color: var(--careers-promises-title);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
            }
        }
    }
}
.swiper-careers-promises {
    position: relative;
    overflow: visible;
    -webkit-clip-path: inset(-100vw -100vw -100vw 0);
    clip-path: inset(-100vw -100vw -100vw 0);
    .swiper {
        height: 100%;
    }
    .swiper-slide {
        height: auto !important;
    }
    .careers-promises-card {
        height: 100% !important;
        display: flex;
        flex-flow: column;
        .careers-promises-details {
            flex: 1;
        }
    }
}
/***end careers-promises-section***/

/***our-culture-section***/
.our-culture-section {
    padding: 120px 0 60px;
    .section-heading {
        padding-bottom: 20px;
    }
    .our-culture-images {
        position: relative;
        .culture-logo-img {
            position: absolute;
            right: 17%;
            top: 50%;
            transform: translate(-50%, -65%);
            width: 100px;
            height: 100px;
            z-index: 3;
        }
        .culture-img {
            margin-bottom: 24px;
            border-radius: 10px;
            overflow: hidden;
            &:hover img {
                transform: scale(1.03);
                transition: all 0.4s linear;
            }
            img {
                width: 100%;
                height: 311px;
                object-fit: cover;
                object-position: center center;
                transform: scale(1);
                transition: all 0.4s linear;
            }
            &.culture-img-6 {
                img {
                    height: 645px;
                }
            }
        }
    }
}
/***end our-culture-section***/
/***perks-benefits-section***/
.perks-benefits-section {
    padding: 60px 0 120px;
    .section-heading {
        padding-bottom: 20px;
    }
    .perks-benefits-details {
        .perks-benefits-card {
            padding: 24px 24px 40px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            min-height: calc(100% - 24px);
            margin-bottom: 24px;
            &::after {
                content: "";
                opacity: 0;
                background: linear-gradient(180deg, rgba(34, 165, 217, 0) 0%, #22a5d9 100%);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.4s linear;
                border-radius: 16px;
            }
            &:hover {
                &::after {
                    top: 0%;
                    opacity: 0.1;
                }
            }
            .perks-benefits-icon {
                padding-bottom: 24px;
                position: relative;
                z-index: 2;
                img {
                    width: 46px;
                    height: auto;
                }
            }
            .perks-benefits-content {
                position: relative;
                z-index: 2;
                h3 {
                    
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                p {
                    
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}
/***end perks-benefits-section***/

/***Job Details modal***/
.Job-apply-modal {
    .modal-dialog {
        padding: 0 10px;
        max-width: 1200px;
        .modal-content {
            border: 0;
            border-radius: 16px;
            .modal-header {
                padding: 24px 32px 24px 32px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                border-bottom: 1px solid var(--border-color-secondary-ltr);
                .modal-title {
                    padding-bottom: 0;
                    
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 36px;
                }
                .btn-close {
                    background-size: cover;
                    box-shadow: none;
                    outline: none;
                    opacity: 1;
                    background-image: url(../assets/images/btn-close-bg.svg);
                    border-radius: 100%;
                    transition: all 0.4s;
                    &:hover {
                        background-color: var(--secondary-background-ltr);
                    }
                }
            }
        }
        .modal-body {
            padding: 0;
            border: none;
        }
        .Job-apply-details {
            .apply-job-details {
                padding: 20px 30px;
                border-bottom: 1px solid var(--border-color-secondary-ltr);
                .apply-job-name {
                    padding-bottom: 16px;
                    h3 {
                        
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 48px;
                        padding-bottom: 8px;
                    }
                    p {
                        
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 27px;
                    }
                }
                .apply-job-tag {
                    ul {
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            list-style: none;
                            margin-right: 16px;
                            .job-tag {
                                padding: 10px 24px 11px 24px;
                                
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 25px;
                                display: inline-block;
                                border: 1px solid var(--border-color-primary-ltr);
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }
        }
        .apply-job-overview {
            .accordion-item {
                padding: 14px 0;
            }
            .accordion-button.collapsed::after {
                width: 24px;
                height: 24px;
                transform-origin: center center;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6V18' stroke='%2307252F' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M18 12L6 12' stroke='%2307252F' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
            }
            .accordion-button:not(.collapsed)::after {
                opacity: 0;
                width: 24px;
                height: 24px;
            }
            .accordion-item .accordion-button,
            .accordion-flush .accordion-item .accordion-button {
                
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
                padding: 8px 24px 8px 30px;
                border: 0;
                box-shadow: none;
                background: transparent;
            }
            .accordion-header {
                padding-bottom: 0;
            }
            .accordion-body {
                padding: 0px 24px 21px 30px;
                .job-overview-content {
                    
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    p,
                    li,
                    span,
                    a {
                        
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
            }
        }
        .apply-job-left {
            border-right: 1px solid var(--border-color-secondary-ltr);
            min-height: 100%;
        }
        .apply-form-title {
            padding: 22px 30px 22px 30px;
            border-bottom: 1px solid var(--border-color-secondary-ltr);
            background-color: var(--secondary-background-lt);
            p {
                
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
            }
        }
        .apply-job-form {
            .job-form-inner {
                padding: 15px 30px 15px 30px;
                border-bottom: 1px solid var(--border-color-secondary-ltr);
            }
            .form-group {
                margin-bottom: 15px;
                label {
                    display: none;
                }
                .form-control {
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid var(--border-color-secondary-ltr);
                    border-radius: 0;
                    padding: 12px 0px;
                    box-shadow: none;
                    outline: none;
                    
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    &:focus {
                        border-bottom: 1px solid var(--border-color-primary-ltr);
                    }
                    &::placeholder {
                        opacity: 0.5;
                    }
                }
                &.upload-file-group {
                    label {
                        display: block;
                        cursor: pointer;
                        padding: 4px 0;
                    }
                    .upload-file-input {
                        display: none;
                    }
                    .upload-file-label {
                        display: flex;
                        align-items: center;
                        .upload-file-icon {
                            width: 48px;
                        }
                        .upload-file-text {
                            flex: 1;
                            padding-left: 12px;
                            opacity: 0.5;
                            p {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 21px;
                                
                            }
                        }
                    }
                }
            }
            .job-form-footer {
                text-align: right;
                padding: 24px 30px 24px 24px;
                .btn.btn-outline-primary {
                    margin-right: 18px;
                }
            }
        }
    }
}
/***end Job Details modal***/
/******************/
@media (max-width: 1366px) {
    /***careers-overview-section***/
    .careers-overview-section {
        padding: 120px 0 80px;
    }
    /***end careers-overview-section***/
    /***careers-insights-section ***/
    .careers-insights-section {
        padding: 80px 0;
    }
    /***end careers-insights-section ***/
    /*** job-positions-section***/
    .job-positions-section {
        padding: 80px 0;
    }
    /***end job-positions-section***/
    /***careers-promises-section***/
    .careers-promises-section {
        padding: 80px 0;
    }
    /***end careers-promises-section***/
    /***our-culture-section***/
    .our-culture-section {
        padding: 80px 0 40px;
    }
    /***end our-culture-section***/
    /***perks-benefits-section***/
    .perks-benefits-section {
        padding: 40px 0 80px;
    }
    /***end perks-benefits-section***/
}
@media (max-width: 1200px) {
    /***careers-overview-section***/
    .careers-overview-section {
        .careers-overview-title {
            h2 {
                font-size: 28px;
                line-height: 38px;
            }
        }
    }
    /***end careers-overview-section***/
    /****cta-section*****/
    .careers-gradient-cta {
        &.cta-section.gradient-cta-section {
            padding-top: 0;
            .cta-inner {
                min-height: 230px;
            }
            .card-link {
                width: 280px;
                height: 200px;
            }
        }
    }

    /****end cta-section*****/
    /***careers-promises-section***/
    .careers-promises-list {
        .careers-promises-card {
            .careers-promises-thumbnail {
                height: 340px;
            }
        }
    }
    /***end careers-promises-section***/
    /***our-culture-section***/
    .our-culture-section {
        padding: 80px 0 40px;

        .our-culture-images {
            .culture-logo-img {
                right: 14%;
            }
            .culture-img {
                img {
                    width: 100%;
                    height: 216px;
                }
                &.culture-img-6 {
                    img {
                        height: 455px;
                    }
                }
            }
        }
    }
    /***end our-culture-section***/
}
@media (max-width: 991px) {
    .careers-hero-section {
        padding: 120px 0 0;
    }
    /***careers-overview-section***/
    .careers-overview-section {
        padding: 60px 0 60px;
        .careers-overview-title {
            h2 {
                font-size: 24px;
                line-height: 34px;
            }
        }
        .overview-short-description p {
            font-size: 18px;
            line-height: 24px;
        }
        .overview-full-description p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    /***end careers-overview-section***/
    /***careers-insights-section ***/
    .careers-insights-section {
        padding: 60px 0;
        .careers-insights-card .careers-insights-content p {
            font-size: 20px;
            line-height: 30px;
        }
        .careers-insights-card .careers-insights-icon {
            margin-top: -30px;
            img {
                width: 60px;
            }
        }
    }

    /***end careers-insights-section ***/
    /*** job-positions-section***/
    .job-positions-section {
        padding: 60px 0;
        .job-positions-filter {
            padding-top: 30px;
            padding-bottom: 10px;
        }
        .job-filter-form {
            text-align: left;
        }
    }
    .open-job-positions .open-job-name h4 {
        font-size: 22px;
        line-height: 34px;
    }
    .open-job-positions .job-apply .job-apply-btn {
        font-size: 20px;
        line-height: 30px;
    }
    /***end job-positions-section***/
    /****cta-section*****/
    .careers-gradient-cta {
        &.cta-section.gradient-cta-section {
            padding-top: 0;
            .cta-inner {
                min-height: 180px;
            }
            .card-link {
                width: 215px;
                height: 160px;
            }
        }
    }

    /****end cta-section*****/
    /***careers-promises-list***/
    .careers-promises-section {
        padding: 60px 0;
    }
    .careers-promises-list .careers-promises-card {
        .careers-promises-thumbnail {
            height: 240px;
        }
        .careers-promises-details {
            padding: 15px;
            margin: -80px auto 0;
            .careers-promises-title {
                font-size: 22px;
                line-height: 30px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    /***end careers-promises-list***/
    /***our-culture-section***/
    .our-culture-section {
        padding: 60px 0 30px;
        .our-culture-images {
            position: relative;
            .culture-logo-img {
                right: 13%;
                width: 80px;
                height: 80px;
            }
            .culture-img {
                img {
                    width: 100%;
                    height: 152px;
                }
                &.culture-img-6 {
                    img {
                        height: 328px;
                    }
                }
            }
        }
    }
    /***end our-culture-section***/
    /***perks-benefits-section***/
    .perks-benefits-section {
        padding: 30px 0 60px;
        .perks-benefits-details {
            .perks-benefits-card {
                .perks-benefits-content {
                    h3 {
                        font-size: 22px;
                        line-height: 32px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    /***end perks-benefits-section***/

    /***Job Details modal***/
    .Job-apply-modal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    padding: 15px 20px 15px 20px;
                }
            }

            .Job-apply-details {
                .apply-job-details {
                    padding: 20px 15px;
                    .apply-job-name {
                        padding-bottom: 12px;
                        h3 {
                            font-size: 28px;
                            line-height: 38px;
                        }
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .apply-job-tag {
                        ul {
                            li {
                                margin-right: 8px;
                                margin-bottom: 8px;
                                .job-tag {
                                    padding: 8px 14px 8px 14px;
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }
            .apply-job-overview {
                .accordion-item {
                    padding: 8px 0;
                }

                .accordion-item .accordion-button,
                .accordion-flush .accordion-item .accordion-button {
                    font-size: 20px;
                    line-height: 32px;
                    padding: 8px 15px 8px 15px;
                    border: 0;
                }

                .accordion-body {
                    padding: 0px 18px 20px 15px;
                }
            }

            .apply-form-title {
                padding: 12px 15px;
                p {
                    font-size: 22px;
                    line-height: 32px;
                }
            }
            .apply-job-form {
                .job-form-inner {
                    padding: 20px 15px 20px;
                }
                .form-group {
                    margin-bottom: 12px;
                    .form-control {
                        padding: 10px 0px;
                    }
                }
                .job-form-footer {
                    text-align: right;
                    padding: 14px 20px 14px 14px;
                }
            }
        }
    }
    /***endJob Details modal***/
}
@media (max-width: 767px) {
    .careers-hero-section {
        .careers-hero-heading h1 span {
            display: inline;
        }
    }
    .careers-hero-section {
        padding: 110px 0 0;
    }
    .careers-hero-section .careers-hero-heading {
        padding-bottom: 30px;
    }
    /***careers-overview-section***/
    .careers-overview-section {
        .careers-overview-title {
            h2 {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }
    /***end careers-overview-section***/
    /***careers-insights-section ***/
    .careers-insights-section {
        padding: 60px 0;
        .careers-insights-card .careers-insights-content p {
            font-size: 16px;
            line-height: 24px;
        }
        .careers-insights-card .careers-insights-author .insights-author-info span {
            font-size: 14px;
            line-height: 22px;
        }
        .careers-insights-card .careers-insights-icon {
            margin-top: -25px;
            img {
                width: 50px;
            }
        }
    }
    /***end careers-insights-section ***/
    /*** job-positions-section***/
    .open-job-positions {
        .open-job-description p {
            font-size: 16px;
            line-height: 24px;
        }
        .open-job-name {
            padding-bottom: 10px;
            h4 {
                font-size: 20px;
                line-height: 30px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
            .job-name {
                padding-right: 100px;
            }
        }
        .open-job-tag ul li .job-tag {
            font-size: 16px;
            line-height: 24px;
            padding: 6px 16px 6px 16px;
        }
    }

    .positions-filter-form .form-control,
    .positions-filter-form .form-select {
        font-size: 16px;
        line-height: 24px;
    }
    .open-job-positions .job-apply {
        right: 0;
        opacity: 1;
        .job-apply-btn {
            font-size: 16px;
            line-height: 20px;
            padding: 10px 40px 10px 10px;
            &::after {
                width: 15px;
                height: 15px;
                right: 20px;
            }
            &:hover {
                &::after {
                    right: 15px;
                }
            }
        }
    }
    .job-positions-Location {
        max-width: calc(100% - 45px);
    }

    /*** end job-positions-section***/
    /****cta-section*****/
    .careers-gradient-cta {
        &.cta-section.gradient-cta-section {
            padding-top: 0;
            .cta-inner {
                min-height: 160px;
                padding-bottom: 100px;
            }
            .card-link {
                width: 155px;
                height: 125px;
                background-size: 40%;
            }
        }
    }

    /****end cta-section*****/
    /***careers-promises-list***/
    .careers-promises-list .careers-promises-card {
        .careers-promises-thumbnail {
            height: 240px;
        }
        .careers-promises-details {
            padding: 15px;
            margin: -60px auto 0;
            width: calc(100% - 20px);
            .careers-promises-title {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    /***end careers-promises-list***/
    /***our-culture-section***/
    .our-culture-section {
        .our-culture-images {
            .culture-logo-img {
                right: 23%;
                width: 60px;
                height: 60px;
            }
            .culture-img {
                margin-bottom: 8px;
                img {
                    width: 100%;
                    height: 112px;
                }
                &.culture-img-6 {
                    img {
                        height: 232px;
                    }
                }
            }
        }
    }
    /***end our-culture-section***/
    /***perks-benefits-section***/
    .perks-benefits-section {
        .perks-benefits-details {
            .perks-benefits-card {
                min-height: inherit;
                &::after {
                    opacity: 0.1;
                }
                .perks-benefits-content {
                    h3 {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    /***end perks-benefits-section***/

    /***Job Details modal***/
    .Job-apply-modal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    padding: 15px 15px 15px 15px;
                }
            }

            .Job-apply-details {
                .apply-job-details {
                    padding: 15px 15px;
                    .apply-job-name {
                        padding-bottom: 12px;
                        h3 {
                            font-size: 22px;
                            line-height: 32px;
                            padding-bottom: 5px;
                        }
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .apply-job-tag {
                        ul {
                            li {
                                .job-tag {
                                    padding: 8px 12px 8px 12px;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .apply-job-overview {
                .accordion-item {
                    padding: 0px 0;
                }

                .accordion-item .accordion-button,
                .accordion-flush .accordion-item .accordion-button {
                    font-size: 20px;
                    line-height: 32px;
                    padding: 8px 15px 8px 15px;
                    border: 0;
                }

                .accordion-body {
                    padding: 0px 18px 20px 15px;
                }
            }

            .apply-form-title {
                padding: 20px 15px 20px;
                margin-top: 20px;
                border-top: 1px solid var(--border-color-secondary-ltr);
                p {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .apply-job-form {
                .job-form-inner {
                    padding: 15px 15px 15px;
                }
                .form-group {
                    margin-bottom: 10px;
                }
                .job-form-footer {
                    text-align: right;
                    padding: 14px 20px 14px 14px;
                }
            }
        }
    }
    /***endJob Details modal***/
}
@media (max-width: 575px) {
    .careers-hero-section .careers-hero-img {
        text-align: right;
        max-width: 120px;
        margin-left: auto;
        img {
            width: auto;
        }
    }
    .careers-hero-section .careers-hero-heading {
        padding-bottom: 20px;
    }
    /***careers-insights-section ***/
    .careers-insights-section .careers-insights-content .swiper-arrow {
        position: relative;
        top: 0;
    }
    .careers-insights-section .section-heading {
        padding-right: 0;
    }
    /*** end careers-insights-section ***/
    /***job-positions-section***/
    .positions-filter-form {
        flex-wrap: wrap;
        .job-positions-name {
            width: 100%;
            flex: 100%;
            border-right: 0px solid var(--border-color-primary);
            border-bottom: 1px solid var(--border-color-primary);
            padding-bottom: 6px;
            margin-bottom: 6px;
        }
        .job-positions-Location {
            padding-left: 0;
        }
        .job-positions-search .btn.btn-primary {
            padding: 6px 8px;
            &::before,
            &::after {
                display: none;
            }
            img {
                width: 20px;
            }
        }
    }
    .open-job-positions .open-job-tag ul li {
        margin-right: 10px;
        .job-tag {
            padding: 6px 10px 6px 10px;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 22px;
        }
    }
    /*** end job-positions-section***/
    /***careers-promises-list***/
    .careers-promises-list .swiper-arrow {
        position: relative;
        padding-bottom: 20px;
        top: 0;
        text-align: right;
    }
    /***end careers-promises-list***/
    /***our-culture-section***/
    .our-culture-section {
        .our-culture-images {
            .culture-logo-img {
                left: 50%;
                right: auto;
                width: 60px;
                height: 60px;
                top: 68%;
                z-index: 3;
            }
            .culture-img {
                margin-bottom: 8px;
                img {
                    width: 100%;
                    height: 165px;
                }
                &.culture-img-6 {
                    img {
                        height: 165px;
                    }
                }
            }
        }
        .culture-right-img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    /***end our-culture-section***/
    /***Job-apply-modal***/
    .Job-apply-modal .modal-dialog {
        padding: 10px 0;
        .apply-job-form .job-form-footer .btn.btn-outline-primary {
            margin-right: 10px;
        }
    }
    /***end Job-apply-modal***/
}
@media (max-width: 369px) {
    /***our-culture-section***/
    .our-culture-section .our-culture-images .culture-img img {
        width: 100%;
        height: 145px;
    }
    .our-culture-section .our-culture-images .culture-img.culture-img-6 img {
        height: 145px;
    }

    /***end our-culture-section***/
    /***Job-apply-modal***/
    .Job-apply-modal .modal-dialog .Job-apply-details .apply-job-details .apply-job-tag ul li .job-tag {
        padding: 8px 8px 8px 8px;
        font-size: 14px;
        line-height: 20px;
    }
    /***end Job-apply-modal***/
}
@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
    /***Job-apply-modal ***/
    .Job-apply-modal .modal-dialog {
        .apply-form-title {
            padding: 25px;
        }
        .modal-content .modal-header {
            padding: 20px;
        }
        .Job-apply-details .apply-job-details {
            padding: 20px 30px;
        }
        .apply-job-overview .accordion-item {
            padding: 0px 0;
        }
        .apply-job-form .form-group .form-control {
            padding: 12px 0px;
        }
    }
    /***End Job-apply-modal ***/
}

/*********************/
