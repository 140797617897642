
.footer-section {
    .footer-top {
        padding: 32px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--footer-border);
        .footer-logo {
            max-width: 155px;
        }
    }
    .footer-center {
        border-top: 1px solid var(--footer-border);
        padding-top: 32px;
        padding-bottom: 65px;
    }
    .footer-social-link {
        ul {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                padding: 0 12px;
                a {
                    transition: all 0.5s;
                    display: inline-block;
                    img {
                        transition: all 0.5s;
                    }
                    &:hover img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
    .footer-title {
        padding-bottom: 32px;
        p {
            
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 23.4px;
        }
    }
    .footer-col-details {
        .footer-location {
            padding-bottom: 24px;
            p {
                
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18.2px;
                padding-bottom: 6px;
            }
            .location-details {
                address {
                    
                    margin-bottom: 0;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }
        }
        .footer-contect-details {
            p {
                
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18.2px;
                padding-bottom: 8px;
                &:last-child {
                    padding-bottom: 0;
                }
                span {
                    font-weight: 500;
                }
                a {
                    
                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
        }
        .footer-contect-business {
            padding-bottom: 24px;
        }
    }
    .footer-col-link {
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                padding-bottom: 25px;
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    color: var(--footer-link);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    &:hover {
                        color: var(--primary-color);
                    }
                }
                .tag-line {
                    margin-left: 10px;
                    background-color: var(--primary-color);
                    padding: 2px 10px;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--white-color);
                    border-radius: 3px;
                    animation: tag-animation 2s linear infinite alternate both;
                }
            }
        }
    }
    .footer-bottom {
        padding: 25px 0 30px;
        border-top: 1px solid var(--footer-border);
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            color: var(--footer-link);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18.2px;
            padding-bottom: 0;
            flex: 1;
            padding-right: 10px;
        }
        .footer-bottom-right {
            display: flex;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                a {
                    color: var(--footer-link);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18.2px;
                    padding-bottom: 0;
                    margin-right: 32px;
                    position: relative;

                    &:hover {
                        color: var(--primary-color);
                    }
                    &::after {
                        content: "|";
                        position: absolute;
                        top: 1px;
                        color: rgba(1, 4, 5, 0.65);
                        margin: 0 16px;
                    }
                }
                &:last-child {
                    a {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/****/
@media (max-width: 991px) {
    .footer-section {
        .footer-center {
            padding-bottom: 0;
        }
        .footer-col-details {
            max-width: 350px;
        }
        .footer-col-link ul li {
            padding-bottom: 16px;
            a {
                font-size: 12px;
                line-height: 16px;
                .tag-line {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
        .footer-center .footer-col {
            padding-bottom: 40px;
        }
        .footer-top {
            padding: 24px 0;
        }
        .footer-title {
            padding-bottom: 24px;
            p {
                font-size: 16px;
                line-height: 20px;
            }
        }
        .footer-col-details .footer-location {
            padding-bottom: 16px;
            p.location-name {
                font-size: 12px;
                line-height: 16px;
            }
            .footer-col-details .footer-location {
                .footer-location .location-details address {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            .footer-col-link ul li {
                padding-bottom: 16px;
            }
            .footer-center {
                padding-bottom: 20px;
                padding-top: 24px;
            }
        }
        .footer-bottom {
            padding: 24px 0 32px;
            p {
                font-size: 12px;
                line-height: 15.5px;
            }
            .footer-bottom-right li a {
                font-size: 12px;
                line-height: 15.5px;
            }
        }
        .footer-col-details {
            .footer-contect-details p {
                font-size: 12px;
                line-height: 16px;
            }
            .footer-location {
                .location-details address {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .footer-section {
        .footer-col-link ul li {
            padding-bottom: 16px;
        }
        .footer-center .footer-col {
            padding-bottom: 32px;
        }
    }
}
@media (max-width: 575px) {
    .footer-section .footer-social-link {
        padding-left: 20px;
        ul li {
            list-style: none;
            padding: 0 5px;
        }
    }
    .footer-section .footer-bottom {
        display: block;
        text-align: center;
        padding: 10px 0 10px;
        p {
            padding-right: 0;
            padding-bottom: 10px;
            font-size: 12px;
        }
        .footer-bottom-right {
            justify-content: center;
        }
    }
    .footer-section .footer-bottom .footer-bottom-right li a {
        font-size: 12px;
    }
}
@media (max-width: 1366px) and (max-height: 800px) and (min-width: 992px) {
    .footer-section .footer-col-link ul li {
        padding-bottom: 16px;
    }
    .footer-section .footer-top {
        padding: 20px 0;
    }
    .footer-section .footer-col-details .footer-location {
        padding-bottom: 16px;
    }
    .footer-section .footer-center {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .footer-section .footer-col-link ul li a{
        font-size: 12px;
        line-height: 15px;
    }
    .footer-section .footer-col-link ul li .tag-line{
        font-size: 12px;
        line-height: 15px;
    }
    .footer-section .footer-bottom{
        padding: 20px 0 20px;
    }
}
